/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import SvgI16Attention from "common/assets/icons/I16Attention";

import { Row, Field } from "common/components/Form";
import TextInput from "common/components/Input/TextInput";

import {
  Container,
  Button,
  Note,
  NoteRow,
  NoteText,
  Text,
  Title,
  ReturnButton,
} from "./styles";

type Props = {
  onSubmit: (newPassword: string) => void;
  serverError: string;
  onReturnToLogin: () => void;
  mobileVersion?: boolean;
  resetIsSuccessful?: boolean;
  isLoading?: boolean;
  tokenIsExpiredError?: string;
};

@observer
class ResetPasswordForm extends React.Component<Props> {
  @observable password: string = "";
  @observable confirmPassword: string = "";
  @observable passwordError: string = "";

  @computed get canResetPassword(): boolean {
    return !!this.password && !!this.confirmPassword;
  }

  onChangePassword = (value: string) => {
    this.password = value.trim();
    this.resetError();
  };

  onChangeConfirmPassword = (value: string) => {
    this.confirmPassword = value.trim();
    this.resetError();
  };

  resetError = () => {
    this.passwordError = "";
  };

  validate = () => {
    if (this.password !== this.confirmPassword) {
      this.passwordError = "Password and confirmation password do not match";
      return false;
    }

    if (this.password?.length < 8) {
      this.passwordError = "Password must be at least 8 characters long.";
      return false;
    }

    return true;
  };

  onSubmit = (e: any) => {
    const { onSubmit } = this.props;
    // do not reload page in IE/Edge
    e.preventDefault();

    if (!this.validate()) return;
    onSubmit(this.password);

    return false;
  };

  render() {
    const {
      serverError,
      isLoading,
      mobileVersion,
      onReturnToLogin,
      resetIsSuccessful,
      tokenIsExpiredError,
    } = this.props;

    return tokenIsExpiredError ? (
      <>
        <Container>
          <Text>{tokenIsExpiredError}</Text>
        </Container>
      </>
    ) : resetIsSuccessful ? (
      <>
        <Row>
          <Title>Password successfully reset</Title>
        </Row>
        <Container>
          <Text>
            {mobileVersion
              ? "Your new password is successfully saved. Please sign in via the mobile app."
              : "Your password has been successfully reset."}
          </Text>
          {!mobileVersion && (
            <ReturnButton onClick={onReturnToLogin}>
              Login to SMART.
            </ReturnButton>
          )}
        </Container>
      </>
    ) : (
      <form onSubmit={this.onSubmit}>
        <Row>
          <Title>Password reset</Title>
        </Row>
        <Row>
          <Text>
            {mobileVersion
              ? "Create a new password to login to your participant account in the SMART mobile app:"
              : "Create a new password to login to your account in the SMART app:"}
          </Text>
        </Row>
        <NoteRow>
          <Note>
            <SvgI16Attention />
            <NoteText>Min 8 characters</NoteText>
          </Note>
        </NoteRow>
        <Row>
          <Field title="New password" required>
            <TextInput
              value={this.password}
              onChange={this.onChangePassword}
              type="password"
              error={this.passwordError || serverError}
              placeholder="Password"
              canStretchOnError={true}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Confirm password" required>
            <TextInput
              value={this.confirmPassword}
              onChange={this.onChangeConfirmPassword}
              type="password"
              error={this.passwordError || serverError}
              placeholder="Password"
              canStretchOnError={true}
            />
          </Field>
        </Row>
        <Button
          type="submit"
          disabled={!this.canResetPassword}
          isLoading={isLoading}
        >
          Save
        </Button>
      </form>
    );
  }
}

export default ResetPasswordForm;
