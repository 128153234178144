/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import ColorConverter from "studyAdmin/services/colorConverter";

export const EVENT_TYPE = {
  AVAILABILITY: "availability",
  REQUEST: "request",
  VISIT: "visit",
};

export const CALENDAR_TYPE = {
  AVAILABILITY: "availability",
  SCHEDULE: "schedule",
};

export const CALENDAR_VIEWS = {
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
};

export type CalendarViewValues =
  | CALENDAR_VIEWS.MONTH
  | CALENDAR_VIEWS.WEEK
  | CALENDAR_VIEWS.DAY;

export const RECURRING_OPTION_BY = {
  label: "By",
  value: "By",
};

export const RECURRING_OPTION_AFTER = {
  label: "After",
  value: "After",
};

export const DELETE_OR_EDIT_AVAILABILITY_OPTION = {
  CURRENT: {
    label: "This slot",
    value: true,
  },
  TEMPLATE: {
    label: "This and following slots",
    value: false,
  },
};

export const DELETE_OR_EDIT_AVAILABILITY_OPTIONS = [
  DELETE_OR_EDIT_AVAILABILITY_OPTION.CURRENT,
  DELETE_OR_EDIT_AVAILABILITY_OPTION.TEMPLATE,
];

export const REQUEST_CONFIRM_TYPE = {
  APPROVE: "approve",
  DECLINE: "decline",
};

export const DEFAULT_CALENDAR_HEIGHT = 700;

const VISIT_COLOR_PALETTE = [
  { r: 63, g: 115, b: 255 },
  { r: 255, g: 145, b: 44 },
  { r: 255, g: 181, b: 33 },
  { r: 145, g: 99, b: 238 },
  { r: 216, g: 102, b: 228 },
  { r: 102, g: 214, b: 124 },
  { r: 21, g: 210, b: 241 },
];

export const VISIT_COLORS = VISIT_COLOR_PALETTE.map(
  color => new ColorConverter({ color }),
);

export const NEW_VISIT_COLOR = {
  backgroundColor: "rgba(164, 170, 246, 0.08)",
  borderColor: {
    solid: "rgba(86, 130, 246, 0.6)",
    dashed: "rgba(86, 130, 246, 0.6)",
  },
};

export const COMPLETED_VISIT_COLOR = new ColorConverter({
  color: { r: 145, g: 155, b: 184 },
});

export const PAST_EVENT_COLOR = new ColorConverter({
  color: { r: 145, g: 155, b: 184 },
});

export const AVAILABILITY_COLOR = new ColorConverter({
  color: { r: 247, g: 74, b: 112 },
});
export const AVAILABILITY_SELF_SCHEDULE_COLOR = new ColorConverter({
  color: { r: 86, g: 130, b: 246 },
});

export const TIMEZONE_IS_DIFFERENT_TOOLTIP_TEXT = {
  LOCAL_TIMEZONE:
    "Please note that your network time zone doesn’t coincide with the Site time zone. All slots and appointments are shown for you in the network time zone.",
  CHOSEN_TIMEZONE:
    "Your selected time zone doesn’t coincide with your Site time zone. All slots and appointments are shown for you in the selected time zone.",
};

export const WHOLE_DAY_AVAILABILITY_MIN_DURATION = 1425;
