/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Placeholder, Container, LeftContent, RightContent } from "./styles";

type Props = {
  className?: string;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

function NavPane({ className, leftContent, rightContent }: Props) {
  return (
    <Placeholder>
      <Container className={className}>
        <LeftContent>{leftContent}</LeftContent>
        <RightContent>{rightContent}</RightContent>
      </Container>
    </Placeholder>
  );
}

export default NavPane;
