/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Container, TitleContainer, Title, Star, Action } from "./styles";

type Props = {
  title?: string;
  required?: boolean;
  action?: React.ReactNode;
  actionShiftedLeft?: boolean;
  children: React.ReactNode;
  titleTextAlign?: string;
  shifted?: boolean;
};

function Field({
  title,
  titleTextAlign,
  required,
  action,
  actionShiftedLeft,
  children,
  shifted,
}: Props) {
  return (
    <Container shifted={shifted}>
      {title && (
        <TitleContainer>
          <Title titleTextAlign={titleTextAlign} shifted={shifted}>
            {title}
            {required && <Star />}
          </Title>
          {action && (
            <Action actionShiftedLeft={actionShiftedLeft}>{action}</Action>
          )}
        </TitleContainer>
      )}
      {children}
    </Container>
  );
}

export default Field;
