/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types, applySnapshot } from "mobx-state-tree";

import createTableStore from "stores/templates/TableStore";

import { ASSIGNEE_TYPE } from "studyAdmin/constants/taskManager";

import api from "common/services/api";

import { IMAGE_TYPES } from "constants/imageTypes";

import { formatDateToUTC } from "common/utils/dateUtils";
import { getImageType } from "common/utils/images";

import { Employee } from "models/common";

const Avatar = types.model("Avatar", {
  id: types.number,
  avatar: types.string,
});

export const TaskModel = types.model("TaskModel", {
  title: types.maybe(types.string),
  description: types.maybe(types.string),
  dueDate: types.maybe(types.string),
  status: types.string,
  assignee: types.maybe(Employee),
  reporter: types.maybe(Employee),
  site: types.maybe(types.string),
  id: types.number,
});

const TasksStore = createTableStore({
  fetch: function({ params }) {
    return api.get(`/task/list`, params);
  },
  itemModel: TaskModel,
  searchBy: "search",
  initialOrder: {
    column: "dueDate",
    desc: false,
  },
  initialFilter: {
    column: "assignmentStatus",
    value: ASSIGNEE_TYPE.ASSIGNED_TO_ME,
  },
})
  .props({
    assignToMeCount: types.maybe(types.number),
    isAddLoading: types.optional(types.boolean, false),
    avatars: types.array(Avatar),
  })
  .actions(self => ({
    fetchAssignToMeCount: flow(function*() {
      try {
        type Response = { count: number };
        const response: Response = yield api.get(
          `/task/list/assign-to-me/count`,
        );

        applySnapshot(self, { ...self, assignToMeCount: response?.count ?? 0 });
      } catch (error) {
        console.error("error", error);
        throw error;
      }
    }),
    addTask: flow(function*(values: any) {
      let isSuccess = false;
      let error = "";
      self.isAddLoading = true;

      try {
        const { siteId, ...otherValues } = values;

        if (otherValues.customerUserId === ASSIGNEE_TYPE.UNASSIGNED) {
          otherValues.customerUserId = undefined;
        }

        if (otherValues.participant) {
          otherValues.participantId = otherValues.participant.id;
        }

        if (otherValues.dueDate) {
          otherValues.dueDate = formatDateToUTC(otherValues.dueDate);
        }

        yield api.post(`/site/${siteId}/task`, {
          ...otherValues,
        });

        yield self.fetchAssignToMeCount();
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isAddLoading = false;
      }

      return { isSuccess, error };
    }),
    fetchAvatars: flow(function*() {
      try {
        const ids = [
          ...new Set([
            ...self.items.map(item => item.assignee.id).filter(Boolean),
            ...self.items.map(item => item.reporter.id).filter(Boolean),
          ]),
        ];

        const avatars: any = [];
        for (const id of ids) {
          type Response = { blob?: Blob; contentDisposition?: string };
          const response: Response = yield api.getBlob(
            `/customer-user/${id}/photo/download`,
            {
              imageType: getImageType(IMAGE_TYPES.WEB),
            },
          );
          if (response.blob) {
            avatars.push({
              id,
              avatar: URL.createObjectURL(response.blob),
            });
          }
        }

        applySnapshot(self, { ...self, avatars });
      } catch (error) {
        console.log("error :>> ", error);
      }
    }),
    setLoading(status: boolean) {
      self.isLoading = status;
    },
    resetTasksCount() {
      self.assignToMeCount = undefined;
    },
  }))
  .views(self => ({
    findAvatarById(id: number) {
      return self.avatars?.find(x => x.id === id)?.avatar;
    },
  }));

export default TasksStore.create();
