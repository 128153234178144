/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import { Button } from "common/components/Button/styles";

import { ButtonBlue as DefaultButtonBlue } from "common/components/Button";

export const ButtonBlue = styled(DefaultButtonBlue)`
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

export const ButtonHint = styled.p`
  margin-left: 30px;

  font-size: 14px;
  color: #919bb8;
`;

export const ButtonWrapper = styled.div`
  height: 48px;

  flex-shrink: 0;

  :not(:first-child) {
    margin-left: 20px;
  }

  ${Button} {
    padding: 0 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;

export const Content = styled.div``;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 50px;
`;

export const FieldValue = styled.p`
  min-height: 32px;
  display: inline-flex;
  align-items: center;
`;

export const Hint = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 16px;
`;

export const HintText = styled.span`
  margin-left: 6px;

  font-size: 14px;
  color: #5682f6;
  white-space: nowrap;
`;

export const IconContainer = styled.div`
  padding-top: 6px;
`;

export const Lead = styled.p`
  margin-bottom: 24px;

  font-size: 18px;
  line-height: 1.44;
  color: #37383d;
`;

export const Timer = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-end;

  width: 100%;
`;

export const TimerText = styled.span`
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  color: #a9b4c9;
`;
