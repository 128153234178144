/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldRenderProps } from "react-final-form";

import { Field } from "common/components/Form";
import Editor from "common/components/Editor";

import withFieldControl from "../FieldControl";

import { Container, EditorFieldContainer, Error, Wrapper } from "./styled";

type Props = {
  config?: string;
  title?: string;
  required?: boolean;
  action?: React.ReactNode;
} & FieldRenderProps<any>;

class EditorFieldControl extends React.Component<Props> {
  onChange = (value: string) => {
    const { input } = this.props;

    const event: any = {
      target: { value },
    };
    input.onChange(event);
  };

  render() {
    const {
      config,
      title,
      required,
      action,
      input,
      meta,
      ...props
    } = this.props;

    const error = meta.touched && meta.error;

    return (
      <Field title={title} action={action} required={required}>
        <Container>
          <EditorFieldContainer hasError={error}>
            <Wrapper>
              <Editor
                config={config}
                content={input.value}
                onChange={this.onChange}
                {...props}
              />
            </Wrapper>
          </EditorFieldContainer>
          {error && <Error>{error}</Error>}
        </Container>
      </Field>
    );
  }
}

export default withFieldControl(EditorFieldControl);
