/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import LetterAvatar from "common/components/LetterAvatar";

import { Logo, LogoContainer } from "./styles";

type Props = {
  profilePhoto?: string;
  name: string;
  fontSize?: number;
  size?: number;
  className?: any;
};

export default class Avatar extends React.Component<Props> {
  render() {
    const {
      profilePhoto,
      name,
      fontSize = 16,
      size = 40,
      className,
    } = this.props;

    return profilePhoto ? (
      <LogoContainer size={size} className={className}>
        <Logo
          size={size}
          style={{
            backgroundImage: `url(${profilePhoto})`,
          }}
        />
      </LogoContainer>
    ) : (
      <LetterAvatar
        name={name}
        size={size}
        fontSize={fontSize}
        className={className}
      />
    );
  }
}
