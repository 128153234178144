/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

export const CustomerAdminModel = types.model("CustomerAdminModel", {
  id: types.number,
  isActive: types.maybe(types.boolean),
  customerUserRoleId: types.maybe(types.number),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  email: types.maybe(types.string),
});
