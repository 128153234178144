/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { matchPath, RouteComponentProps, withRouter } from "react-router-dom";
import { observable } from "mobx";
import { observer } from "mobx-react";
import IdleTimer from "react-idle-timer";
import { differenceInMilliseconds } from "date-fns";

import AuthStore from "stores/Auth";
import Popup from "common/components/Popup";

import ROUTES from "navigation/routes";

import { USER_SESSION_RESTORE_TIMEOUT } from "constants/autoLogout";

import { ConfirmationDialog } from "./styles";

type Props = {
  timeoutShowPopup: number;
  timeoutAutoLogout: number;
} & RouteComponentProps;

const ROUTES_WITHOUT_AUTO_LOGOUT = [
  ROUTES.CHANGE_EMAIL,
  ROUTES.EMAIL_CHANGE_CONFIRM,
  ROUTES.SELF_SCHEDULE,
  ROUTES.LOGIN,
];

@observer
class AutoLogout extends React.Component<Props> {
  @observable isPopupOpened: boolean = false;

  get isPageWithAutoLogout() {
    const { location } = this.props;

    return !ROUTES_WITHOUT_AUTO_LOGOUT.some(
      (path: string) =>
        !!matchPath(location.pathname, {
          path,
          exact: true,
          strict: false,
        }),
    );
  }

  get shouldAutoLogout() {
    return AuthStore.isSignedIn && this.isPageWithAutoLogout;
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload);

    const dateString = window.sessionStorage.getItem(
      "windowRefreshOrCloseDateTime",
    );
    const date = dateString ? new Date(dateString) : undefined;

    if (!date) {
      return;
    }

    const currentDate = new Date();
    const shouldCleanStorage =
      differenceInMilliseconds(currentDate, date) >=
      USER_SESSION_RESTORE_TIMEOUT;

    if (shouldCleanStorage) {
      AuthStore.isSignedIn && AuthStore.signOut();
    }

    window.sessionStorage.removeItem("windowRefreshOrCloseDateTime");
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  onBeforeUnload = () => {
    const date = new Date().toString();
    window.sessionStorage.setItem("windowRefreshOrCloseDateTime", date);
  };

  openPopup = () => {
    this.isPopupOpened = true;
  };

  closePopup = () => {
    this.isPopupOpened = false;
  };

  logout = () => {
    AuthStore.signOut();
    this.closePopup();
  };

  handleLogout = () => {
    const { timeoutAutoLogout } = this.props;

    setTimeout(() => {
      if (!this.isPopupOpened) {
        return;
      }
      this.logout();
    }, timeoutAutoLogout);
  };

  handleOnIdle = () => {
    this.openPopup();
    this.handleLogout();
  };

  render(): React.ReactNode {
    const { timeoutShowPopup } = this.props;

    return this.shouldAutoLogout ? (
      <>
        <IdleTimer timeout={timeoutShowPopup} onIdle={this.handleOnIdle} />
        {this.isPopupOpened && (
          <Popup onClose={this.closePopup} withoutExitButton>
            <ConfirmationDialog
              title="Your Session Is About To Expire"
              text="Are you still working?"
              acceptText={"No, log out"}
              declineText={"Yes, keep working"}
              onDecline={this.closePopup}
              onAccept={this.logout}
            />
          </Popup>
        )}
      </>
    ) : null;
  }
}

export default withRouter(AutoLogout);
