/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  ACCEPTED_IMAGE_TYPES,
  ERROR_COUNT_OF_IMAGES,
  ERROR_INCORRECT_FORMAT,
  ERROR_IMAGE_IS_CORRUPTED,
} from "constants/index";
import { IMAGE_TYPES } from "constants/imageTypes";
import { OPERATION_STATUS } from "constants/operationStatus";

export function acceptedImageFormats() {
  return ACCEPTED_IMAGE_TYPES.replace(/image\//g, " .");
}

export type Result = {
  success: boolean;
  error?: string;
  result?: string | ArrayBuffer | null;
};

const fileTypeIsValid = (file: File): Result => {
  const acceptedTypes = ACCEPTED_IMAGE_TYPES.split(",");
  const fileIsValid = acceptedTypes.includes(file.type);

  if (fileIsValid) {
    return {
      success: true,
      error: "",
    };
  }

  if (!fileIsValid) {
    return {
      success: false,
      error: `${ERROR_INCORRECT_FORMAT}: ${acceptedImageFormats()}.`,
    };
  }
  return fileIsValid;
};

const countOfFilesIsValid = (files: File[]): Result => {
  if (files.length > 1) {
    return {
      success: false,
      error: ERROR_COUNT_OF_IMAGES,
    };
  }

  return { success: true };
};

export const readImageContent = (
  reader: FileReader,
  file: File,
): Promise<Result> => {
  return new Promise(resolve => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.onload = () => {
        resolve({ success: true, result: reader.result });
      };
      img.onerror = () => {
        resolve({
          success: false,
          error: ERROR_IMAGE_IS_CORRUPTED,
          result: reader.result,
        });
      };
      img.src = e.target?.result;
    };
    reader.readAsDataURL(file);
  });
};

export const fileIsValid = async (
  reader: FileReader,
  files: File[],
): Promise<Result> => {
  const [file] = files;

  let res = countOfFilesIsValid(files);
  if (!res.success) {
    return res;
  }

  res = fileTypeIsValid(file);
  if (!res.success) {
    return res;
  }

  res = await readImageContent(reader, file);
  if (!res.success) {
    return res;
  }

  return { success: true };
};

export const getImageType = (imageType: string) => {
  return process.env.REACT_APP_ENV === "ec2-dev"
    ? imageType
    : IMAGE_TYPES.ORIGINAL;
};

export const fetchImageWithCheck = async (
  checkImage: () => Promise<string>,
  fetchImage: () => Promise<void>,
  onSuccess?: () => void,
) => {
  const statusId = window.setInterval(async () => {
    const status = await checkImage();
    if (status === OPERATION_STATUS.SUCCESS) {
      window.clearInterval(statusId);
      onSuccess && onSuccess();
      await fetchImage();
    } else if (status === OPERATION_STATUS.FAILED) {
      window.clearInterval(statusId);
    }
  }, 1000);
};
