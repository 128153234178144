/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI24Edit(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        fill="#5682F6"
        fillRule="evenodd"
        d="M12.403 17.25c-.384.384-1.152.707-1.7.707h-3.71c-.546 0-.993-.45-.993-1.004v-3.61c0-.563.314-1.319.702-1.707l9.754-9.753c1.174-1.174 3.073-1.18 4.248-.004l1.412 1.412c1.172 1.173 1.179 3.067-.003 4.249l-9.71 9.71zM9.906 11.26l5.988-5.988L18.723 8.1l-5.989 5.989-2.828-2.829zm-1.79 1.834c-.014.014-.116.262-.116.292v1.72c.129.068.34.17.491.32.206.207.27.4.356.574h1.856c.012 0 .273-.108.286-.122l.33-.33-2.828-2.829-.375.375zm9.193-9.236l.561-.562c.395-.395 1.027-.396 1.42-.003l1.412 1.412c.394.394.394 1.021-.004 1.42l-.561.56-2.828-2.827zM13.646 2h-9.43C2.993 2 2 2.992 2 4.217v15.566C2 21.008 2.992 22 4.217 22h15.566C21.008 22 22 21.008 22 19.783v-9.166l-2 2v6.814c0 .314-.251.569-.57.569H4.57c-.315 0-.57-.251-.57-.57V4.57c0-.315.251-.57.57-.57h7.076l2-2z"
      />
    </svg>
  );
}

export default SvgI24Edit;
