/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import qs from "qs";
import jsonToFormData from "json-form-data";

import { apiUrl } from "common/utils/env";
import { downloadBlob } from "common/utils/files";

const UNAUTHORIZED_ERROR_CODE = 401;
const NOT_FOUND_ERROR_CODE = 404;

const API_URL = `${apiUrl}/api`;

let authHeader: { [key: string]: string } = {};

interface AuthValue {
  accessToken?: string;
  accessTokenType?: string;
  accessTokenExpiresIn: number;
  refreshToken?: string;
  refreshTokenExpiresIn: number;
}

export function assignAuth(auth?: AuthValue) {
  if (auth?.accessTokenType && auth?.accessToken) {
    authHeader = {
      Authorization: `${auth.accessTokenType} ${auth.accessToken}`,
    };
  } else {
    authHeader = {};
  }
}

let onRefresh: () => Promise<void>;

export function assignOnRefresh(handleRefresh: () => Promise<void>) {
  onRefresh = handleRefresh;
}

const RESPONSE_TYPES = {
  JSON: "json",
  BLOB: "blob",
};

type RequestParams = {
  responseType: typeof RESPONSE_TYPES.JSON | typeof RESPONSE_TYPES.BLOB;
  isBlob?: boolean;
};

async function makeRequest(
  path: string,
  options: Object,
  headers: Object,
  { responseType, isBlob = true }: RequestParams,
) {
  const url = `${API_URL}${path}`;

  let wwwAuthenticate;

  const response = await fetch(url, {
    headers: {
      Accept: "application/json",
      ...headers,
      ...authHeader,
    },
    ...options,
  })
    .then(async (response: any) => {
      wwwAuthenticate = response.headers.get("WWW-Authenticate");
      switch (responseType) {
        case RESPONSE_TYPES.JSON: {
          const responseText = await response.text();
          const JsonpMatch = responseText.match(/\?\((.*)\);/);
          return {
            jsonResponse: JsonpMatch
              ? JSON.parse(JsonpMatch[1])
              : responseText
              ? JSON.parse(responseText)
              : undefined,
            statusCode: response.status,
          };
        }
        case RESPONSE_TYPES.BLOB: {
          let success;
          let error: any = {};
          let blob;

          const responseClone = response.clone();
          try {
            blob = await response.blob();
            const contentDisposition = response.headers.get(
              "content-disposition",
            );
            if (isBlob) {
              downloadBlob({ blob, contentDisposition });
            } else if (response.status === 200) {
              return {
                jsonResponse: {
                  blob,
                  contentDisposition,
                },
                statusCode: response.status,
              };
            }
          } catch {
            success = false;
            error = { ...(await responseClone.json()) };
          }

          return {
            jsonResponse: {
              success,
              ...error,
              blobContent: blob,
            },
            statusCode: response.status,
          };
        }
      }
    })
    .catch(error => {
      throw new NetworkApiError({
        message: "Something went wrong. Try again later.",
        data: error,
      });
    });

  const { jsonResponse, statusCode } = response;
  if (jsonResponse?.success === false) {
    const error = {
      name: jsonResponse.code,
      message: jsonResponse.message,
      data: jsonResponse.codeType,
      details: jsonResponse?.details,
    };
    switch (statusCode) {
      case UNAUTHORIZED_ERROR_CODE:
        throw new UnauthorizedError(error);
      case NOT_FOUND_ERROR_CODE:
        throw new NotFoundError(error);
      default:
        throw new NetworkApiError(error);
    }
  } else {
    if (statusCode === UNAUTHORIZED_ERROR_CODE) {
      throw new UnauthorizedError({ name: wwwAuthenticate });
    }
  }
  return jsonResponse;
}

/**
 * Determines if the network request is creating new token data.
 */
let _isRefreshingToken: boolean = false;

let _subscribers: Array<(error?: any, tokenData?: AuthValue) => void> = [];

const _subscribe = (
  subscriber: (error?: any, tokenData?: AuthValue) => void,
): void => {
  if (_subscribers.includes(subscriber)) {
    return;
  }

  _subscribers.push(subscriber);
};

const _broadcast = ({
  error = null,
  tokenData,
}: {
  error?: any;
  tokenData?: AuthValue;
}): void => {
  _isRefreshingToken = false;

  _subscribers.forEach(subscriber => {
    subscriber(error, tokenData);
  });

  _subscribers = [];
};

const _refreshTokens = () => {
  if (_isRefreshingToken) {
    return new Promise((resolve, reject) => {
      const subscriber = (error?: any, tokenData?: AuthValue): void => {
        if (error) {
          return reject(error);
        }

        return resolve(tokenData);
      };

      _subscribe(subscriber);
    });
  }

  _isRefreshingToken = true;

  return onRefresh()
    .then(tokenData => {
      _broadcast({ tokenData: tokenData });

      return tokenData;
    })
    .catch(error => {
      _broadcast({ error: error });

      throw error;
    });
};

async function request(
  path: string,
  options: Object = {},
  headers: Object = {},
  params: RequestParams = { responseType: RESPONSE_TYPES.JSON },
): Promise<Object> {
  try {
    return await makeRequest(path, options, headers, params);
  } catch (error) {
    if (error instanceof UnauthorizedError && error.name.includes("Bearer")) {
      if (onRefresh) {
        await _refreshTokens();
        return makeRequest(path, options, headers, params);
      }
    }
    throw error;
  }
}

export function filterEmptyValues(params: Object): Object {
  return Object.fromEntries(
    Object.entries(params).filter(
      x => undefined !== x[1] && x[1] !== null && x[1] !== "",
    ),
  );
}

const jsonToFormDataOptions = {
  showLeafArrayIndexes: false,
  mapping: (value: any) => {
    if (Array.isArray(value)) {
      return value.join();
    }
    return value;
  },
};

const api = {
  get: async (
    path: string,
    params?: Object,
    options?: Object,
  ): Promise<any> => {
    const query = params
      ? qs.stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })
      : "";
    return request(`${path}${query}`, options, {
      "Content-Type": "application/json",
    });
  },
  post: async (
    path: string,
    body: Object,
    options: Object = {},
  ): Promise<any> => {
    return request(
      path,
      {
        method: "post",
        body: JSON.stringify(body),
        ...options,
      },
      {
        "Content-Type": "application/json",
      },
    );
  },
  postWithQueryParams: async (
    path: string,
    body: Object,
    params?: Object,
    options: Object = {},
  ): Promise<any> => {
    const query = params
      ? qs.stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
          encode: false,
        })
      : "";
    return request(
      `${path}${query}`,
      {
        method: "post",
        body: JSON.stringify(body),
        ...options,
      },
      {
        "Content-Type": "application/json",
      },
    );
  },
  put: async (
    path: string,
    body: Object,
    options: Object = {},
  ): Promise<any> => {
    return request(
      path,
      {
        method: "put",
        body: JSON.stringify(body),
        ...options,
      },
      {
        "Content-Type": "application/json",
      },
    );
  },
  delete: async (
    path: string,
    body?: Object,
    params?: Object,
    options: Object = {},
  ): Promise<any> => {
    const query = params
      ? qs.stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })
      : "";
    return request(
      `${path}${query}`,
      {
        method: "delete",
        body: body && JSON.stringify(body),
        ...options,
      },
      {
        "Content-Type": "application/json",
      },
    );
  },
  download: async (
    path: string,
    params?: Object,
    options: Object = {},
  ): Promise<any> => {
    const query = params
      ? qs.stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })
      : "";
    return request(
      `${path}${query}`,
      options,
      {
        "Content-Type": "application/json",
      },
      { responseType: RESPONSE_TYPES.BLOB },
    );
  },
  downloadPost: async (
    path: string,
    body: Object,
    params?: Object,
    options: Object = {},
  ): Promise<any> => {
    const query = params
      ? qs.stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })
      : "";
    return request(
      `${path}${query}`,
      {
        method: "post",
        body: JSON.stringify(body),
        ...options,
      },
      {
        "Content-Type": "application/json",
      },
      { responseType: RESPONSE_TYPES.BLOB },
    );
  },
  upload: async (
    path: string,
    body: Object,
    options: Object = {},
  ): Promise<any> => {
    const response = await fetch(path, {
      method: "put",
      body,
      ...options,
    })
      .then(
        (response: Object): Object => ({
          data: response,
          statusCode: response.status,
        }),
      )
      .catch(onNetworkError);

    if (response.statusCode >= 300) {
      throw new NetworkApiError({});
    }

    return response;
  },
  postFormData: async (
    path: string,
    body: Object,
    options: Object = {},
  ): Promise<any> => {
    return request(path, {
      method: "post",
      body: jsonToFormData(body, jsonToFormDataOptions),
      ...options,
    });
  },
  putFormData: async (
    path: string,
    body: Object,
    options: Object = {},
  ): Promise<any> => {
    return request(path, {
      method: "put",
      body: jsonToFormData(body, jsonToFormDataOptions),
      ...options,
    });
  },
  getBlob: async (
    path: string,
    params?: Object,
    options?: Object,
  ): Promise<any> => {
    const query = params
      ? qs.stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })
      : "";
    return request(
      `${path}${query}`,
      options,
      {
        "Content-Type": "application/json",
      },
      { responseType: RESPONSE_TYPES.BLOB, isBlob: false },
    );
  },
};

const onNetworkError = (error: Error): void => {
  throw new NetworkApiError({
    message: "Unhandled network error!",
    data: error,
  });
};

export class NetworkApiError implements Error {
  data: any = {};
  details: any = {};
  message: string = "";
  name: string = "";

  constructor({ message, data, name, details }: any) {
    this.message = message;
    this.data = data;
    this.name = name;
    this.details = details;
  }
}

export class UnauthorizedError extends NetworkApiError {}

export class NotFoundError extends NetworkApiError {}

export default api;
