/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  Horizontal as SeparatorHorizontal,
  Vertical as SeparatorVertical,
} from "./styles";

export { SeparatorHorizontal, SeparatorVertical };
