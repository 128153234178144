/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const SEND_TYPES = {
  EMAIL: "Email",
  PHONE: "Phone",
};
