/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import DropzoneComponent, {
  DropzoneComponentHandlers,
  DropzoneComponentConfig,
} from "react-dropzone-component";

import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/min/dropzone.min.css";

import { auth } from "stores/Auth";

import { Container, Error } from "./styles";

export type ExternalDropZoneProps = {
  url?: string;
  maxFiles?: number;
  /**
   * @example
   * "image/jpeg,image/png,image/gif"
   */
  acceptedFiles?: string;
  showFiletypeIcon?: boolean;
  /**
   * @example
   * [".jpg", ".png", ".gif"]
   */
  iconFiletypes?: string[];
  addRemoveLinks?: boolean;
  maxFilesize?: number;
  defaultMessage?: string;
  /**
   * See all available options here: https://www.dropzonejs.com/#configuration-options
   */
  djsConfig?: Object;
  /**
   * See all available options here: https://github.com/felixrieseberg/React-Dropzone-Component
   */
  componentConfig?: DropzoneComponentConfig;
  /**
   * See all available callbacks here: https://github.com/felixrieseberg/React-Dropzone-Component#callbacks
   */
  eventHandlers?: DropzoneComponentHandlers;
  /**
   * If not specified by default is `true`.
   * If `false`, files will be added to the queue but the queue will not be processed automatically.
   * In this case, call `upload()` method.
   */
  autoUpload?: boolean;
  fitWidth?: boolean;
  fitHeight?: boolean;
  hideProgressBar?: boolean;
  error?: boolean;
  clickable?: boolean;
  invalidFileTypeMessage?: string;
};

type Props = { className?: string } & ExternalDropZoneProps;

class DropZone extends React.Component<Props> {
  djsConfig = {
    method: "put",
    maxFiles: this.props.maxFiles,
    addRemoveLinks: this.props.addRemoveLinks,
    acceptedFiles: this.props.acceptedFiles,
    headers: { authorization: `Bearer ${auth.tokenData.accessToken}` },
    autoProcessQueue: this.props.autoUpload,
    clickable: this.props.clickable,
    dictDefaultMessage:
      this.props.defaultMessage || "Drag a file here or browse to upload",
    dictInvalidFileType: this.props.invalidFileTypeMessage,
    ...this.props.djsConfig,
  };

  componentConfig = {
    showFiletypeIcon: this.props.showFiletypeIcon,
    iconFiletypes: this.props.iconFiletypes,
    postUrl: this.props.url,
    ...this.props.componentConfig,
  };

  dropzone: any;

  eventHandlers = {
    init: (dz: any) => {
      this.dropzone = dz;
      const { maxFiles } = this.props;
      if (maxFiles) {
        dz.on("addedfile", function() {
          if (dz.files[maxFiles] != null) {
            dz.removeFile(dz.files[0]);
          }
        });
      }
    },
    ...this.props.eventHandlers,
  };

  upload() {
    this.dropzone.processQueue();
  }

  render() {
    const {
      hideProgressBar,
      fitWidth,
      fitHeight,
      error,
      className,
    } = this.props;

    return (
      <Container
        fitWidth={fitWidth}
        fitHeight={fitHeight}
        hideProgressBar={hideProgressBar}
        error={error}
        className={className}
      >
        <DropzoneComponent
          djsConfig={this.djsConfig}
          config={this.componentConfig}
          eventHandlers={this.eventHandlers}
        />
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
}

export default DropZone;
