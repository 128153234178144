/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observable } from "mobx";
import { observer } from "mobx-react";

import ROUTES from "navigation/routes";

import Background from "common/components/Layout/Background/BackgroundBlue";
import Paper from "common/components/Paper";

import { Container, Content, Logo, Separator } from "./styles";

import { auth } from "stores/Auth";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import { correctTextFromQueryParams } from "common/utils/queryParamsUtils";

type Props = {} & RouteComponentProps;

@observer
class ResetPassword extends React.Component<Props> {
  @observable serverError: string = "";
  @observable tokenIsExpiredError: string = "";
  @observable resetIsSuccessful: boolean = false;

  get userId(): number | null {
    const userId = new URLSearchParams(this.props.location.search).get(
      "userId",
    );
    return userId ? Number.parseInt(userId) : null;
  }

  get code(): string | null {
    const code =
      new URLSearchParams(this.props.location.search).get("code") ?? null;

    return code ? correctTextFromQueryParams(code) : code;
  }

  get isMobile(): boolean {
    const isMobile =
      new URLSearchParams(this.props.location.search).get("isMobile") ?? null;

    return isMobile === "true";
  }

  async componentDidMount() {
    const { error } = await auth.validateToken({
      userId: this.userId,
      code: this.code,
    });

    if (error) {
      this.tokenIsExpiredError = error;
    }
  }

  onSubmit = async (newPassword: string) => {
    if (!this.userId || !this.code) {
      return;
    }

    const { error } = await auth.resetPassword(
      {
        userId: this.userId,
        code: this.code,
        newPassword,
      },
      this.isMobile,
    );
    if (error) {
      this.serverError = error;
      return;
    }

    this.resetError();
    this.resetIsSuccessful = true;
  };

  resetError = () => {
    this.serverError = "";
  };

  onReturnToLogin = () => {
    this.props.history.push(ROUTES.LOGIN);
  };

  render() {
    return (
      <Container>
        <Background />
        <Paper>
          <Content>
            <Logo />
            <Separator />
            <ResetPasswordForm
              onSubmit={this.onSubmit}
              serverError={this.serverError}
              isLoading={auth.isLoading}
              onReturnToLogin={this.onReturnToLogin}
              resetIsSuccessful={this.resetIsSuccessful}
              mobileVersion={this.isMobile}
              tokenIsExpiredError={this.tokenIsExpiredError}
            />
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(ResetPassword);
