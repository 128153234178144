/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const COLORS = {
  CORNFLOWER: "#5682f6",
  CORNFLOWER_DARK: "#4a53df",
  DARK_CORNFLOWER: "#3725ba",
  CORNFLOWER_LIGHT: "rgba(164, 170, 246, 0.08)",
  ERROR_BACKGROUND: "rgba(247, 74, 112, 0.08)",
  ACTIVE: "#61dc9f",
  ACCENT: "#c23bc7",
  TEXT_LIGHT: "#a9b4c9",
  BACK: "#ebf0fb",
  WHITE: "white",
  LIGHT_GRAY: "#ebf0fb7e",
  SHADOW: "rgba(74, 84, 223, 0.1)",
  TEST: "red",
  BUTTON_FONT_COLOR: "white",
  EXPIRING: "#ffb624",
  YELLOW: "#ffbb33",
  GREEN: "#26c576",
  BLUE: "#5682f6",
  BLUE_MASKING: "#5682f619",
  ROW_MASKING: "rgba(64, 170, 246, 0.08)",
  RED: "#f74a70",
  DARK_RED: "#de4364",
  RED_BRIGHT: "#ff3467",
  BLACK: "#363636",
  GRAY: "#37383d",
};
