/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  applySnapshot,
  flow,
  types,
  SnapshotOrInstance,
} from "mobx-state-tree";

import createTableStore, { TableOrderModel } from "stores/templates/TableStore";

import api from "common/services/api";

import { getImageType } from "common/utils/images";

import { LicensedStudyModel } from "models/LicensedStudyModel";
import { IMAGE_TYPES } from "constants/imageTypes";

type LicensedStudiesStoreProperties = {
  getUrl: (self: any) => string;
  initialOrder?: SnapshotOrInstance<typeof TableOrderModel>;
};

const Logo = types.model("Logo", {
  id: types.number,
  logo: types.string,
});

function createLicensedStudiesStore({
  getUrl,
  initialOrder,
}: LicensedStudiesStoreProperties) {
  return createTableStore({
    fetch: async function ({ self, params }) {
      const { licenceStatuses, ...payload } = await api.get(
        getUrl(self),
        params,
      );
      self.applyLicenseStatuses(licenceStatuses);
      return payload;
    },
    exportData: function ({ params }) {
      return api.download("/study/export", params);
    },
    itemModel: LicensedStudyModel,
    searchBy: "search",
    initialOrder,
  })
    .props({
      licenceStatuses: types.optional(types.array(types.string), []),
      customerLogos: types.maybe(types.array(Logo)),
      isAddLoading: types.optional(types.boolean, false),
      isRenewLoading: types.optional(types.boolean, false),
      isLogoLoading: types.optional(types.boolean, false),
      isDomainUpdating: types.optional(types.boolean, false),
      isDomainsValidating: types.optional(types.boolean, false),
    })
    .actions(self => ({
      applyLicenseStatuses(licenseStatuses: Array<string> | undefined) {
        applySnapshot(self.licenceStatuses, licenseStatuses);
      },

      fetchLogos: flow(function* () {
        self.isLogoLoading = true;
        try {
          const ids = new Map();
          self.items.map(item => ids.set(item.customerId, item.customerId));

          const logos: any = [];
          for (const id of ids.keys()) {
            type Response = { blob?: Blob; contentDisposition?: string };
            const response: Response = yield api.getBlob(
              `/customer/${id}/photo/download`,
              {
                imageType: getImageType(IMAGE_TYPES.WEB),
              },
            );
            if (response.blob) {
              logos.push({
                id,
                logo: URL.createObjectURL(response.blob),
              });
            }
          }

          applySnapshot(self, { ...self, customerLogos: logos });
        } finally {
          self.isLogoLoading = false;
        }
      }),

      addLicensedStudy: flow(function* ({
        customerId,
        values,
      }: {
        customerId: number;
        values: any;
      }) {
        self.isAddLoading = true;
        let isSuccess = false;
        let error = "";

        try {
          yield api.post(`/study`, {
            customerId,
            ...values,
          });
          isSuccess = true;
        } catch (e) {
          error = e.message;
        } finally {
          self.isAddLoading = false;
        }

        return { isSuccess, error };
      }),

      terminateLicense: flow(function* (studyId: number) {
        self.isLoading = true;
        let isSuccess = false;

        try {
          yield api.put(`/study/${studyId}/licence/terminate`, {});
          isSuccess = true;
        } finally {
          self.isLoading = false;
        }

        return isSuccess;
      }),

      renewLicense: flow(function* (studyId: number, values: any) {
        self.isRenewLoading = true;
        let isSuccess = false;
        let error = "";

        try {
          yield api.put(`/study/${studyId}/licence/renew`, {
            ...values,
          });
          isSuccess = true;
        } catch (e) {
          error = e.message;
          console.log(error);
        } finally {
          self.isRenewLoading = false;
        }

        return { isSuccess, error };
      }),

      deleteStudy: flow(function* (studyId: number) {
        self.isLoading = true;
        let isSuccess = false;
        let error = "";

        try {
          yield api.delete(`/study/${studyId}`, {});
          isSuccess = true;
        } catch (e) {
          error = e.message;
          console.log(error);
        } finally {
          self.isLoading = false;
        }

        return { isSuccess, error };
      }),

      updateDomain: flow(function* (studyId: number, values: any) {
        self.isDomainUpdating = true;
        let isSuccess = false;
        let error = "";

        try {
          yield api.put(`/study/${studyId}/domain`, {
            ...values,
          });
          isSuccess = true;
        } catch (e) {
          error = e.message;
          console.log(error);
        } finally {
          self.isDomainUpdating = false;
        }

        return { isSuccess, error };
      }),

      validateDomains: flow(function* (studyId: number, domains: string[]) {
        self.isDomainsValidating = true;
        let isSuccess = false;
        let error = "";

        try {
          const { success } = yield api.get(
            `/study/${studyId}/domain/validate`,
            {
              Domains: domains,
            },
          );
          isSuccess = success;
        } catch (e) {
          error = e.message;
          console.log(error);
        } finally {
          self.isDomainsValidating = false;
        }

        return { isSuccess, error };
      }),
    }))
    .views(self => ({
      getLogoByCustomerId(customerId: number) {
        return self.customerLogos?.find(x => x.id === customerId)?.logo;
      },
    }));
}

const LicensedStudiesStore = createLicensedStudiesStore({
  getUrl: () => `/study/list/licenced`,
  initialOrder: {
    column: "licenceExpirationDate",
    desc: true,
  },
});

export const CustomerLicensedStudiesStore = createLicensedStudiesStore({
  getUrl: ({ customerId }) => `/customer/${customerId}/study/list/licenced`,
  initialOrder: {
    column: "licenceExpirationDate",
    desc: false,
  },
})
  .props({
    customerId: types.maybe(types.number),
  })
  .actions(self => ({
    init: flow(function* (customerId: number) {
      self.customerId = customerId;
      yield self.fetch();
    }),

    addOwnLicensedStudy: flow(function* (values: any) {
      if (!self.customerId) return;

      return yield self.addLicensedStudy({
        customerId: self.customerId,
        values,
      });
    }),
  }))
  .create();

export default LicensedStudiesStore.create();
