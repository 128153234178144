/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import { Row, Field } from "common/components/Form";
import TextInput from "common/components/Input/TextInput";

import { Button, ReturnButton, Text, Container } from "./styles";

import { emailValidator } from "common/utils/validators";

type Props = {
  onSubmit: (email: string) => void;
  serverError: string;
  onReturnToLogin: () => void;
  emailIsSent?: boolean;
  isLoading?: boolean;
};

@observer
class ForgotPasswordForm extends React.Component<Props> {
  @observable email: string = "";
  @observable emailError: string = "";

  @computed get canRequestEmail(): boolean {
    return !!this.email;
  }

  onChangeEmail = (value: string) => {
    this.email = value.trim();
    this.emailError = "";
  };

  validate = () => {
    this.emailError = emailValidator(this.email);
    return !this.emailError;
  };

  onSubmit = (e: any) => {
    const { onSubmit } = this.props;
    // do not reload page in IE/Edge
    e.preventDefault();

    if (!this.validate()) return;
    onSubmit(this.email);

    return false;
  };

  render() {
    const { serverError, isLoading, onReturnToLogin, emailIsSent } = this.props;

    return emailIsSent ? (
      <>
        <Container>
          <Text>
            The link and instructions to reset the password have been sent to{" "}
            <strong>{this.email}</strong>.
          </Text>
        </Container>
        <ReturnButton onClick={onReturnToLogin}>Return to login</ReturnButton>
      </>
    ) : (
      <form onSubmit={this.onSubmit}>
        <Row>
          <Field
            title="Enter your email address and we’ll send you a link and instructions to reset the password."
            required
            titleTextAlign={"center"}
          >
            <TextInput
              value={this.email}
              onChange={this.onChangeEmail}
              error={this.emailError || serverError}
              canStretchOnError={true}
            />
          </Field>
        </Row>
        <Button
          type="submit"
          disabled={!this.canRequestEmail}
          isLoading={isLoading}
        >
          Send
        </Button>
        <ReturnButton onClick={onReturnToLogin}>Return to login</ReturnButton>
      </form>
    );
  }
}

export default ForgotPasswordForm;
