/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { RightContainer, FixedHeader, Container, Logo } from "./styles";

type Props = {
  children?: React.ReactNode;
  isOverlay?: boolean;
  onLogoClick?: () => void;
};

class Header extends React.Component<Props> {
  render() {
    const { isOverlay, children, onLogoClick } = this.props;

    return (
      <Container isOverlay={isOverlay}>
        <FixedHeader>
          <Logo onClick={onLogoClick} />
          <RightContainer>{children}</RightContainer>
        </FixedHeader>
      </Container>
    );
  }
}

export default Header;
