/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import { COLORS } from "common/constants/layout";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: ${({ minimal }) => (minimal ? "0px" : "32px")};
  padding: ${({ minimal }) => (minimal ? "0px" : "0 12px")};

  border-radius: 6px;

  border: none;
  outline: none;
  user-select: none;

  background-color: transparent;

  color: ${({ isRed, isGreyed }) =>
    isRed ? COLORS.RED : isGreyed ? COLORS.TEXT_LIGHT : COLORS.CORNFLOWER};

  & path,
  & use {
    fill: ${({ isRed, isGreyed, disabled, disabledColor }) =>
      isGreyed || disabled
        ? disabledColor || COLORS.TEXT_LIGHT
        : isRed
        ? COLORS.RED
        : COLORS.CORNFLOWER};
  }

  &:not(:disabled):hover {
    color: ${({ isRed }) => (isRed ? COLORS.DARK_RED : COLORS.CORNFLOWER)};
    background-color: ${({ isGreyed }) =>
      isGreyed ? "transparent" : "rgba(86, 130, 246, 0.1)"};
  }

  &:not(:disabled):hover path,
  &:not(:disabled):hover use {
    fill: ${({ isRed }) => (isRed ? COLORS.DARK_RED : COLORS.CORNFLOWER)};
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    color: ${({ disabledColor }) => disabledColor || COLORS.TEXT_LIGHT};

    &:hover path,
    &:hover use {
      fill: ${({ disabledColor }) => disabledColor || COLORS.TEXT_LIGHT};
    }
  }

  &:not(:disabled):active {
    background-color: transparent;
    color: ${COLORS.CORNFLOWER_DARK};

    & path,
    & use {
      fill: ${COLORS.CORNFLOWER_DARK};
    }
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      &&& {
        & path,
        & use {
          fill: transparent;
        }
      }
    `};
`;

export const Text = styled.span`
  font-family: OpenSans-SemiBold;
  font-size: 14px;

  margin-right: ${({ isRight, isMenuButton }) =>
    isRight || isMenuButton ? "8px" : "0"};
  margin-left: ${({ isRight }) => (isRight ? "0" : "8px")};

  padding: 5px 0;
  text-align: left;

  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}
`;

export const Icon = styled.div`
  padding-top: 6px;

  ${({ minimal }) =>
    minimal &&
    css`
       {
        padding-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
`;

export const IconContainer = styled.div`
  position: relative;
`;

export const Counter = styled.div`
  position: absolute;
  top: 0;
  right: -8px;

  width: 16px;
  height: 16px;

  background-color: #f74a70;
  color: #ffffff;

  font-family: OpenSans-Bold;
  font-size: 10px;
  font-weight: bold;
  text-align: center;

  border-radius: 50%;
`;
