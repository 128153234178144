/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Container, Marker, Text } from "./styles";

export type RadioButtonExternalProps = {
  label: string;
  isSelected: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
};

type Props = {} & RadioButtonExternalProps;

class RadioButton extends React.Component<Props> {
  onClick = () => {
    const { disabled, onClick } = this.props;

    !disabled && onClick && onClick();
  };

  render() {
    const { className, disabled, isSelected, label } = this.props;
    return (
      <Container
        className={className}
        onClick={this.onClick}
        disabled={disabled}
      >
        <Marker isSelected={isSelected} />
        <Text isSelected={isSelected}>{label}</Text>
      </Container>
    );
  }
}

export default RadioButton;
