/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import styledWrapper from "common/components/StyledWrapper";
import DefaultLoader from "common/components/Loader";

import { MEDIA_QUERIES } from "constants/layout";

import { TITLE_HEIGHT } from "./Title.styles";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 0 40px;

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    padding: 0 24px;
  }
`;

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  ${({ dynamicHeight }) =>
    dynamicHeight &&
    css`
      &,
      & ${Container}, & ${WrapperTable} {
        height: auto;
      }
    `};
`;

export const WrapperTable = styled.div`
  display: table;

  height: 100%;
  width: 100%;
  min-width: 700px;

  ${MEDIA_QUERIES.MEDIUM_DESKTOP} {
    min-width: 560px;
  }
`;

export const Content = styled.div`
  position: relative;
  min-width: 100%;
  width: 0;
  flex: 1;
`;

export const Loader = styledWrapper(DefaultLoader)(css`
  margin-top: -${TITLE_HEIGHT};
  height: 100%;
`);
