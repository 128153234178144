/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import styled from "styled-components";
import {
  ButtonBlue as ButtonBlueOrig,
  ButtonOutline as ButtonOutlineOrig,
} from "common/components/Button";

const ButtonOutline = styled(ButtonOutlineOrig)`
  width: 180px;
  border-radius: 0;
  border-width: 0;
  background-color: white;
  margin-right: 2px;
  font-size: 14px;
  font-family: OpenSans-Bold;

  :first-child {
    border-radius: 5px 0 0 5px;
  }

  :last-child {
    border-radius: 0 5px 5px 0;
    margin-right: 0;
  }
`;

const ButtonBlue = styled(ButtonBlueOrig)`
  width: 180px;
  border-radius: 0;
  margin-right: 2px;
  font-size: 14px;
  font-family: OpenSans-Bold;

  :first-child {
    border-radius: 5px 0 0 5px;
  }

  :last-child {
    border-radius: 0 5px 5px 0;
    margin-right: 0;
  }
`;

type Props = {
  tabIndex: number;
  label: string;
  isActive: boolean;
  onClick: (e: { tabIndex: number }) => void;
};

export default class TabButton extends React.Component<Props> {
  render() {
    const { label, isActive } = this.props;

    const Button = isActive ? ButtonBlue : ButtonOutline;

    return <Button onClick={this.onClick}>{label}</Button>;
  }

  onClick = () => {
    const { tabIndex, onClick } = this.props;
    onClick && onClick({ tabIndex });
  };
}
