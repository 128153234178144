/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Container = styled.div`
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};

  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
`;

export const Row = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;

    ${({ inline }) =>
      inline &&
      css`
        margin-right: 40px;
      `}
  }
`;
