/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import { MEDIA_QUERIES } from "constants/layout";

export const TITLE_HEIGHT = "120px";
export const SMALL_DESKTOP_TITLE_HEIGHT = "106px";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  height: ${TITLE_HEIGHT};
  min-height: ${TITLE_HEIGHT};
  max-height: ${TITLE_HEIGHT};

  ${MEDIA_QUERIES.SMALL_DESKTOP} {
    height: ${SMALL_DESKTOP_TITLE_HEIGHT};
    min-height: ${SMALL_DESKTOP_TITLE_HEIGHT};
    max-height: ${SMALL_DESKTOP_TITLE_HEIGHT};

    padding-top: 4px;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const RightContent = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const TitleWrapper = styled.div`
  display: table;
  table-layout: fixed;

  ${({ badge }) =>
    !badge &&
    css`
      width: 100%;
    `}
`;

export const TitleText = styled.p`
  font-family: OpenSans-Bold;
  font-size: 30px;

  user-select: none;

  color: #37383d;

  ${MEDIA_QUERIES.SMALL_DESKTOP} {
    line-height: 42px;
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;

  margin: 4px 0 0 16px;
  user-select: none;
`;
