/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import DefaultPaper from "common/components/Paper";

import { COLORS } from "common/constants/layout";

export const Paper = styled(DefaultPaper)`
  position: relative;

  max-height: none;
  margin-bottom: 50px;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;

  padding: 0 23px;
`;

export const StepsContent = styled.div`
  padding: 50px 60px 60px 60px;

  width: ${({ wide }) => (wide ? `820px` : `590px`)};
  min-height: 510px;
  height: 100%;
`;

export const StepsHeader = styled.p`
  width: 100%;

  margin: 60px 0 50px;

  font-family: OpenSans-Bold;
  font-size: 30px;
  text-align: center;

  color: ${COLORS.GRAY};
`;

export const StepHeader = styled.div`
  margin-bottom: 24px;

  font-family: OpenSans-Bold;
  font-size: 30px;
  line-height: 41px;

  color: ${COLORS.GRAY};
`;

export const StepLead = styled.div`
  height: 19px;

  margin-bottom: 2px;

  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: OpenSans-SemiBold;
  font-size: 14px;
  line-height: 19px;

  color: ${COLORS.CORNFLOWER};
`;
