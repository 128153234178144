/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  position: fixed;
  background-color: ${props =>
    props.type === "Alert"
      ? COLORS.RED
      : props.type === "Warning"
      ? COLORS.YELLOW
      : COLORS.GREEN};
  box-sizing: border-box;
  left: calc(100% - 50px);
  ${({ position }) =>
    position === "bottom"
      ? css`
          top: calc(100% - 50px);
        `
      : css`
          top: 125px;
        `}
  width: max-content;
  max-width: calc(100% - 200px);
  height: auto;
  z-index: 10000;
  transform: translate(-100%, -100%);
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-radius: 4px;

  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.15);
`;

export const Text = styled.p`
  color: white;
  margin-left: 6px;
`;
