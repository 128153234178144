/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const Placeholder = styled.div`
  width: 100%;
  min-height: 100px;
`;

export const Container = styled.div`
  position: fixed;
  top: 100px;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 80px;
  width: 100%;

  padding: 24px 24px 24px 144px;

  box-shadow: 0 6px 16px 0 rgba(169, 180, 201, 0.31);
  background-color: #ebf0fb;

  z-index: 1;
`;

export const LeftContent = styled.div`
  justify-self: flex-start;
`;

export const RightContent = styled.div`
  justify-self: flex-end;
`;
