/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldInputProps } from "react-final-form";

import { Container, Area, Error } from "./styles";

export type TextAreaExternalProps = {
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  input?: FieldInputProps<string>;
  canStretchOnError?: boolean;
  rows?: string;
  minHeight?: number;
};

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
} & TextAreaExternalProps;

class TextArea extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange && onChange(e.currentTarget.value);
  };

  render() {
    const {
      value,
      error,
      disabled,
      placeholder,
      rows,
      required,
      input,
      minHeight,
      canStretchOnError,
    } = this.props;
    return (
      <Container>
        <Area
          disabled={disabled}
          placeholder={placeholder}
          hasError={error}
          value={value}
          onChange={this.onChange}
          required={required}
          rows={rows}
          minHeight={minHeight}
          {...input}
        />
        {error && <Error isStatic={canStretchOnError}>{error}</Error>}
      </Container>
    );
  }
}

export default TextArea;
