/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import styledWrapper from "common/components/StyledWrapper";
import { LogoWhite } from "common/components/Logo";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  width: 100%;
  min-height: ${({ isOverlay }) => (isOverlay ? "0px" : "100px")};
`;

export const FixedHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  position: fixed;
  top: 0;
  left: 0;

  /* using vw instead of % prevents header from scrollbar-jumping */
  width: 100vw;
  height: 100px;

  background-color: ${COLORS.CORNFLOWER_DARK};
  padding: 0 32px;

  z-index: 1000;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;

  color: white;

  margin-right: 8px;
`;

export const Logo = styledWrapper(LogoWhite)`
  margin-bottom: -6px;
`;
