/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const EditorFieldContainer = styled.div`
  width: 100%;

  font-family: OpenSans-Regular;
  font-size: 16px;

  outline: none;

  border-radius: 7px;

  ${({ disabled }) =>
    disabled
      ? css`
          color: rgba(51, 51, 51, 0.4);
          background-color: #f0f2f7;
        `
      : css`
          color: #333333;
          background-color: transparent;
        `};

  ${({ hasError }) =>
    hasError
      ? css`
          border: solid 2px #f74a70;
        `
      : css`
          border: solid 2px #d8ddeb;
        `};

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;
  color: #f74a70;

  margin-top: ${({ small }) => (small ? "-6px" : "4px")};
  margin-bottom: ${({ small }) => (small ? "8px" : "0px")};
`;

export const Wrapper = styled.div`
  margin-bottom: -40px;

  && {
    .ql-toolbar {
      padding: 4px 0;
    }

    .ql-editor {
      min-height: 148px;
      padding: 16px;
    }

    .ql-editor p {
      font-size: 16px !important;
    }

    .ql-editor.ql-blank {
      font-family: OpenSans-Regular;
      font-size: 16px;
    }

    .ql-editor.ql-blank::before {
      left: 16px;
      font-style: normal;
      color: rgba(51, 51, 51, 0.4);
    }

    .ql-container {
      border: 0;
    }
  }
`;
