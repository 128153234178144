/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  applySnapshot,
  flow,
  SnapshotOrInstance,
  types,
} from "mobx-state-tree";
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  isSameDay,
  startOfDay,
  parseISO,
} from "date-fns";

import AuthStore from "stores/Auth";

import api from "common/services/api";

import { formatDateTimeToUTC } from "common/utils/dateUtils";
import {
  convertDateToLocal,
  convertDateToTimezone,
  parseDateFromServer,
  convertZonedDateToUTC,
} from "studyAdmin/utils/calendar";

import { EVENT_TYPE } from "studyAdmin/constants/calendar";

import { EVENT_STAFF, STUDY_ADMIN, STUDY_COORDINATOR } from "constants/roles";

import { TimeZoneCustom } from "models/TimeZoneCustom";

import { Employee, SimpleModel } from "models/common";

const VisitType = types.model("VisitType", {
  id: types.maybe(types.number),
  durationMins: types.maybe(types.number),
  name: types.maybe(types.string),
});

export const Visit = types.model("Visit", {
  id: types.maybe(types.number),
  name: types.maybe(types.string),
  isOnline: types.optional(types.boolean, false),
  isOptional: types.optional(types.boolean, false),
  milestone: types.maybe(SimpleModel),
  visitType: types.maybe(VisitType),
});

const ScheduleItem = types
  .model("ScheduleItem", {
    id: types.maybe(types.number),
    scheduleDate: types.string,
    completionDate: types.maybe(types.string),
    startTimeMins: types.number,
    location: types.maybe(SimpleModel),
    notes: types.maybe(types.string),
    visit: types.maybe(Visit),
    staff: types.maybe(Employee),
    participant: types.maybe(Employee),
    type: types.maybe(types.string),

    cutoffDays: types.maybe(types.number),
    earliestDays: types.maybe(types.number),
    targetDays: types.maybe(types.number),

    cutoffDate: types.maybe(types.string),
    earliestDate: types.maybe(types.string),
    targetDate: types.maybe(types.string),
  })
  .views(self => ({
    get isCompleted() {
      return !!self.completionDate;
    },
  }))
  .views(self => ({
    getDate(timezone?: string) {
      const startDefault = convertDateToLocal(
        addMinutes(
          startOfDay(parseDateFromServer(self.scheduleDate)),
          self.startTimeMins,
        ),
      );
      const start = timezone
        ? convertDateToTimezone(startDefault, timezone)
        : startDefault;

      const scheduleDate = startOfDay(start);
      const end = addMinutes(start, self.visit.visitType.durationMins);

      const completionDate = self.completionDate
        ? timezone
          ? convertDateToTimezone(self.completionDate, timezone)
          : parseISO(self.completionDate)
        : undefined;

      const cutoffDate = self.cutoffDate
        ? timezone
          ? convertDateToTimezone(self.cutoffDate, timezone)
          : parseISO(self.cutoffDate)
        : undefined;

      const earliestDate = self.earliestDate
        ? timezone
          ? convertDateToTimezone(self.earliestDate, timezone)
          : parseISO(self.earliestDate)
        : undefined;

      const targetDate = self.targetDate
        ? timezone
          ? convertDateToTimezone(self.targetDate, timezone)
          : parseISO(self.targetDate)
        : undefined;

      return {
        start,
        end,
        scheduleDate,
        completionDate,
        cutoffDate,
        earliestDate,
        targetDate,
      };
    },
  }));

const CalendarScheduleStore = types
  .model({
    items: types.array(ScheduleItem),
    requestIsExist: types.boolean,
    requests: types.array(ScheduleItem),
    searchItems: types.optional(types.array(ScheduleItem), []),
    searchValue: types.maybe(types.string),
    timezone: types.maybe(TimeZoneCustom),
    isLoading: types.optional(types.boolean, false),
    isSearchLoading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
    isDeleting: types.optional(types.boolean, false),
    isApproving: types.optional(types.boolean, false),
    isDeclining: types.optional(types.boolean, false),
    isCompleting: types.optional(types.boolean, false),
  })
  .views(self => ({
    get timezoneValue() {
      return self.timezone?.timezoneIANA || self.timezone?.timezoneOffset;
    },
  }))
  .views(self => ({
    convertScheduleItem(item: SnapshotOrInstance<typeof ScheduleItem>) {
      const date = item.getDate(self.timezoneValue);
      return {
        ...item,
        ...date,
        startDate: date.start,
        isCompleted: item.isCompleted,
      };
    },
    get requestsCount() {
      return self.requests.length ?? 0;
    },
    get convertedItems() {
      return self.items.map(self.convertScheduleItem);
    },
    get convertedRequests() {
      return self.requests.map(self.convertScheduleItem);
    },
    get convertedSearchVisits() {
      if (!self.searchValue) {
        return self.searchItems.map(self.convertScheduleItem);
      }

      const searcValue = self.searchValue?.toLowerCase();

      return self.searchItems
        .filter(
          item =>
            item.participant?.name?.toLowerCase().includes(searcValue ?? "") ||
            item.staff?.name?.toLowerCase().includes(searcValue ?? "") ||
            item.visit?.name?.toLowerCase().includes(searcValue ?? ""),
        )
        .map(self.convertScheduleItem);
    },

    get pendingRequestIsExist() {
      return self.requestIsExist || self.requests.length > 0;
    },
  }))
  .actions(self => ({
    fetchRequests: flow(function*(
      siteId: number,
      withoutLoader: boolean = false,
    ) {
      if (!withoutLoader) {
        self.isLoading = true;
      }
      try {
        const { items: appointmentRequests } = yield api.get(
          `/site/${siteId}/schedule-request/list`,
        );

        const requests = appointmentRequests.map(request => ({
          ...request,
          type: EVENT_TYPE.REQUEST,
        }));

        applySnapshot(self, { ...self, requests });
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        if (!withoutLoader) {
          self.isLoading = false;
        }
      }
    }),
    resetRequests: () => {
      applySnapshot(self, { ...self, requests: [] });
    },
    fetchByRange: flow(function*({
      locationId,
      startDate,
      endDate,
    }: {
      locationId: number;
      startDate: string;
      endDate: string;
    }) {
      self.isLoading = true;
      try {
        const start = formatDateTimeToUTC(new Date(startDate));
        const end = formatDateTimeToUTC(
          startOfDay(addDays(new Date(endDate), 1)),
        );

        if (AuthStore.hasRole([EVENT_STAFF])) {
          const { items: locationScheduleVisits } = yield api.get(
            `/location/${locationId}/activity/schedule/${start}/${end}/list`,
          );

          const scheduleVisits = locationScheduleVisits.map(request => ({
            ...request,
            type: EVENT_TYPE.VISIT,
          }));

          const items = scheduleVisits;

          applySnapshot(self, { ...self, items });
        } else {
          const { items: locationScheduleVisits } = yield api.get(
            `/location/${locationId}/activity/schedule/${start}/${end}/list`,
          );

          const { items: locationScheduleRequests } = yield api.get(
            `/site/location/${locationId}/schedule-request/${start}/${end}/list`,
          );

          const scheduleVisits = locationScheduleVisits.map(request => ({
            ...request,
            type: EVENT_TYPE.VISIT,
          }));

          const scheduleRequests = locationScheduleRequests.map(request => ({
            ...request,
            type: EVENT_TYPE.REQUEST,
          }));

          const items = scheduleVisits.concat(scheduleRequests);

          applySnapshot(self, { ...self, items });
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchSearchItemsByRange: flow(function*({
      locationId,
      startDate,
      endDate,
    }: {
      locationId: number;
      startDate: Date;
      endDate: Date;
    }) {
      self.isSearchLoading = true;
      try {
        const start = formatDateTimeToUTC(new Date(startDate));
        const end = formatDateTimeToUTC(
          startOfDay(addDays(new Date(endDate), 1)),
        );

        const { items: locationScheduleVisits } = yield api.get(
          `/location/${locationId}/activity/schedule/${start}/${end}/list`,
        );

        const scheduleVisits = locationScheduleVisits.map(request => ({
          ...request,
          type: EVENT_TYPE.VISIT,
        }));

        applySnapshot(self, { ...self, searchItems: scheduleVisits });
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isSearchLoading = false;
      }
    }),
    resetSearchItems() {
      applySnapshot(self, { ...self, searchItems: [] });
    },
    updateSearchValue(searchValue?: string) {
      applySnapshot(self, {
        ...self,
        searchValue: searchValue?.trim(),
      });
    },
    fetchRequestIsExist: flow(function*() {
      try {
        const { isExist } = yield api.get(`/study/schedule-request/exist`);
        applySnapshot(self, { ...self, requestIsExist: isExist });
      } catch (error) {
        console.error("error", error);
        throw error;
      }
    }),
    create: flow(function*(values: any) {
      self.isCreating = true;
      try {
        const { locationId, ...otherValues } = values;

        yield api.post(`/location/${locationId}/activity/schedule`, {
          ...otherValues,
        });
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isCreating = false;
      }
    }),
    update: flow(function*(values: any) {
      self.isUpdating = true;
      try {
        const { id, ...otherValues } = values;

        const response = yield api.put(`/location/activity/${id}/schedule`, {
          ...otherValues,
        });

        return response;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isUpdating = false;
      }
    }),
    delete: flow(function*(id: number) {
      self.isDeleting = true;
      try {
        yield api.delete(`/location/activity/${id}/schedule`);
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isDeleting = false;
      }
    }),
    complete: flow(function*({
      activityId,
      participantId,
      completionDate,
    }: {
      activityId: number;
      participantId: number;
      completionDate: Date;
    }) {
      self.isCompleting = true;
      try {
        const date = formatDateTimeToUTC(
          self.timezoneValue
            ? convertZonedDateToUTC(
                startOfDay(completionDate),
                self.timezoneValue,
              )
            : completionDate,
        );

        yield api.post(
          `/participant/${participantId}/activity/${activityId}/complete`,
          { completionDate: date },
        );
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isCompleting = false;
      }
    }),
    approve: flow(function*(values: any) {
      self.isApproving = true;
      const { requestId, ...params } = values;
      try {
        yield api.post(`/schedule-request/${requestId}/accept`, { ...params });
        if (AuthStore.hasRole([STUDY_ADMIN, STUDY_COORDINATOR])) {
          yield self.fetchRequestIsExist();
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isApproving = false;
      }
    }),
    decline: flow(function*(values: any) {
      self.isDeclining = true;
      const { requestId, ...params } = values;
      try {
        yield api.post(`/schedule-request/${requestId}/decline`, { ...params });
        if (AuthStore.hasRole([STUDY_ADMIN, STUDY_COORDINATOR])) {
          yield self.fetchRequestIsExist();
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isDeclining = false;
      }
    }),
    checkSlotStaffAvailability(
      start?: Date,
      durationMins?: number,
      staffId?: number,
      id?: number,
    ) {
      if (!start || !durationMins || !staffId) {
        return false;
      }

      const intersection = self.items.some(item => {
        const itemDate = item.getDate();
        if (
          !isSameDay(itemDate.start, start) ||
          item.staff?.id !== staffId ||
          item?.id === id
        ) {
          return false;
        }

        const startTimeMins = differenceInMinutes(start, startOfDay(start));

        const existingInterval = [
          item.startTimeMins,
          item.startTimeMins + (item.visit?.visitType?.durationMins ?? 0),
        ];
        const newInterval = [startTimeMins, startTimeMins + durationMins];

        const intervalsIntersection = !(
          newInterval[1] <= existingInterval[0] ||
          newInterval[0] >= existingInterval[1]
        );

        return intervalsIntersection;
      });

      return intersection;
    },
    setTimezone(timezone: SnapshotOrInstance<typeof TimeZoneCustom>) {
      applySnapshot(self, {
        ...self,
        timezone: timezone,
      });
    },
    resetTimezone() {
      applySnapshot(self, {
        ...self,
        timezone: undefined,
      });
    },
    resetRequestIsExist() {
      applySnapshot(self, { ...self, requestIsExist: false });
    },
  }));

export default CalendarScheduleStore.create({
  items: [],
  requestIsExist: false,
});
