/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { FormSpy } from "react-final-form";

import { Row } from "common/components/Form";
import FormControl, { TextFieldControl } from "common/components/FormControl";
import { ButtonBlue } from "common/components/Button/Button";
import Link from "common/components/Link";

import { SEND_TYPES } from "common/constants/changeEmail";

import { convertSecondsToTime } from "common/utils/timeUtils";

import { Form, Error, Text, ButtonSubmit } from "../styles";
import { PinCode, ResendCode, ResendCodeButton } from "./styles";

type Props = {
  onSubmit: (values: any) => void;
  onResend: () => void;
  isLoading: boolean;
  error: string;
  sendType?: string;
  email?: string;
  phoneNumber?: string;
  resetError?: () => void;
};

@observer
class EnterPinCodeForm extends React.Component<Props> {
  @observable isTimerOn: boolean = false;
  @observable timer: any = null;
  @observable seconds: number = 60;

  get type() {
    switch (this.props.sendType) {
      case SEND_TYPES.EMAIL:
        return "email";
      case SEND_TYPES.PHONE:
        return "phone number";
    }
  }

  get url() {
    const { sendType, email, phoneNumber } = this.props;
    switch (sendType) {
      case SEND_TYPES.EMAIL:
        return `mailto:${email}`;
      case SEND_TYPES.PHONE:
        return `tel:${phoneNumber}`;
    }
  }

  get label() {
    const { sendType, email, phoneNumber } = this.props;
    switch (sendType) {
      case SEND_TYPES.EMAIL:
        return email;
      case SEND_TYPES.PHONE:
        return phoneNumber;
    }
  }

  resetError = () => {
    const { error, resetError } = this.props;
    if (!error || !resetError) {
      return;
    }

    resetError();
  };

  startTimer = () => {
    this.seconds = 60;
    this.isTimerOn = true;
    this.timer = setInterval(this.countDown, 1000);
  };

  countDown = () => {
    this.seconds--;

    if (this.seconds === 0) {
      this.isTimerOn = false;
      clearInterval(this.timer);
    }
  };

  resendCode = () => {
    this.props.onResend();
    this.startTimer();
  };

  render() {
    const { onSubmit, error } = this.props;

    return (
      <FormControl onSubmit={onSubmit}>
        <FormSpy subscription={{ values: true }} onChange={this.resetError} />
        <Form>
          <Row>
            <Text>
              Please enter code that we sent to your {this.type}: <br />
              <Link href={this.url}>{this.label}</Link>
            </Text>
          </Row>
          <Row>
            <PinCode>
              <TextFieldControl
                name="pin"
                title="PIN code"
                placeholder="Enter the PIN code"
                canStretchOnError
                required
              />

              {this.isTimerOn ? (
                <ResendCode>{`Code sent. Resend in ${convertSecondsToTime(
                  this.seconds,
                )} seconds`}</ResendCode>
              ) : (
                <ResendCode>
                  Didn&apos;t get a code?{" "}
                  <ResendCodeButton onClick={this.resendCode}>
                    Resend
                  </ResendCodeButton>
                </ResendCode>
              )}
            </PinCode>
          </Row>
          {error && (
            <Row>
              <Error>{error}</Error>
            </Row>
          )}
          <ButtonSubmit render={this.renderButton} />
        </Form>
      </FormControl>
    );
  }

  renderButton = ({ onClick }: { onClick: () => void }) => (
    <ButtonBlue onClick={onClick} isLoading={this.props.isLoading}>
      Next
    </ButtonBlue>
  );
}

export default EnterPinCodeForm;
