/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const ACCEPTED_IMAGE_TYPES = "image/bmp,image/jpeg,image/jpg,image/png";

export const ERROR_COUNT_OF_IMAGES = "Please select only 1 photo.";
export const ERROR_COUNT_OF_FILES = "Please select only 1 file.";
export const ERROR_IMAGE_IS_CORRUPTED =
  "File is corrupted. Please use another file.";
export const ERROR_INCORRECT_FORMAT = "Incorrect format. Available formats";
