/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";
import styledWrapper from "common/components/StyledWrapper";

import { COLORS } from "common/constants/layout";
import DefaultRadioButtonGroup from "common/components/RadioButtonGroup";

export const Container = styled.div`
  flex: 1;

  width: 100%;
  min-height: 120px;

  padding: 24px;
  margin-bottom: 12px;

  border-radius: 6px;
  box-shadow: 0 9px 20px -1px rgba(0, 26, 216, 0.04);
  background-color: #ffffff;

  position: relative;

  display: flex;
  align-items: flex-start;

  cursor: pointer;
  user-select: none;

  border: ${props =>
    props.isSelected
      ? `solid 2px ${COLORS.CORNFLOWER}`
      : "solid 2px transparent"};

  &:hover {
    background-color: ${COLORS.BLUE_MASKING};
  }
`;

export const Title = styled.p`
  font-family: OpenSans-Bold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;

  line-height: normal;
  letter-spacing: normal;

  color: #37383d;
`;

export const Description = styled.div`
  font-family: OpenSans-Regular;
  font-size: 14px;

  color: #919bb8;

  margin-top: 6px;
`;

export const RadioButtonGroup = styledWrapper(DefaultRadioButtonGroup)`
  margin-top: 10px
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  margin-left: 27px;
`;

export const CheckMark = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;
