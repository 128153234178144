/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import Router from "navigation";

import GlobalPopup from "common/services/popup";

import { version } from "common/../../package.json";
import { environmentName } from "common/utils/env";

import { Version } from "./styles";

export default class App extends React.Component {
  render(): React.ReactNode {
    return (
      <>
        <Router />
        <GlobalPopup />
        <Version>{version + "\n" + environmentName}</Version>
      </>
    );
  }
}
