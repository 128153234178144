/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { set, getHours, getMinutes } from "date-fns";

import { Field } from "common/components/Form";
import TimePicker, {
  TimePickerExternalProps,
} from "common/components/Input/TimePicker";

import withFieldControl from "../FieldControl";

import { roundTime } from "common/utils/dateUtils";

type Props = {
  title?: string;
  required?: boolean;
  name: string;
  day: Date;
  needRound?: boolean;
  timeIntervals?: number;
} & TimePickerExternalProps;

class TimeFieldControl extends React.Component<FieldRenderProps<any> & Props> {
  onChange = (date: Date) => {
    const { day, input, needRound, timeIntervals = 15 } = this.props;

    const event: any = {
      target: { value: date },
    };

    if (!date) {
      input.onChange(event);
      return;
    }

    const dateTime = set(new Date(day), {
      hours: getHours(date),
      minutes: getMinutes(date),
    });

    event.target.value = needRound
      ? roundTime(dateTime, timeIntervals)
      : dateTime;

    input.onChange(event);
  };

  render() {
    const { title, required, input, meta, ...props } = this.props;

    return (
      <Field title={title} required={required}>
        <TimePicker
          input={input}
          onChange={this.onChange}
          error={meta.touched && meta.error}
          {...props}
        />
      </Field>
    );
  }
}

export default withFieldControl(TimeFieldControl);
