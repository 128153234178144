/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  flow,
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  onSnapshot,
  SnapshotOrInstance,
} from "mobx-state-tree";
import { addDays, differenceInMinutes, startOfDay } from "date-fns";

import api, { assignAuth, assignOnRefresh } from "common/services/api";

import { formatDateToUTC, formatDateTimeToUTC } from "common/utils/dateUtils";
import { getAvailableSlots } from "common/utils/selfSchedule";
import {
  convertDateToUTC,
  convertZonedDateToUTC,
} from "studyAdmin/utils/calendar";

import { SimpleModel } from "models/common";
import { AuthModel } from "models/Auth";
import { TimeZoneCustom } from "models/TimeZoneCustom";

export const Location = types
  .model("Location", {
    id: types.number,
    name: types.string,
    address1: types.maybe(types.string),
    address2: types.maybe(types.string),
    city: types.maybe(types.string),
    isRemote: types.maybe(types.boolean),
    state: types.maybe(SimpleModel),
    zipCode: types.maybe(types.string),
    email: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
    siteDirection: types.maybe(types.string),
    siteTimeZone: types.maybe(
      types.model({ name: types.string, id: types.number }),
    ),
  })
  .views(self => ({
    get addressFull(): string {
      return [
        self.address1,
        self.address2,
        self.city,
        self.state?.name,
        self.zipCode,
      ]
        .filter(item => !!item)
        .join(", ");
    },
  }));

export const VisitType = types.model("VisitType", {
  id: types.number,
  durationMins: types.number,
  name: types.string,
});

export const Visit = types.model("Visit", {
  id: types.number,
  cutoffDate: types.string,
  earliestDate: types.string,
  name: types.string,
  visitType: types.maybe(VisitType),
});

const SelfScheduleStore = types
  .model({
    tokenData: types.maybe(AuthModel),
    step: types.optional(types.number, 1),
    studyId: types.maybe(types.number),
    studyName: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    visit: types.maybe(Visit),
    location: types.maybe(Location),
    date: types.maybe(types.Date),
    timezone: types.maybe(TimeZoneCustom),
    isLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get isScheduleStep() {
      return self.step === 4;
    },
    get isRequestIsSentStep() {
      return self.step === 5;
    },
    get timezoneValue() {
      return self.timezone?.timezoneIANA || self.timezone?.timezoneOffset;
    },
  }))
  .actions(self => ({
    softReset(step: number, resetEmail: boolean = false) {
      const { tokenData, studyId, studyName, email } = getSnapshot(self);
      applySnapshot(self, {
        step,
        tokenData,
        studyId,
        studyName: !resetEmail ? studyName : "",
        email: !resetEmail ? email : "",
      });
    },

    reset() {
      window.sessionStorage.removeItem("tokenData");
      applySnapshot(self, {});
    },
  }))
  .actions(self => ({
    refreshToken: flow(function*() {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/pin/refresh", {
          token: self.tokenData?.refreshToken,
        });
        if (result.accessToken) {
          self.tokenData = result;
        }
        return result;
      } catch (error) {
        console.error("error", error);
        self.reset();
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .actions(self => ({
    sendPinCode: flow(function*({ email }: { email: string }) {
      self.isLoading = true;
      try {
        self.email = email;

        yield api.post("/account/pin/send", {
          studyId: self.studyId,
          email: self.email,
        });
      } finally {
        self.isLoading = false;
      }
    }),

    confirmPinCode: flow(function*({ pin }: { pin: number }) {
      self.isLoading = true;
      try {
        const result = yield api.post("/account/pin/confirm", {
          studyId: self.studyId,
          email: self.email,
          pin,
        });

        if (result?.accessToken) {
          self.tokenData = result;
        }
      } finally {
        self.isLoading = false;
      }
    }),

    fetchSlots: flow(function*(values) {
      self.isLoading = true;
      try {
        const activityId = self.visit?.id;
        const locationId = values.locationId;
        const start = formatDateTimeToUTC(
          self.timezoneValue
            ? convertZonedDateToUTC(new Date(values.start), self.timezoneValue)
            : new Date(values.start),
        );
        const endDefault = startOfDay(addDays(new Date(values.end), 1));
        const end = formatDateTimeToUTC(
          self.timezoneValue
            ? convertZonedDateToUTC(endDefault, self.timezoneValue)
            : endDefault,
        );

        const { items } = yield api.get(
          `/self-schedule/activity/${activityId}/location/${locationId}/available-time/${start}/${end}/list`,
        );

        return getAvailableSlots({
          items,
          start: values.start,
          end: values.end,
          visitDuration: self.visit?.visitType?.durationMins,
          timezone: self.timezoneValue,
        });
      } finally {
        self.isLoading = false;
      }
    }),

    createRequest: flow(function*(slot?: Date) {
      self.isLoading = true;
      try {
        if (slot) {
          const activityId = self.visit?.id;
          const locationId = self.location?.id;
          const scheduledDateUTCDefault = convertDateToUTC(new Date(slot));
          const scheduledDateUTC = self.timezoneValue
            ? convertZonedDateToUTC(scheduledDateUTCDefault, self.timezoneValue)
            : scheduledDateUTCDefault;
          const scheduleDate = formatDateToUTC(scheduledDateUTC);
          const startTimeMins = differenceInMinutes(
            scheduledDateUTC,
            startOfDay(scheduledDateUTC),
          );

          yield api.post(
            `/self-schedule/activity/${activityId}/schedule-request`,
            {
              scheduleDate,
              startTimeMins,
              locationId,
            },
          );
        }
      } finally {
        self.isLoading = false;
      }
    }),

    stepForward() {
      self.step++;
    },

    stepBackward() {
      self.step--;
    },

    setStudyId(studyId: number) {
      self.studyId = studyId;
    },

    setStudyName(studyName: string) {
      self.studyName = studyName;
    },

    setVisit(visit: Instance<typeof Visit>) {
      self.visit = getSnapshot(visit);
    },

    setLocation(location?: Instance<typeof Location>) {
      self.location = getSnapshot(location);
    },

    setDate(date?: Date) {
      self.date = date;
    },

    setTokenData: (tokenData?: Instance<typeof AuthModel>) => {
      self.tokenData = tokenData;
    },

    afterCreate() {
      onSnapshot(self, ({ tokenData }) => {
        assignAuth(tokenData);
        assignOnRefresh(self.refreshToken);
        if (tokenData) {
          window.sessionStorage.setItem("tokenData", JSON.stringify(tokenData));
        }
      });
    },
    setTimezone(timezone: SnapshotOrInstance<typeof TimeZoneCustom>) {
      applySnapshot(self, {
        ...self,
        timezone: timezone,
      });
    },
    resetTimezone() {
      applySnapshot(self, {
        ...self,
        timezone: undefined,
      });
    },
  }));

const selfSchedule = SelfScheduleStore.create();

export default selfSchedule;
