/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const getWindowSize = () => {
  const documentElement = document.documentElement;
  const body = document.getElementsByTagName("body")[0];
  const width =
    window.innerWidth || documentElement.clientWidth || body.clientWidth;
  const height =
    window.innerHeight || documentElement.clientHeight || body.clientHeight;

  return { width, height };
};

export const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

export const isMacOS = /(Mac)/i.test(navigator.platform);
