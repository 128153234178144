/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};

  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
`;

export const Row = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:not(:first-of-type) {
    margin-left: ${({ marginLeft }) => marginLeft ?? "0px"};
  }
`;

export const Error = styled.p`
  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;
`;
