/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { DictionaryItem } from "common/types/models";

import CheckboxDefault from "common/components/Checkbox";

import { ContainerWrapper, Container, Row, Error } from "./styles";

type Props = {
  options: Array<DictionaryItem>;
  selected?: Array<DictionaryItem>;
  onChange: (option: Array<DictionaryItem>) => void;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  marginLeft?: string;
  error?: string;
};

function CheckboxGroup({
  options,
  disabled,
  onChange,
  selected,
  required,
  inline,
  marginLeft,
  error,
}: Props) {
  const handleChange = (option: DictionaryItem) => {
    let items = selected || [];
    if (items.includes(option)) {
      items = items.filter(item => item.value !== option.value);
    } else {
      items.push(option);
    }
    required ? items.length > 0 && onChange(items) : onChange(items);
  };

  return (
    <ContainerWrapper>
      <Container disabled={disabled} inline={inline}>
        {options.map((option, index) => (
          <Checkbox
            key={option.value}
            index={index}
            option={option}
            onChange={handleChange}
            isChecked={!!(selected && selected.includes(option))}
            disabled={disabled}
            marginLeft={inline ? marginLeft || "24px" : "0px"}
          />
        ))}
      </Container>
      {error && <Error>{error}</Error>}
    </ContainerWrapper>
  );
}

export default CheckboxGroup;

type CheckboxProps = {
  index: number;
  option: DictionaryItem;
  isChecked: boolean;
  onChange: (option: DictionaryItem, index: number) => void;
  disabled?: boolean;
  marginLeft?: string;
};

class Checkbox extends React.Component<CheckboxProps> {
  onClick = () => {
    const { option, onChange, disabled, index } = this.props;
    !disabled && onChange(option, index);
  };

  render() {
    const { isChecked, option, marginLeft } = this.props;
    return (
      <Row marginLeft={marginLeft}>
        <CheckboxDefault
          onClick={this.onClick}
          isChecked={isChecked}
          label={option.label}
          accent
        />
      </Row>
    );
  }
}
