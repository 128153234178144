/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

export const TimeZoneCustom = types
  .model("TimeZoneCustomModel", {
    value: types.string,
    text: types.string,
    offset: types.number,
    isDST: types.boolean,
    utc: types.array(types.string),
  })
  .views(self => ({
    get timezoneIANA() {
      return self.utc.find(timezone => !timezone.includes("Etc/GMT"));
    },
    get timezoneOffset() {
      return self.offset.toString();
    },
    get timezoneOffsetForCalendar() {
      let timezoneOffsetForCalendar = "GMT";
      const matches = self.text.match(/\(UTC(.{3}).*\).*$/);
      if (matches) {
        timezoneOffsetForCalendar += matches[1];
      }

      return timezoneOffsetForCalendar;
    },
  }));
