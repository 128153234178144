/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  addMinutes,
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getMinutes,
  getMonth,
  isEqual,
  isSameDay,
  isBefore,
  isToday,
  isValid,
  lastDayOfWeek,
  parse,
  parseISO,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMinutes,
  subMonths,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import {
  CALENDAR_VIEWS,
  RECURRING_OPTION_AFTER as OPTION_AFTER,
  RECURRING_OPTION_BY as OPTION_BY,
} from "studyAdmin/constants/calendar";

import { SERVER_DATE_FORMAT } from "common/utils/dateUtils";

import CalendarAvailabilityStore from "../stores/CalendarAvailabilityStore";
import CalendarScheduleStore from "../stores/CalendarScheduleStore";

export const getUniqEvents = (events: any) => {
  const uniqueIds = [...new Set(events.map(({ id }) => id))];
  return uniqueIds.map(id => events.find(event => event.id === id));
};

export const convertedDate = (date: string | Date): Date => {
  return isValid(parseISO(date)) ? parseISO(date) : date;
};

export const parseDateFromServer = (date: string) =>
  parse(date.slice(0, 10), SERVER_DATE_FORMAT, new Date());

export const getCalendarHeader = (date: Date) => {
  const start = startOfWeek(date);
  const end = lastDayOfWeek(date);

  const monthWithYear = (date: Date) =>
    `${format(date, "MMM")} ${format(date, "yyyy")}`;

  return getMonth(start) === getMonth(end)
    ? monthWithYear(start)
    : `${monthWithYear(start)} - ${monthWithYear(end)}`;
};

export const getCurrentTimeZone = () => {
  return format(new Date(), "O");
};

export const getDayOfMonth = (date: Date) => {
  return format(date, "dd");
};

export const getDayOfWeek = (date: Date, full?: boolean = false) => {
  return full ? format(date, "EEEE") : format(date, "E");
};

export const getEventDate = (date: string) => {
  return format(date, "EEEE, LLL dd, yyyy");
};

const getVisitTimeItem = (time: string) => {
  return getMinutes(time) ? format(time, "h:mm a") : format(time, "h a");
};

export const getEventTime = (start: Date, end: Date) => {
  return `${getVisitTimeItem(start)} - ${getVisitTimeItem(end)}`;
};

export const isDateToday = (date: Date, timezone: string) => {
  if (!timezone) {
    return isToday(date);
  }

  const today = convertDateToTimezone(new Date(), timezone);

  return isSameDay(today, date);
};

export const shiftFrontDateByMonths = (date: Date, amount: number) => {
  return startOfMonth(addMonths(date, amount));
};

export const shiftBackDateByMonths = (date: Date, amount: number) => {
  return endOfMonth(subMonths(date, amount));
};

export const getRange = ({ date, view }: { date: Date, view: string }) => {
  if (view === CALENDAR_VIEWS.DAY) {
    return { startDate: startOfDay(date), endDate: endOfDay(date) };
  }

  if (view === CALENDAR_VIEWS.WEEK) {
    return { startDate: startOfWeek(date), endDate: endOfWeek(date) };
  }

  if (view === CALENDAR_VIEWS.MONTH) {
    return {
      startDate: startOfWeek(startOfMonth(date)),
      endDate: endOfWeek(endOfMonth(date)),
    };
  }

  return { startDate: "", endDate: "" };
};

export const getOccurrencesDays = ({
  start,
  endDate,
  maxOccurrencesCount,
}: {
  start: Date,
  endDate?: Date,
  maxOccurrencesCount?: number,
}) => {
  return endDate
    ? eachDayOfInterval(
        {
          start,
          end: endOfDay(endDate),
        },
        { step: 7 },
      )
    : [...Array(maxOccurrencesCount).keys()].map(element =>
        addWeeks(start, element),
      );
};

export const getExceptions = ({
  start,
  endDate,
  maxOccurrencesCount,
  excludedNumbers,
}: {
  start: Date,
  endDate?: Date,
  maxOccurrencesCount?: number,
  excludedNumbers: Array<number>,
}) => {
  const dates = getOccurrencesDays({
    start,
    endDate,
    maxOccurrencesCount,
  });

  return excludedNumbers.map(exception => dates[exception - 1]);
};

export const getExceptionsNumber = ({
  start,
  endDate,
  maxOccurrencesCount,
  exceptions,
}: {
  start: Date,
  endDate?: Date,
  maxOccurrencesCount?: number,
  exceptions: Array<Date>,
}) => {
  const dates = getOccurrencesDays({
    start,
    endDate,
    maxOccurrencesCount,
  });

  return exceptions.map(
    exception => dates.findIndex(date => isEqual(date, exception)) + 1,
  );
};

/*
 * Available slot final-form utils
 */

export const getSplitDates = (dates: Array<>) => {
  const labels = [];

  dates.forEach(({ value }) => {
    if (!labels.includes(value)) {
      labels.push(value);
    }
  });

  return labels.map(label => ({
    dates: dates.filter(({ value }) => value === label).map(({ date }) => date),
    label,
  }));
};

export const getSplitOccurrences = (values: any) => {
  if (values.recurring) {
    if (values.endDate === OPTION_BY.value && values.endDateBy) {
      const dates = getOccurrencesDays({
        start: values.start,
        endDate: values.endDateBy,
      }).map(date => ({
        date,
        value: `${format(date, "LLL")}, ${format(date, "yyyy")}`,
      }));

      return getSplitDates(dates);
    }

    if (values.endDate === OPTION_AFTER.value && values.endDateAfter) {
      const dates = getOccurrencesDays({
        start: values.start,
        maxOccurrencesCount: Number.parseInt(values.endDateAfter),
      }).map(date => ({
        date,
        value: `${format(date, "LLL")}, ${format(date, "yyyy")}`,
      }));

      return getSplitDates(dates);
    }
  }

  return [];
};

export const getActiveOccurrencesCount = (values: any) => {
  const occurrences = getSplitOccurrences(values);

  return (
    occurrences.reduce((acc, val) => acc.concat(val.dates), []).length -
    values.exceptions.length
  );
};

export const convertDateWithTimeToUTC = (
  date: Date,
  hours: Number,
  minutes: Number,
  timezone: string,
): Date => {
  const dateUTCDefault = convertDateToUTC(set(date, { hours, minutes }));

  const dateUTC = timezone
    ? convertZonedDateToUTC(dateUTCDefault, timezone)
    : dateUTCDefault;

  return dateUTC;
};

export const getTimeZoneOffset = (date: string | Date): number =>
  new Date(date).getTimezoneOffset();

export const convertDateToLocal = (date: Date) =>
  subMinutes(date, getTimeZoneOffset(date));

export const convertDateToUTC = (date: Date) =>
  addMinutes(date, getTimeZoneOffset(date));

export const convertDateToTimezone = (
  date: Date | string,
  timezone: string,
) => {
  return utcToZonedTime(date, timezone);
};

export const convertZonedDateToUTC = (date: Date, timezone: string) => {
  return zonedTimeToUtc(date, timezone);
};

export const calculateTimezoneLabelByOffset = (offset: number) => {
  const hours =
    Math.trunc(-offset / 60) > 0
      ? `+${Math.trunc(-offset / 60)}`
      : Math.trunc(-offset / 60);
  const minutes = Math.abs(offset % 60);

  return minutes ? `GMT ${hours}:${minutes}` : `GMT ${hours}`;
};

export const getCurrentDateByTimezone = () => {
  const timezone =
    CalendarAvailabilityStore.timezoneValue ||
    CalendarScheduleStore.timezoneValue;

  return timezone ? convertDateToTimezone(new Date(), timezone) : new Date();
};

export const checkEventIsPast = (date, isTimeSlot) => {
  const newDate = isTimeSlot ? addMinutes(date, 15) : date;

  return isBefore(newDate, getCurrentDateByTimezone());
};
