/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";
import "react-quill/dist/quill.snow.css";

export const Container = styled.div`
  background-color: white;
  border-radius: 7px;
  margin-bottom: 40px;

  & .ql-toolbar.ql-snow,
  & .ql-container.ql-snow {
    border: none;
    border-bottom: 1px solid #efefef;
  }

  & .ql-snow .ql-picker.ql-font {
    width: 125px;
  }

  ${({ stickyToolbar }) =>
    stickyToolbar &&
    css`
      & .ql-toolbar.ql-snow {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
        border-radius: 7px 7px 0 0;
      }
    `}

  ${({ withBottomToolbar }) =>
    withBottomToolbar &&
    css`
      margin-bottom: 0;

      & .ql-toolbar.ql-snow {
        border: none;
        border-top: 1px solid #efefef;
      }

      & .ql-container.ql-snow {
        border: none;
      }
    `}
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export const ToolbarWrapper = styled.div`
  ${({ readOnly }) =>
    readOnly &&
    css`
      display: none;
    `}
`;
