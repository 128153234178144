/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const NONE = "NONE";
export const SUPER_ADMIN = "SUPER_ADMIN";
export const CUSTOMER_ADMIN = "CUSTOMER_ADMIN";
export const STUDY_ADMIN = "STUDY_ADMIN";
export const STUDY_COORDINATOR = "STUDY_COORDINATOR";
export const SITE_ADMIN = "SITE_ADMIN";
export const SITE_STAFF = "SITE_STAFF";
export const EVENT_STAFF = "EVENT_STAFF";
export const REPORTING = "REPORTING";
export const LOCATOR_MANAGER = "LOCATOR_MANAGER";

export const ROLE_ID: { [key: string]: number } = {
  SUPER_ADMIN: 1,
  CUSTOMER_ADMIN: 2,
  STUDY_ADMIN: 3,
  STUDY_COORDINATOR: 4,
  SITE_ADMIN: 5,
  SITE_STAFF: 6,
  EVENT_STAFF: 7,
  REPORTING: 8,
  LOCATOR_MANAGER: 9,
};

export const ROLES_ID_STUDY_STAFF = [
  ROLE_ID.STUDY_ADMIN,
  ROLE_ID.STUDY_COORDINATOR,
  ROLE_ID.SITE_ADMIN,
  ROLE_ID.SITE_STAFF,
  ROLE_ID.EVENT_STAFF,
  ROLE_ID.REPORTING,
];

export const ROLE_TYPE = {
  NONE,
  SUPER_ADMIN,
  CUSTOMER_ADMIN,
  STUDY_ADMIN,
  STUDY_COORDINATOR,
  SITE_ADMIN,
  SITE_STAFF,
  EVENT_STAFF,
  REPORTING,
  LOCATOR_MANAGER,
};

export const ROLE_TYPES = Object.values(ROLE_TYPE);
