/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const SYSTEM_ID = "System ID";
export const SYSTEM_ID_FIELD_NOTE =
  "Participant ID automatically assigned by the system";

export const STUDY_ID = "Study ID";
export const STUDY_ID_FIELD_NOTE = "Participant ID specified for the study";

export const DATE_ID = "Date";
export const DATE_FIELD_NOTE = "MM/DD/YYYY";

export const LAB_RESULT_FIELD_TYPE = {
  NUMBER: "Number",
  TEXT: "Text",
  CHOICE: "Choice",
  DATE: "Date",
};

export const LAB_RESULT_FIELD_NAME_RESTRICTED_VALUES = [
  ".",
  ",",
  "[",
  "]",
  "/",
  "|",
  "\\",
  "*",
  "@",
  "'",
];

export const LAB_RESULT_FIELD_NAME_RESTRICTED_VALUES_REGEXP = /^(\s*\d+\s*)*$/;
