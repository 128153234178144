/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";

import "tippy.js/themes/light.css";

import { Container, StyledTippy } from "./style";

type Props = {
  content?: string | React.ReactNode;
  className?: string;
  children: React.ReactElement;
  childrenWithEllipsis?: boolean;
  offset?: [number, number];
  theme?: string;
  placement?: string;
  shouldOpen?: boolean;
};

@observer
export default class Tooltip extends React.Component<Props> {
  render() {
    const {
      children,
      className,
      content,
      childrenWithEllipsis,
      offset,
      theme = "light",
      placement = "bottom-start",
      shouldOpen = true,
    } = this.props;

    if (!content) {
      return children;
    }

    return (
      <Container
        className={className}
        childrenWithEllipsis={childrenWithEllipsis}
      >
        {shouldOpen ? (
          <StyledTippy
            arrow={false}
            content={content}
            placement={placement}
            theme={theme}
            offset={offset}
            maxWidth={500}
          >
            {children}
          </StyledTippy>
        ) : (
          children
        )}
      </Container>
    );
  }
}
