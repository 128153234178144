/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styledWrapper from "common/components/StyledWrapper";
import styled from "styled-components";

import Link from "common/components/Link";
import { ButtonBlue } from "common/components/Button";
import { COLORS } from "common/constants/layout";

export const ForgotPasswordLink = styledWrapper(Link)`
  margin-top: 7px;
`;

export const RegisterButton = styledWrapper(ButtonBlue)`
`;

export const DoubleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    margin-right: 22px;
    flex: 1;
  }

  & > :last-child {
    margin-right: 0px;
  }
`;

export const LastRow = styled(DoubleRow)`
  margin-top: 60px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.p`
  font-family: OpenSans;
  font-size: 14px;
  color: ${COLORS.GRAY};

  margin-left: 10px;
  user-select: none;
`;

export const ErrorText = styled.p`
  font-size: 16px;
  font-family: "OpenSans-Regular";

  color: #f74a70;
`;
