/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Container = styled.div`
  min-width: 80px;
  width: 100%;

  ${({ size }) =>
    size.maxWidth &&
    css`
      .react-datepicker-wrapper {
        max-width: ${size.maxWidth};
      }
    `};

  ${({ size }) =>
    size.fullWidth &&
    css`
      .react-datepicker-wrapper {
        width: 100%;
      }
    `};

  .react-datepicker__time-container {
    width: 100px;
  }

  .react-datepicker__input-container input {
    max-width: ${({ size }) => (size.fullWidth ? "100%" : "144px")};
    width: 100%;
    height: 48px;

    cursor: pointer;

    padding: 0 16px;

    color: #333333;
    background-color: ${({ disabled }) =>
      disabled ? "#f0f2f7" : "transparent"};

    font-family: OpenSans-Regular;
    font-size: 16px;

    outline: none;

    border-radius: 7px;
    border: ${({ hasError }) =>
      hasError ? "solid 2px #f74a70;" : "solid 2px #d8ddeb"};

    &::placeholder {
      color: rgba(51, 51, 51, 0.4);
    }

    &:active {
      border: ${({ hasError }) =>
        hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
    }

    &:focus {
      border: ${({ hasError }) =>
        hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100px;
  }
`;

export const Error = styled.p`
  position: absolute;

  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 4px;
`;
