/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  applySnapshot,
  flow,
  SnapshotOrInstance,
  types,
} from "mobx-state-tree";

import api from "common/services/api";

export const HELP_TYPES = {
  about: "about",
  faq: "faq",
  terms: "terms",
  privacy: "privacy",
  userGuides: "user-guide",
};

export const HELP_CMS_TYPES: { [key: string]: string } = {
  about: "About",
  faq: "FAQ",
  terms: "TermsAndConditions",
  privacy: "PrivacyPolicy",
  "user-guide": "UserGuides",
};

const HelpDetails = types.model({
  created: types.string,
  updated: types.string,
  content: types.string,
});

const HelpItem = types.model({
  updated: types.string,
  cmsItemType: types.enumeration(Object.values(HELP_CMS_TYPES)),
  isEmpty: types.boolean,
});

const HelpStore = types
  .model({
    items: types.array(HelpItem),
    details: types.maybe(HelpDetails),
    terms: types.maybe(HelpDetails),
    privacyPolicy: types.maybe(HelpDetails),
    isLoading: types.optional(types.boolean, false),
    isDeleteLoading: types.optional(types.boolean, false),
    isSaved: types.optional(types.boolean, false),
    documentLoadingType: types.maybe(types.string),
  })
  .actions(self => ({
    fetch: flow(function*() {
      self.isSaved = false;
      self.isLoading = true;
      try {
        type Response = { items: SnapshotOrInstance<typeof HelpItem>[] };
        const { items }: Response = yield api.get("/cms/list");
        applySnapshot(self, { ...self, items });
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchByType: flow(function*(type: string) {
      self.isLoading = true;
      self.documentLoadingType = type;
      try {
        const details: SnapshotOrInstance<typeof HelpDetails> = yield api.get(
          `/cms/${type}`,
        );
        applySnapshot(self, { ...self, details });
        if (type === HELP_TYPES.privacy) {
          applySnapshot(self, { ...self, privacyPolicy: details });
        }
        if (type === HELP_TYPES.terms) {
          applySnapshot(self, { ...self, terms: details });
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
        self.documentLoadingType = undefined;
      }
    }),
    save: flow(function*(type: string, content: string) {
      self.isLoading = true;
      try {
        yield api.put(`/cms/${type}`, { content });

        self.isSaved = true;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    saveFile: flow(function*(type: string, value: any) {
      let isSuccess = false;
      let error = "";

      self.isLoading = true;
      try {
        const result: SnapshotOrInstance<typeof HelpDetails> = yield api.putFormData(
          `/cms/${type}`,
          value,
        );

        if (result.content) {
          self.isSaved = true;

          const index = self.items.findIndex(
            item => item.cmsItemType === HELP_CMS_TYPES[type],
          );

          self.items[index] = {
            isEmpty: false,
            updated: result.updated,
            cmsItemType: HELP_CMS_TYPES[type],
          };
        }
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
    deleteFile: flow(function*(type: string) {
      let isSuccess = false;
      let error = "";

      self.isDeleteLoading = true;
      try {
        const { success } = yield api.delete(`/cms/${type}`, {});

        if (success) {
          self.isSaved = true;

          const index = self.items.findIndex(
            item => item.cmsItemType === HELP_CMS_TYPES[type],
          );
          self.items[index] = {
            isEmpty: true,
            updated: "",
            cmsItemType: HELP_CMS_TYPES[type],
          };
        }

        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isDeleteLoading = false;
      }

      return { isSuccess, error };
    }),
    downloadUserGuide: flow(function*() {
      self.isLoading = true;
      self.documentLoadingType = HELP_TYPES.userGuides;
      try {
        const { blob } = yield api.getBlob(`/cms/user-guide/download`);
        return blob;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
        self.documentLoadingType = undefined;
      }
    }),
  }));

export default HelpStore.create();
