/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import ChangeEmailStore from "stores/ChangeEmailStore";

import Background from "common/components/Layout/Background/BackgroundLight";
import { Header } from "common/components/Layout";

import StepTitle from "./StepTitle";
import EnterEmailForm from "./EnterEmailForm";
import GetPinCodeForm from "./GetPinCodeForm";
import EnterPinCodeForm from "./EnterPinCodeForm";
import EnterNewEmailForm from "./EnterNewEmailForm";
import CheckInbox from "./CheckInbox";

import { SEND_TYPES } from "common/constants/changeEmail";

import {
  Container,
  Content,
  Title,
  NotificationText,
  SmallLink,
  Paper,
} from "./styles";

const pageTitles = [
  "Enter your current email",
  "Get PIN code",
  "Enter PIN code",
  "Enter new email address",
  "Check your inbox and confirm new email address",
];

const STEPS = {
  ENTER_EMAIL: 0,
  GET_PIN_CODE: 1,
  ENTER_PIN_CODE: 2,
  ENTER_NEW_EMAIL: 3,
  CHECK_INBOX: 4,
};

type Props = {};

@observer
class ChangeEmail extends React.Component<Props> {
  @observable currentStep: number = STEPS.ENTER_EMAIL;
  @observable error: string = "";

  @observable currentEmail: string | undefined;
  @observable newEmail: string | undefined;
  @observable sendType: string | undefined;
  @observable phoneId: string | undefined;

  componentDidMount() {
    ChangeEmailStore.reset();
  }

  componentWillUnmount() {
    ChangeEmailStore.reset();
  }

  resetError = () => {
    this.error = "";
  };

  enterEmailSubmit = async (values: { email: string }) => {
    this.resetError();

    const { isSuccess, error } = await ChangeEmailStore.validateEmail(values);

    if (isSuccess) {
      const { isSuccess, error } = await ChangeEmailStore.getPossibilities(
        values,
      );
      if (isSuccess) {
        this.currentEmail = values.email;
        this.currentStep = STEPS.GET_PIN_CODE;
      } else {
        this.error = error;
      }
    } else {
      this.error = error;
    }
  };

  getPinCodeSubmit = async (values: { sendType: string; phoneId?: string }) => {
    this.resetError();

    const phones = ChangeEmailStore.possibilities?.phones;

    const sendType = values.sendType;
    const phoneId =
      sendType === SEND_TYPES.PHONE && phones?.length === 1
        ? phones[0].id
        : values.phoneId;

    const { isSuccess, error } = await ChangeEmailStore.sendPinCode({
      email: this.currentEmail,
      sendType,
      phoneId,
    });

    if (isSuccess) {
      this.sendType = sendType;
      this.phoneId = phoneId;
      this.currentStep = STEPS.ENTER_PIN_CODE;
    } else {
      this.error = error;
    }
  };

  enterPinCodeSubmit = async ({ pin }: { pin: string }) => {
    this.resetError();

    const { isSuccess, error } = await ChangeEmailStore.confirmPinCode({
      email: this.currentEmail,
      pin,
    });

    if (isSuccess) {
      this.currentStep = STEPS.ENTER_NEW_EMAIL;
    } else {
      this.error = error;
    }
  };

  resendPinCode = async () => {
    this.resetError();

    const { error } = await ChangeEmailStore.resendPinCode({
      email: this.currentEmail,
      sendType: this.sendType,
      phoneId: this.phoneId,
    });

    if (error) {
      this.error = error;
    }
  };

  enterNewEmailSubmit = async (values: {
    currentEmail: string;
    email: string;
  }) => {
    this.resetError();

    const { isSuccess, error } = await ChangeEmailStore.changeEmail({
      email: values.email,
    });

    if (isSuccess) {
      this.newEmail = values.email;
      this.currentStep = STEPS.CHECK_INBOX;
    } else {
      this.error = error;
    }
  };

  render() {
    return (
      <Container>
        <Background />
        <Header />
        <Title>SMART Participant email address change service</Title>
        <Paper>
          <Content>
            <StepTitle
              title={pageTitles[this.currentStep]}
              page={this.currentStep}
            />
            {this.currentStep === STEPS.ENTER_EMAIL && (
              <EnterEmailForm
                isLoading={ChangeEmailStore.isLoading}
                onSubmit={this.enterEmailSubmit}
                error={this.error}
                resetError={this.resetError}
              />
            )}
            {this.currentStep === STEPS.GET_PIN_CODE && (
              <GetPinCodeForm
                isLoading={ChangeEmailStore.isLoading}
                onSubmit={this.getPinCodeSubmit}
                error={this.error}
                possibilities={ChangeEmailStore.possibilities}
              />
            )}
            {this.currentStep === STEPS.ENTER_PIN_CODE && (
              <EnterPinCodeForm
                isLoading={ChangeEmailStore.isLoading}
                onSubmit={this.enterPinCodeSubmit}
                onResend={this.resendPinCode}
                error={this.error}
                sendType={this.sendType}
                email={this.currentEmail}
                phoneNumber={ChangeEmailStore.findPhoneNumberById(this.phoneId)}
                resetError={this.resetError}
              />
            )}
            {this.currentStep === STEPS.ENTER_NEW_EMAIL && (
              <EnterNewEmailForm
                isLoading={ChangeEmailStore.isLoading}
                onSubmit={this.enterNewEmailSubmit}
                error={this.error}
                email={this.currentEmail}
                resetError={this.resetError}
              />
            )}
            {this.currentStep === STEPS.CHECK_INBOX && (
              <CheckInbox email={this.newEmail} />
            )}
          </Content>
        </Paper>
        {this.currentStep === STEPS.ENTER_EMAIL && (
          <NotificationText>
            If you lost access to your current email address, please contact
            your study admin or our support team by{" "}
            <SmallLink href="mailto:admin@smartmobileportal.com">
              admin@smartmobileportal.com
            </SmallLink>
          </NotificationText>
        )}
      </Container>
    );
  }
}

export default ChangeEmail;
