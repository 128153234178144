/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { parse } from "content-disposition";

import { ERROR_COUNT_OF_FILES, ERROR_INCORRECT_FORMAT } from "constants/index";

export const openDownloadedBlob = (blob: Blob, filename: string) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

function download(blob: Blob, name: string) {
  // @ts-ignore
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    const uri = window.URL.createObjectURL(blob);
    downloadURI({ uri, name });
  }
}

type DownloadURIProps = { uri: string; name: string; target?: string };

export function downloadURI({ uri, name, target }: DownloadURIProps) {
  const anchorElement = document.createElement("a");
  anchorElement.download = name;
  anchorElement.href = uri;
  anchorElement.target = target ?? "";
  document.body.appendChild(anchorElement);
  anchorElement.click();
  anchorElement.remove();
}

function getFilename(contentDisposition: string) {
  if (contentDisposition == null) {
    return "download";
  }

  const result = parse(contentDisposition);
  return result.parameters?.filename ?? "download";
}

type DownloadBlobProperties = {
  blob: Blob;
  contentDisposition: string;
};

export function downloadBlob({
  blob,
  contentDisposition,
}: DownloadBlobProperties) {
  const name = getFilename(contentDisposition);
  download(blob, name);
}

function base64toBlob(base64Data: string, contentType: string = "") {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

type DownloadBase64Properties = {
  base64: string;
  name: string;
  contentType?: string;
};

export function downloadBase64({
  base64,
  name,
  contentType,
}: DownloadBase64Properties) {
  const blob = base64toBlob(base64, contentType);
  download(blob, name);
}

export function acceptedFileFormats(fileTypes: string[]) {
  return fileTypes.map(format => format.split("/")[1]).join(" .");
}

export type Result = {
  success: boolean;
  error?: string;
};

const fileTypeIsValid = (file: File, fileTypes: string[]): Result => {
  const fileIsValid = fileTypes.includes(file.type);

  if (fileIsValid) {
    return {
      success: true,
      error: "",
    };
  }

  if (!fileIsValid) {
    return {
      success: false,
      error: `${ERROR_INCORRECT_FORMAT}: ${acceptedFileFormats(fileTypes)}.`,
    };
  }
  return fileIsValid;
};

const countOfFilesIsValid = (files: File[]): Result => {
  if (files.length > 1) {
    return {
      success: false,
      error: ERROR_COUNT_OF_FILES,
    };
  }

  return { success: true };
};

export const fileIsValid = (
  reader: FileReader,
  files: File[],
  fileTypes: string[],
): Result => {
  const [file] = files;

  let res = countOfFilesIsValid(files);
  if (!res.success) {
    return res;
  }

  res = fileTypeIsValid(file, fileTypes);
  if (!res.success) {
    return res;
  }

  return { success: true };
};
