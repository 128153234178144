/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { applySnapshot, flow, types } from "mobx-state-tree";

import createTableStore from "stores/templates/TableStore";

import api from "common/services/api";

import { getImageType } from "common/utils/images";

import { CustomerModel } from "models/Customer";
import { IMAGE_TYPES } from "constants/imageTypes";

const Logo = types.model("Logo", {
  id: types.number,
  logo: types.string,
});

const CustomersStore = createTableStore({
  fetch: function({ params }) {
    return api.get("/customer/list", params);
  },
  exportData: function({ params }) {
    return api.download("/customer/export", params);
  },
  itemModel: CustomerModel,
  searchBy: "name",
  initialOrder: {
    column: "registrationDate",
    desc: true,
  },
})
  .props({
    logos: types.array(Logo),
    isAddLoading: types.optional(types.boolean, false),
    isDeleteLoading: types.optional(types.boolean, false),
    isLogoLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetchLogos: flow(function*() {
      self.isLogoLoading = true;
      try {
        const ids = self.items.map(item => item.id);

        const logos: any = [];
        for (const id of ids) {
          type Response = { blob?: Blob; contentDisposition?: string };
          const response: Response = yield api.getBlob(
            `/customer/${id}/photo/download`,
            {
              imageType: getImageType(IMAGE_TYPES.WEB),
            },
          );
          if (response.blob) {
            logos.push({
              id,
              logo: URL.createObjectURL(response.blob),
            });
          }
        }

        applySnapshot(self, { ...self, logos: logos });
      } finally {
        self.isLogoLoading = false;
      }
    }),

    findCustomerNameById(id: number): string {
      return self.items.find(x => x.id === id)?.name ?? "";
    },

    addCustomer: flow(function*({ values }: { values: any }) {
      self.isAddLoading = true;
      let isSuccess = false;

      try {
        yield api.post(`/customer`, {
          ...values,
        });
        isSuccess = true;
      } finally {
        self.isAddLoading = false;
      }

      return isSuccess;
    }),

    deleteCustomer: flow(function*(customerId: number) {
      self.isDeleteLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.delete(`/customer/${customerId}`, {});
        isSuccess = true;
      } catch (e) {
        error = e.message;
      } finally {
        self.isDeleteLoading = false;
      }

      return { isSuccess, error };
    }),
  }))
  .views(self => ({
    getLogoByCustomerId(id: number) {
      return self.logos.find(x => x.id === id)?.logo;
    },
  }));

export default CustomersStore.create();
