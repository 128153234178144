/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import {
  Container,
  LetterAvatar,
  Label,
  Error,
  IconControl,
  IconControlsContainer,
} from "./styles";
import SvgI24Trash from "common/assets/icons/I24Trash";
import SvgI24Edit from "common/assets/icons/I24Edit";
import DropZone from "common/components/DropZone/DropZone";

import { ACCEPTED_IMAGE_TYPES } from "constants/index";
import { fileIsValid } from "common/utils/images";

type Props = {
  name: string;
  profilePhoto?: string;
  onSubmit: (file: File) => Promise<{ isSuccess: boolean; error?: string }>;
  onDelete: () => Promise<{ error: string }>;
  className?: string;
  isEditingAvailable?: boolean;
  size?: number;
  fontSize?: number;
};

@observer
export default class Avatar extends React.Component<Props> {
  dropZoneRef = React.createRef<DropZone>();
  reader = new FileReader();

  @observable error: string = "";

  get acceptedImageFormats() {
    return ACCEPTED_IMAGE_TYPES.replace(/image\//g, " .");
  }

  componentWillUnmount() {
    this.resetError();
  }

  resetError = () => {
    this.error = "";
  };

  onAddLogo = async (files: File[]) => {
    const { success, error } = await fileIsValid(this.reader, files);

    if (!success) {
      this.error = error ?? "";
      return;
    }

    const [file] = files;

    if (file) {
      const { onSubmit } = this.props;

      const { isSuccess, error } = await onSubmit(file);

      if (isSuccess) {
        this.resetError();
      } else {
        this.error = error ?? "";
      }
    }
  };

  openFileSelector = () => {
    if (this.dropZoneRef.current) {
      this.dropZoneRef.current.dropzone.element.click();
    }
  };

  onDeleteLogo = async () => {
    const { onDelete } = this.props;
    const { error } = await onDelete();

    if (error) {
      this.error = error;
      return;
    }
    this.resetError();
  };

  render() {
    const {
      name,
      profilePhoto,
      className,
      isEditingAvailable = true,
      size = 162,
      fontSize = 60,
    } = this.props;

    return (
      <Container className={className}>
        <Label
          size={`${size}px`}
          isEditingAvailable={isEditingAvailable}
          style={{
            backgroundImage: profilePhoto ? `url(${profilePhoto})` : "none",
          }}
        >
          {!profilePhoto && (
            <LetterAvatar name={name} size={size} fontSize={fontSize} />
          )}

          {isEditingAvailable && (
            <>
              <DropZone
                ref={this.dropZoneRef}
                componentConfig={{ postUrl: "no-url" }}
                acceptedFiles={ACCEPTED_IMAGE_TYPES}
                maxFiles={1}
                hideProgressBar={true}
                autoUpload={false}
                eventHandlers={{
                  addedfiles: this.onAddLogo,
                }}
              />

              <IconControlsContainer>
                {profilePhoto && (
                  <IconControl
                    icon={<SvgI24Trash />}
                    onClick={this.onDeleteLogo}
                  />
                )}

                <IconControl
                  icon={<SvgI24Edit />}
                  onClick={this.openFileSelector}
                />
              </IconControlsContainer>
            </>
          )}
        </Label>

        {this.error && <Error>{this.error}</Error>}
      </Container>
    );
  }
}
