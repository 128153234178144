/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const isEmptyObject = (value: any) =>
  value.constructor === Object &&
  (Object.keys(value).every(key => !value[key]) ||
    Object.keys(value).length === 0);

export const isObject = (value: any) =>
  value != null && value.constructor.name === "Object";

export const uniqueArray = (array: Array<any>) => [...new Set(array)];

export const capitalizeFirstLetter = (value: string) =>
  value[0].toUpperCase() + value.slice(1);

export function formatNumber(value: number): string {
  return Number(value)
    .toLocaleString()
    .replace(/\s/g, ",");
}

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

export const timeoutAsync = async (delay: number): Promise<void> => {
  return new Promise<void>(resolve => {
    setTimeout(resolve, delay);
  });
};

export const fullName = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}): string => {
  return [firstName, lastName].filter(Boolean).join(" ");
};

export const sortByGuiOrder = (
  items: Array<any> | undefined,
  getGuiOrderValue: (item: any) => number,
) => {
  if (items) {
    items.sort((a, b) => getGuiOrderValue(a) - getGuiOrderValue(b));
  }
};

export const trimString = ({
  value,
  maxLength,
}: {
  value?: string;
  maxLength: number;
}) => {
  if (!value) {
    return value;
  }

  if (value && value.length > maxLength) {
    return `${value.substr(0, maxLength)}...`;
  } else {
    return value;
  }
};

export const getExternalLink = (link?: string) =>
  link
    ? link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `//${link}`
    : "";

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
