/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types, applySnapshot } from "mobx-state-tree";

import { IMAGE_TYPES } from "constants/imageTypes";
import api from "common/services/api";

import { AuthDashboardModel } from "models/Auth";
import { getImageType } from "common/utils/images";

const AuthDashboardStore = types
  .model({
    data: types.optional(AuthDashboardModel, {}),
    isLoading: types.optional(types.boolean, false),
    studiesImages: types.optional(
      types.array(types.model({ studyId: types.number, avatar: types.string })),
      [],
    ),
    customersImages: types.optional(
      types.array(
        types.model({ customerId: types.number, avatar: types.string }),
      ),
      [],
    ),
    isImagesLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetch: flow(function*() {
      self.isLoading = true;
      try {
        const payload = yield api.get("/login-dashboard");
        applySnapshot(self.data, payload);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchStudiesImages: flow(function*() {
      self.isImagesLoading = true;
      try {
        const studyIds = self.data?.studies?.map(item => item.study.id) ?? [];

        const images: any = [];

        for (const studyId of studyIds) {
          type Response = { blob?: Blob; contentDisposition?: string };
          const response: Response = yield api.getBlob(
            `/study/${studyId}/photo/download`,
            {
              imageType: getImageType(IMAGE_TYPES.WEB),
            },
          );
          if (response && response.blob) {
            images.push({
              studyId,
              avatar: URL.createObjectURL(response.blob),
            });
          }
        }

        applySnapshot(self, { ...self, studiesImages: images });
      } finally {
        self.isImagesLoading = false;
      }
    }),
    fetchCustomersImages: flow(function*() {
      self.isImagesLoading = true;
      try {
        const customerIds = self.data?.spaces?.map(x => x.id) ?? [];

        const images: any = [];

        for (const customerId of customerIds) {
          type Response = { blob?: Blob; contentDisposition?: string };
          const response: Response = yield api.getBlob(
            `/customer/${customerId}/photo/download`,
            {
              imageType: getImageType(IMAGE_TYPES.WEB),
            },
          );
          if (response && response.blob) {
            images.push({
              customerId,
              avatar: URL.createObjectURL(response.blob),
            });
          }
        }

        applySnapshot(self, { ...self, customersImages: images });
      } finally {
        self.isImagesLoading = false;
      }
    }),
  }));

export default AuthDashboardStore.create();
