/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { FormSpy } from "react-final-form";

import { Row } from "common/components/Form";
import FormControl, { TextFieldControl } from "common/components/FormControl";
import { ButtonBlue } from "common/components/Button/Button";

import { emailValidator } from "common/utils/validators";

import { Form, Error, Text, ButtonSubmit } from "../styles";

type Props = {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  error: string;
  type?: string;
  email?: string;
  resetError?: () => void;
};

@observer
class EnterNewEmailForm extends React.Component<Props> {
  emailValidate = (newEmail: string) => {
    const { email } = this.props;

    return email === newEmail.trim()
      ? "New and current email addresses should not match"
      : emailValidator(newEmail)
      ? "Incorrect email"
      : "";
  };

  resetError = () => {
    const { error, resetError } = this.props;
    if (!error || !resetError) {
      return;
    }

    resetError();
  };

  render() {
    const { onSubmit, error, email } = this.props;

    return (
      <FormControl onSubmit={onSubmit} initialValues={{ currentEmail: email }}>
        <FormSpy subscription={{ values: true }} onChange={this.resetError} />
        <Form>
          <Row>
            <Text>Please enter your new email address.</Text>
          </Row>
          <Row>
            <TextFieldControl
              name="currentEmail"
              title="Current email"
              required
              disabled
            />
          </Row>
          <Row>
            <TextFieldControl
              name="email"
              title="New email"
              placeholder="New email address"
              validate={this.emailValidate}
              canStretchOnError
              required
            />
          </Row>
          <Row>
            <Text>
              We’ll send you a confirmation link to your new email address.
              You’ll have 24 hours to confirm the email update.
            </Text>
          </Row>
          {error && (
            <Row>
              <Error>{error}</Error>
            </Row>
          )}
          <ButtonSubmit render={this.renderButton} />
        </Form>
      </FormControl>
    );
  }

  renderButton = ({ onClick }: { onClick: () => void }) => (
    <ButtonBlue onClick={onClick} isLoading={this.props.isLoading}>
      Change email
    </ButtonBlue>
  );
}

export default EnterNewEmailForm;
