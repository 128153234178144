/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import { Row, Field } from "common/components/Form";
import TextInput from "common/components/Input/TextInput";

import { ForgotPasswordLink, LoginButton } from "./styles";

import { AuthData } from "common/types/models";

import { emailValidator } from "common/utils/validators";

type Props = {
  onSubmit: (payload: AuthData) => void;
  serverError: string;
  onForgotPassword: () => void;
  isLoading?: boolean;
};

@observer
class LoginForm extends React.Component<Props> {
  @observable login: string = "";
  @observable password: string = "";
  @observable loginError: string = "";

  @computed get canLogin(): boolean {
    return !!this.login && !!this.password;
  }

  onChangeLogin = (value: string) => {
    this.login = value.trim();
    this.loginError = "";
  };

  onChangePassword = (value: string) => {
    this.password = value.trim();
  };

  validate = () => {
    this.loginError = emailValidator(this.login);
    return !this.loginError;
  };

  onSubmit = (e: any) => {
    const { onSubmit } = this.props;
    // do not reload page in IE/Edge
    e.preventDefault();

    if (!this.validate()) return;
    onSubmit({
      login: this.login,
      password: this.password,
    });

    return false;
  };

  render() {
    const { serverError, isLoading, onForgotPassword } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <Row>
          <Field title="Email address" required>
            <TextInput
              value={this.login}
              onChange={this.onChangeLogin}
              error={this.loginError || serverError}
              canStretchOnError
            />
          </Field>
        </Row>
        <Row>
          <Field title="Password" required>
            <TextInput
              value={this.password}
              onChange={this.onChangePassword}
              type="password"
              autoComplete="current-password"
              error={serverError}
              canStretchOnError
            />
            <ForgotPasswordLink onClick={onForgotPassword}>
              Forgot your password?
            </ForgotPasswordLink>
          </Field>
        </Row>
        <LoginButton
          type="submit"
          disabled={!this.canLogin}
          isLoading={isLoading}
        >
          Login
        </LoginButton>
      </form>
    );
  }
}

export default LoginForm;
