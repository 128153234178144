/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, SnapshotOrInstance, types, Instance } from "mobx-state-tree";

import AuthStore from "stores/Auth";

import api from "common/services/api";

import { IMAGE_TYPES } from "constants/imageTypes";
import { ROLE_ID, ROLES_ID_STUDY_STAFF } from "constants/roles";

import { formatPhone } from "common/utils/phoneUtils";
import { getImageType } from "common/utils/images";
import { getRoleNameByRoleId } from "common/utils/auth";

import { SimpleModel } from "models/common";

const AdditionalInfo = types.model({
  role: types.maybe(SimpleModel),
  customer: types.maybe(SimpleModel),
  study: types.maybe(SimpleModel),
  site: types.maybe(SimpleModel),
});

const Profile = types.model({
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  phoneNumber: types.maybe(types.string),
  profileImage: types.maybe(types.string),
  email: types.maybe(types.string),
  currentRole: types.maybe(SimpleModel),
});

const ProfileStore = types
  .model({
    profile: types.maybe(Profile),
    additionalInfo: types.maybe(AdditionalInfo),
    profilePhoto: types.maybe(types.string),
    rolesCount: types.optional(types.number, 0),
    isLoading: types.maybe(types.boolean),
    isSaveLoading: types.optional(types.boolean, false),
    isAvatarLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get name() {
      return [self.profile?.firstName, self.profile?.lastName]
        .filter(Boolean)
        .join(" ");
    },
    get role() {
      return self.profile?.currentRole?.name ?? "";
    },
    get studyId() {
      return self.additionalInfo?.study?.id;
    },
    get studyName() {
      return self.additionalInfo?.study?.name;
    },
    get siteId() {
      return self.additionalInfo?.site?.id;
    },
    get isSuperAdmin() {
      return self.profile?.currentRole?.id === ROLE_ID.SUPER_ADMIN;
    },
    get isCustomerAdmin() {
      return self.profile?.currentRole?.id === ROLE_ID.CUSTOMER_ADMIN;
    },
    get isSiteStaff() {
      return self.profile?.currentRole?.id === ROLE_ID.SITE_STAFF;
    },
    get isStudyStaff() {
      if (self.profile?.currentRole) {
        return ROLES_ID_STUDY_STAFF.includes(self.profile.currentRole.id);
      }
    },
    get isStudyAdmin() {
      if (self.profile?.currentRole) {
        return self.profile?.currentRole?.id === ROLE_ID.STUDY_ADMIN;
      }
    },
    get hasChangeRoleButton() {
      return self.rolesCount > 1;
    },
    get profileEditData() {
      return {
        ...self.profile,
        phoneNumber: self.profile?.phoneNumber
          ? formatPhone(self.profile.phoneNumber)
          : undefined,
      };
    },
  }))
  .actions(self => ({
    fetch: flow(function*() {
      self.isLoading = true;
      try {
        type Response = Instance<typeof Profile>;
        const profile: Response = yield api.get("/user/-/profile");
        self.profile = profile;

        if (self.profile.currentRole) {
          AuthStore.setAuthRole(
            getRoleNameByRoleId(self.profile.currentRole?.id),
          );
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchAdditionalInfo: flow(function*() {
      self.isLoading = true;
      try {
        type Response = Instance<typeof AdditionalInfo>;
        const additionalInfo: Response = yield api.get("/user/-/profile/role");
        self.additionalInfo = additionalInfo;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchRolesCount: flow(function*() {
      self.isLoading = true;
      try {
        type Response = { count: number };
        const response: Response = yield api.get("/user/-/profile/role/count");

        self.rolesCount = response?.count ?? 0;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    save: flow(function*(values: SnapshotOrInstance<typeof Profile>) {
      let error = "";
      let isSuccess = false;
      self.isSaveLoading = true;

      try {
        yield api.put("/user/-/profile", values);
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isSaveLoading = false;
      }

      return { isSuccess, error };
    }),
    fetchAvatar: flow(function*() {
      self.profilePhoto = undefined;
      self.isAvatarLoading = true;

      try {
        type Response = { blob?: Blob; contentDisposition?: string };
        const response: Response = yield api.getBlob(
          "/user/-/profile/photo/download",
          {
            imageType: getImageType(IMAGE_TYPES.WEB),
          },
        );
        if (response.blob) {
          self.profilePhoto = URL.createObjectURL(response.blob);
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isAvatarLoading = false;
      }
    }),
    deleteAvatar: flow(function*() {
      self.isAvatarLoading = true;
      let success = false;
      let error = "";
      try {
        yield api.delete("/user/-/profile/photo", {});
        self.profilePhoto = undefined;
        success = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isAvatarLoading = false;
      }

      return { success, error };
    }),
    editAvatar: flow(function*(values: any) {
      let isSuccess = false;
      let error = "";

      try {
        yield api.putFormData(`/user/-/profile/photo`, {
          ...values,
        });
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      }

      return { isSuccess, error };
    }),
    checkAvatar: flow(function*() {
      let status = "";

      try {
        type Response = { operationStatus: string };
        const response: Response = yield api.get(
          `/user/-/profile/photo/status`,
          {
            imageType: getImageType(IMAGE_TYPES.WEB),
          },
        );
        status = response.operationStatus;
      } catch (e) {
        console.error("error", e);
      }

      return status;
    }),
  }));

export default ProfileStore.create();
