/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  & path,
  & use {
    fill: ${({ color }) => color};
  }

  &:hover path,
  &:hover use {
    fill: ${({ hoverColor, color }) => hoverColor ?? color};
  }
`;
