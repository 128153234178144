/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  border-radius: 50%;

  min-height: 32px;
  min-width: 32px;
  padding: 0;

  background-color: transparent;
  border: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:disabled):hover {
    background-color: rgba(86, 130, 246, 0.133);
  }

  &:not(:disabled):active {
    background-color: rgba(86, 130, 246, 0.4);
  }

  &:disabled {
    path,
    use {
      fill: ${COLORS.TEXT_LIGHT};
    }
  }
`;

export const Counter = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 16px;
  height: 16px;

  background-color: #f74a70;
  color: #ffffff;

  font-family: OpenSans-Bold;
  font-size: 10px;
  font-weight: bold;
  text-align: center;

  border-radius: 50%;
`;
