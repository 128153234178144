/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import SvgI16Check from "common/assets/icons/I16Check";

import { Container, Border, Label } from "./styles";

export type CheckboxExternalProps = {
  isChecked: boolean;
  onClick: () => void;
  label?: string;
  className?: string;
  accent?: boolean;
  disabled?: boolean;
  large?: boolean;
};

type Props = {} & CheckboxExternalProps;

class Checkbox extends React.Component<Props> {
  onClick = () => {
    const { onClick, disabled } = this.props;
    !disabled && onClick && onClick();
  };

  render() {
    const { className, disabled, isChecked, label, accent, large } = this.props;
    return (
      <Container
        className={className}
        disabled={disabled}
        onClick={this.onClick}
      >
        <Border disabled={disabled} isChecked={isChecked}>
          {isChecked && <SvgI16Check />}
        </Border>
        {label && (
          <Label large={large} accent={accent}>
            {label}
          </Label>
        )}
      </Container>
    );
  }
}

export default Checkbox;
