/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types, flow } from "mobx-state-tree";
import api from "common/services/api";

import { CustomerStaffModel } from "models/CustomerStaffModel";
import createTableStore from "stores/templates/TableStore";
import { deleteDuplicateRoles } from "stores/RolesStore";
import { InviteCustomerUserPayload } from "stores/study/StudyStaffStore";

const CustomerStaffStore = createTableStore({
  fetch: function({ params }) {
    return api.get(`/customer/customer-user/list`, params);
  },
  itemModel: CustomerStaffModel,
})
  .props({
    isInviteLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    inviteUser: flow(function*(payload: InviteCustomerUserPayload) {
      let isSuccess = false;
      let error = "";
      self.isInviteLoading = true;

      try {
        const uniqueRoles = deleteDuplicateRoles(payload.roles);
        const payloadUniqueRoles: InviteCustomerUserPayload = {
          ...payload,
          roles: uniqueRoles,
        };
        yield api.post(`/customer-user/invite`, payloadUniqueRoles);
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isInviteLoading = false;
      }

      return { isSuccess, error };
    }),
    deleteCustomerUser: flow(function*(customerUserId: number) {
      self.isLoading = true;
      let isSuccess = false;

      try {
        yield api.delete(`/customer-user/${customerUserId}`, {});
        isSuccess = true;
      } finally {
        self.isLoading = false;
      }

      return isSuccess;
    }),
  }));

export default CustomerStaffStore.create();
