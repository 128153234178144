/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const TIMEOUT_AUTO_LOGOUT = 1000 * 30;

export const TIMEOUT_AUTO_LOGOUT_SHOW_POPUP = 1000 * 60 * 2 + 1000 * 10;

export const USER_SESSION_RESTORE_TIMEOUT = 10 * 1000;
