/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI16ArrowForward(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#5682F6"
        fillRule="evenodd"
        d="M13.62 7.259a1 1 0 01.083 1.32l-.083.094L9.63 12.7a1 1 0 01-1.498-1.32l.083-.094L10.49 8.99H3a1 1 0 110-2h7.514L8.215 4.707a1 1 0 01-.083-1.32l.083-.094a1 1 0 011.32-.083l.095.083 3.99 3.966z"
      />
    </svg>
  );
}

export default SvgI16ArrowForward;
