/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI16Check(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#FFF"
        d="M6.5 10.586L3.207 7.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l7-7a1 1 0 10-1.414-1.414L6.5 10.586z"
      />
    </svg>
  );
}

export default SvgI16Check;
