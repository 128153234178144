/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Background } from "./styles";

function BackgroundLight() {
  return <Background />;
}

export default BackgroundLight;
