/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";

import { Form, Text } from "../styles";

type Props = {
  email?: string;
};

@observer
class CheckInbox extends React.Component<Props> {
  render() {
    const { email } = this.props;

    return (
      <Form>
        <Text>
          We sent the confirmation link to {email}. Please follow the link to
          confirm your new email address.
        </Text>
        <Text>
          Until confirmation, all the emails will be sent to the old email.
        </Text>
      </Form>
    );
  }
}

export default CheckInbox;
