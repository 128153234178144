/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types, applySnapshot } from "mobx-state-tree";
import api from "common/services/api";

import createTableStore from "stores/templates/TableStore";

import { formatDateTimeToUTC } from "common/utils/dateUtils";
import { convertZonedDateToUTC } from "studyAdmin/utils/calendar";

import { ActivityType } from "stores/study/ArmsAndActivitiesStore";

import { ADHOC_ACTIVITY_TYPES } from "studyAdmin/constants/adhocActivity";

export const ParticipantModel = types.model({
  id: types.number,
  name: types.maybe(types.string),
  manualParticipantId: types.maybe(types.string),
  email: types.maybe(types.string),
  phone: types.maybe(types.string),
  siteName: types.maybe(types.string),
  armName: types.maybe(types.string),
  status: types.maybe(types.string),
  nextActivityDate: types.maybe(types.string),
});

export const SlimItemModel = types.model({
  id: types.number,
  name: types.maybe(types.string),
});

const ParticipantsStore = createTableStore({
  fetch: function({ params }) {
    return api.get(`/study/-/participant/list`, params);
  },
  exportData: function({ self, params }) {
    return api.downloadPost(
      `/study/-/participant/export`,
      {
        ids: self.selectedIds,
      },
      params,
    );
  },
  itemModel: ParticipantModel,
  searchBy: "search",
})
  .props({
    selectedIds: types.optional(types.array(types.number), []),
    slimSites: types.optional(types.array(SlimItemModel), []),
    slimArms: types.optional(types.array(SlimItemModel), []),
    slimArmsFiltersList: types.optional(types.array(SlimItemModel), []),
    isAddParticipantLoading: types.optional(types.boolean, false),
    isUploadLoading: types.optional(types.boolean, false),
    isParticipantChecking: types.optional(types.boolean, false),
    isBatchActivityAdding: types.optional(types.boolean, false),
  })
  .actions(self => ({
    select(ids: Array<number>) {
      applySnapshot(self.selectedIds, ids);
    },
    addParticipant: flow(function*({
      siteId,
      data,
    }: {
      siteId: number;
      data: any;
    }) {
      self.isAddParticipantLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.post(`/site/${siteId}/participant`, data);
        isSuccess = true;
      } catch (e) {
        error = e.message;
      } finally {
        self.isAddParticipantLoading = false;
      }

      return { isSuccess, error };
    }),
    deleteParticipants: flow(function*(participantIds: Array<number>) {
      self.isLoading = true;
      let isSuccess = false;

      try {
        yield api.delete(`/participant`, { ids: participantIds });
        const selectedIds = self.selectedIds.filter(
          id => !participantIds.includes(id),
        );
        applySnapshot(self.selectedIds, selectedIds);
        isSuccess = true;
      } finally {
        self.isLoading = false;
      }

      return isSuccess;
    }),
    fetchSlimSites: flow(function*() {
      self.isLoading = true;
      let isSuccess = false;

      try {
        const { items } = yield api.get(`/study/-/site/slim-list`);
        applySnapshot(self.slimSites, items);

        isSuccess = true;
      } finally {
        self.isLoading = false;
      }

      return isSuccess;
    }),
    fetchSlimArms: flow(function*() {
      self.isLoading = true;
      let isSuccess = false;

      try {
        const { items } = yield api.get(`/study/-/arm/slim-list`);
        applySnapshot(self.slimArms, items);

        isSuccess = true;
      } finally {
        self.isLoading = false;
      }

      return isSuccess;
    }),
    fetchSlimArmsFiltersList: flow(function*() {
      self.isLoading = true;
      let isSuccess = false;

      try {
        const { items } = yield api.get(`/study/-/arm/slim-filtered-list`);
        applySnapshot(self.slimArmsFiltersList, items);

        isSuccess = true;
      } finally {
        self.isLoading = false;
      }

      return isSuccess;
    }),
    batchUpload: flow(function*(values: any) {
      self.isUploadLoading = true;
      let isSuccess = false;
      let error = "";

      type Response = {
        importedParticipantsSuccessCount?: number;
        importedParticipantsFailedCount?: number;
        fileDataResponse?: {
          fileName: string;
          data: string;
        };
      };
      let response: Response = {};

      try {
        const { siteId, ...otherValues } = values;
        response = yield api.postFormData(
          `/site/${siteId}/participant/import`,
          otherValues,
        );
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isUploadLoading = false;
      }

      return {
        isSuccess,
        error,
        successCount: response?.importedParticipantsSuccessCount,
        failedCount: response?.importedParticipantsFailedCount,
        fileName: response?.fileDataResponse?.fileName,
        file: response?.fileDataResponse?.data,
      };
    }),

    getCanCreateBatchActivity: flow(function*(values: any) {
      self.isParticipantChecking = true;

      try {
        return yield api.get("/participant/adhoc-activity/batch/validate", {
          ...values,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isParticipantChecking = false;
      }
    }),

    createBatchAdhocActivity: flow(function*({
      activityType,
      data,
      timezone,
    }: {
      activityType: ActivityType;
      data: any;
      timezone?: string;
    }) {
      self.isBatchActivityAdding = true;

      const dateInfo =
        data.adHocActivityType === ADHOC_ACTIVITY_TYPES.TRIGGER_BASED
          ? {
              earliestDays: Number.parseInt(data.earliestDays),
              targetDays: Number.parseInt(data.targetDays),
              cutoffDays: Number.parseInt(data.cutoffDays),
              earliestDate: undefined,
              cutoffDate: undefined,
              targetDate: undefined,
            }
          : {
              earliestDate: formatDateTimeToUTC(
                timezone
                  ? convertZonedDateToUTC(data.earliestDate, timezone)
                  : data.earliestDate,
              ),
              cutoffDate: formatDateTimeToUTC(
                timezone
                  ? convertZonedDateToUTC(data.cutoffDate, timezone)
                  : data.cutoffDate,
              ),
              targetDate: formatDateTimeToUTC(
                timezone
                  ? convertZonedDateToUTC(data.targetDate, timezone)
                  : data.targetDate,
              ),
              earliestDays: undefined,
              targetDays: undefined,
              cutoffDays: undefined,
              triggeringActivityId: undefined,
            };
      const payload = { ...data, ...dateInfo };
      const notifications =
        payload.notifications?.map((notification: any) => ({
          ...notification,
          id: undefined,
        })) ?? [];

      try {
        yield api.post(`/participant/adhoc-activity/batch/${activityType}`, {
          ...payload,
          notifications,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isBatchActivityAdding = false;
      }
    }),
  }));

export default ParticipantsStore.create();
