/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";
import styledWrapper from "common/components/StyledWrapper";

import { ButtonBlue } from "common/components/Button";
import Link from "common/components/Link";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column

  height: 100px;
`;

export const Button = styledWrapper(ButtonBlue)`
  margin: 10px 0 16px;
`;

export const ReturnButton = styledWrapper(styled(Link)`
  padding-right: 5px;
`)`
 text-align: center;
`;

export const Text = styled.span`
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #919bb8;
`;

export const Title = styled.div`
  font-family: OpenSans-Regular;
  font-size: 16px;
  color: ${COLORS.CORNFLOWER};
  font-weight: 600;

  margin: auto;
  padding-right: 5px;
`;

export const NoteRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const Note = styled.div`
  display: flex;
  align-items: center;
`;

export const NoteText = styled.span`
  font-size: 14px;
  color: #5682f6;
  margin-left: 6px;
  white-space: nowrap;
`;
