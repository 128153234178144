/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

export const AdminModel = types.model("AdminModel", {
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  email: types.maybe(types.string),
});

export const CustomerModel = types.model("CustomerModel", {
  id: types.number,
  name: types.string,
  licensedStudies: types.number,
  registrationDate: types.string,
  admins: types.array(AdminModel),
});
