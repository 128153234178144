/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const PinCode = styled.div`
  width: 100%;
`;

export const ResendCode = styled.p`
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  font-weight: 600;
  color: #a9b4c9;
  text-align: right;
  margin-top: 26px;
`;

export const ResendCodeButton = styled.span`
  color: #5682f6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
