/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import NavBarButton from "./NavBarButton";

import { Container, FixedNavBar, BottomContainer } from "./styles";

type Props = {
  content: React.ReactNode;
  footer?: React.ReactNode;
};

class NavBar extends React.Component<Props> {
  render() {
    const { content, footer } = this.props;
    return (
      <Container>
        <FixedNavBar>
          {content}
          <BottomContainer>{footer}</BottomContainer>
        </FixedNavBar>
      </Container>
    );
  }
}

export default NavBar;
export { NavBarButton };
