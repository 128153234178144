/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  flow,
  types,
  applySnapshot,
  SnapshotOrInstance,
} from "mobx-state-tree";

import api, { filterEmptyValues } from "common/services/api";

export type RolePayload = {
  roleId: number;
  studyId?: number;
  siteId?: number;
};

export const Role = types.model({
  id: types.number,
  name: types.maybe(types.string),
});

export const CustomerUserRole = types
  .model({
    id: types.number,
    roleName: types.maybe(types.string),
    studyName: types.maybe(types.string),
    siteName: types.maybe(types.string),
  })
  .views(self => ({
    get fullName() {
      if (self.siteName && self.studyName) {
        return `${self.roleName} (${self.studyName}, ${self.siteName})`;
      } else if (self.studyName) {
        return `${self.roleName} (${self.studyName})`;
      } else {
        return `${self.roleName}`;
      }
    },
    get fullNameWithoutStudy() {
      if (self.siteName && self.studyName) {
        return `${self.roleName} (${self.siteName})`;
      } else {
        return `${self.roleName}`;
      }
    },
  }));

export function deleteDuplicateRoles(roles: RolePayload[]): RolePayload[] {
  const uniqueRolesMap = new Map();
  roles.map((role: RolePayload) =>
    uniqueRolesMap.set(
      `roleId:${role.roleId}_studyId:${role.studyId}_siteId:${role.siteId}`,
      role,
    ),
  );
  const uniqueRoles = Array.from(uniqueRolesMap.values());
  return uniqueRoles;
}

const RolesStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    customerUserId: types.maybe(types.number),
    studyId: types.maybe(types.number),
    customerUserRoles: types.maybe(types.array(CustomerUserRole)),
    allRoles: types.maybe(types.array(Role)),
    count: types.maybe(types.number),
  })
  .actions(self => ({
    fetch: flow(function*({
      customerUserId,
      studyId,
    }: {
      customerUserId: number;
      studyId?: number;
    }) {
      self.isLoading = true;
      try {
        const url = studyId
          ? `/study/${studyId}/customer-user/${customerUserId}/role/list`
          : `/customer-user/${customerUserId}/customer-user-role/list`;

        const { items, count } = yield api.get(url);

        applySnapshot(self, {
          studyId,
          customerUserId,
          customerUserRoles: items,
          count,
        });
      } finally {
        self.isLoading = false;
      }
    }),

    fetchAll: flow(function*() {
      self.isLoading = true;
      try {
        type Response = { items: SnapshotOrInstance<typeof Role>[] };
        const resp: Response = yield api.get(`/role/list`);
        applySnapshot(self, { allRoles: resp?.items ?? [] });
      } finally {
        self.isLoading = false;
      }
    }),

    assign: flow(function*({
      customerUserId,
      roles,
    }: {
      customerUserId: number;
      roles: { roles: RolePayload[] };
    }) {
      self.isLoading = true;
      let error = "";
      let isSuccess = false;

      try {
        const uniqueRoles = deleteDuplicateRoles(roles.roles);
        yield api.post(
          `/customer-user/${customerUserId}/customer-user-role/assign`,
          filterEmptyValues({ roles: uniqueRoles }),
        );
        isSuccess = true;
      } catch (e) {
        console.error(e);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),

    unassign: flow(function*({ roleId }: { roleId: number }) {
      self.isLoading = true;
      let isSuccess = false;

      try {
        yield api.delete(`/customer-user-role/${roleId}`, {});
        isSuccess = true;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess };
    }),

    unassignAll: flow(function*({
      isStudyAdmin,
      customerUserId,
      studyId,
    }: {
      isStudyAdmin?: boolean;
      customerUserId: number;
      studyId?: number;
    }) {
      self.isLoading = true;
      let isSuccess = false;

      try {
        const url = studyId
          ? isStudyAdmin
            ? `/study/${studyId}/customer-user/${customerUserId}/role/unassign`
            : `/study/${studyId}/customer-user/${customerUserId}/customer-user-role/unassign-all`
          : `/customer-user/${customerUserId}/customer-user-role/unassign-all`;

        yield api.delete(url, {});
        isSuccess = true;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess };
    }),
  }));

export default RolesStore.create();
