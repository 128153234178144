/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  applySnapshot,
  flow,
  types,
  getSnapshot,
  SnapshotOrInstance,
} from "mobx-state-tree";
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  endOfDay,
  format,
  isSameDay,
  isWithinInterval,
  startOfDay,
  set,
} from "date-fns";

import api from "common/services/api";

import {
  formatDateTimeToUTC,
  SERVER_DATE_FORMAT,
} from "common/utils/dateUtils";
import {
  convertDateToLocal,
  convertDateToTimezone,
  convertZonedDateToUTC,
  parseDateFromServer,
} from "studyAdmin/utils/calendar";

import { EVENT_TYPE } from "studyAdmin/constants/calendar";

import { TimeZoneCustom } from "models/TimeZoneCustom";

const RecurrencePattern = types.model({
  occursEvery: types.maybe(types.number),
  endDate: types.maybe(types.string),
  maxOccurrencesCount: types.maybe(types.number),
  excludedNumbers: types.maybe(types.array(types.number)),
});

const Availability = types.model({
  id: types.maybe(types.number),
  startDate: types.string,
  availableForSelfScheduling: types.maybe(types.boolean),
  startTimeMins: types.number,
  durationMins: types.number,
  recurrencePattern: types.maybe(RecurrencePattern),
  type: types.maybe(types.string),
});

const AvailabilityByDay = types.model({
  date: types.string,
  items: types.array(Availability),
});

const CalendarAvailabilityStore = types
  .model({
    items: types.array(AvailabilityByDay),
    timezone: types.maybe(TimeZoneCustom),
    isLoading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
    isDeleting: types.optional(types.boolean, false),
  })
  .views(self => ({
    get timezoneValue() {
      return self.timezone?.timezoneIANA || self.timezone?.timezoneOffset;
    },
  }))
  .views(self => ({
    get convertedItems() {
      const { items } = getSnapshot(self);

      return items
        .map(({ date, items }) =>
          items.map(item => ({
            ...item,
            startDate: parseDateFromServer(date),
            initDate: parseDateFromServer(item.startDate),
          })),
        )
        .flat()
        .map(item => {
          const startDefault = convertDateToLocal(
            addMinutes(startOfDay(item.startDate), item.startTimeMins),
          );
          const start = self.timezoneValue
            ? convertDateToTimezone(startDefault, self.timezoneValue)
            : startDefault;

          const initDateDefault = convertDateToLocal(
            addMinutes(startOfDay(item.initDate), item.startTimeMins),
          );
          const initDate = set(
            self.timezoneValue
              ? convertDateToTimezone(initDateDefault, self.timezoneValue)
              : initDateDefault,
            { hours: 0, minutes: 0, seconds: 0 },
          );

          const startDate = set(start, { hours: 0, minutes: 0, seconds: 0 });
          const end = addMinutes(start, item.durationMins);

          let recurrencePattern = item.recurrencePattern
            ? { ...item.recurrencePattern }
            : undefined;

          if (item.recurrencePattern?.endDate) {
            const recurrenceEndDateDefault = convertDateToLocal(
              addMinutes(
                startOfDay(parseDateFromServer(item.recurrencePattern.endDate)),
                item.startTimeMins,
              ),
            );
            const recurrenceEndDate = set(
              self.timezoneValue
                ? convertDateToTimezone(
                    recurrenceEndDateDefault,
                    self.timezoneValue,
                  )
                : recurrenceEndDateDefault,
              { hours: 0, minutes: 0, seconds: 0 },
            );

            recurrencePattern = {
              ...recurrencePattern,
              endDate: recurrenceEndDate,
            };
          }

          return {
            ...item,
            startDate,
            initDate,
            start,
            end,
            type: EVENT_TYPE.AVAILABILITY,
            recurrencePattern,
          };
        });
    },

    get intervals(): { [key: string]: any } {
      const { items } = getSnapshot(self);

      type SlotType = { date: string; slot: { start: Date; end: Date }[] };

      const mappedItems = (items: any, date: string) => {
        const overnightSlots: SlotType[] = [];
        const slots = items.map(
          (item: SnapshotOrInstance<typeof Availability>) => {
            const startDefault = convertDateToLocal(
              addMinutes(
                startOfDay(parseDateFromServer(date)),
                item.startTimeMins,
              ),
            );
            const start = self.timezoneValue
              ? convertDateToTimezone(startDefault, self.timezoneValue)
              : startDefault;
            const end = addMinutes(start, item.durationMins - 1);
            const slotDate = format(startOfDay(start), SERVER_DATE_FORMAT);

            if (isSameDay(start, addMinutes(end, -1))) {
              return { date: slotDate, slot: [{ start, end }] };
            } else {
              const durationMinsForCurrentDay = differenceInMinutes(
                addMinutes(endOfDay(start), 1),
                start,
              );
              const durationMinsForNextDay =
                item.durationMins - durationMinsForCurrentDay;
              const startNextDay = startOfDay(addDays(start, 1));
              const endNextDay = addMinutes(
                startNextDay,
                durationMinsForNextDay,
              );
              const overnightSlotDate = format(
                startNextDay,
                SERVER_DATE_FORMAT,
              );
              overnightSlots.push({
                date: overnightSlotDate,
                slot: [{ start: startNextDay, end: endNextDay }],
              });

              return {
                date: slotDate,
                slot: [{ start, end: endOfDay(start) }],
              };
            }
          },
        );

        return [...slots, ...overnightSlots];
      };

      return items.reduce((obj: { [key: string]: any }, current) => {
        const mappedObj = mappedItems(current.items, current.date);

        mappedObj.map((slot: SlotType) => {
          if (slot.date in obj) {
            const slots = obj[slot.date].concat(slot.slot);
            obj[slot.date] = slots;
          } else {
            obj[slot.date] = slot.slot;
          }
        });

        return obj;
      }, {});
    },
  }))
  .actions(self => ({
    fetchByRange: flow(function*({
      locationId,
      startDate,
      endDate,
    }: {
      locationId: number;
      startDate: Date;
      endDate: Date;
    }) {
      self.isLoading = true;
      try {
        const start = formatDateTimeToUTC(
          self.timezoneValue
            ? convertZonedDateToUTC(new Date(startDate), self.timezoneValue)
            : new Date(startDate),
        );
        const endDefault = startOfDay(addDays(new Date(endDate), 1));
        const end = formatDateTimeToUTC(
          self.timezoneValue
            ? convertZonedDateToUTC(endDefault, self.timezoneValue)
            : endDefault,
        );

        const response = yield api.get(
          `/location/${locationId}/availability/timeline/${start}/${end}`,
        );

        applySnapshot(self, { ...self, items: response.items });
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetch: flow(function*(id: number) {
      self.isLoading = true;
      try {
        return yield api.get(`/location/availability-template/${id}`);
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    batchCreateUpdate: flow(function*(locationId: number, values: any) {
      self.isUpdating = true;
      try {
        yield api.post(`/location/${locationId}/availability-template/batch`, {
          ...values,
        });
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isUpdating = false;
      }
    }),
    create: flow(function*(values: any) {
      self.isCreating = true;
      try {
        const { locationId, ...otherValues } = values;

        yield api.post(`/location/${locationId}/availability-template`, {
          ...otherValues,
        });
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isCreating = false;
      }
    }),
    update: flow(function*(values: any) {
      self.isUpdating = true;
      try {
        const { id, ...otherValues } = values;

        const response = yield api.put(
          `/location/availability-template/${id}`,
          { ...otherValues },
        );

        return response;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isUpdating = false;
      }
    }),
    delete: flow(function*(id: number) {
      self.isDeleting = true;
      try {
        yield api.delete(`/location/availability-template/${id}`);
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isDeleting = false;
      }
    }),
    checkTimeSlot(timeSlot: Date) {
      const slot = format(timeSlot, SERVER_DATE_FORMAT);
      const intervals = self.intervals[slot];

      return (
        intervals &&
        intervals.some((interval: { start: Date; end: Date }) =>
          isWithinInterval(timeSlot, interval),
        )
      );
    },
    checkDateSlot(dateSlot: Date) {
      const slot = format(dateSlot, SERVER_DATE_FORMAT);
      const intervals = self.intervals[slot];

      return !!intervals;
    },
    setTimezone(timezone: SnapshotOrInstance<typeof TimeZoneCustom>) {
      applySnapshot(self, {
        ...self,
        timezone: timezone,
      });
    },
    resetTimezone() {
      applySnapshot(self, {
        ...self,
        timezone: undefined,
      });
    },
  }));

export default CalendarAvailabilityStore.create({ items: [] });
