/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { auth as AuthStore } from "stores/Auth";
import HelpStore, { HELP_TYPES } from "stores/Help";

import Background from "common/components/Layout/Background/BackgroundBlue";
import Paper from "common/components/Paper";

import RegisterForm from "./RegisterForm";

import { correctTextFromQueryParams } from "common/utils/queryParamsUtils";
import { RegisterData } from "common/types/models";

import ROUTES from "navigation/routes";

import { Container, Content, Logo, Title, Subtitle } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class Register extends React.Component<Props> {
  @observable serverError: string = "";

  get userId(): number {
    const userId = new URLSearchParams(this.props.location.search).get(
      "userId",
    );
    return userId ? Number.parseInt(userId) : -1;
  }

  get code(): string {
    const code =
      new URLSearchParams(this.props.location.search).get("code") ?? "";

    return correctTextFromQueryParams(code);
  }

  async componentDidMount() {
    await Promise.all([
      HelpStore.fetchByType(HELP_TYPES.terms),
      HelpStore.fetchByType(HELP_TYPES.privacy),
    ]);
  }

  onSubmit = async (data: RegisterData) => {
    if (await this.signIn(data)) {
      this.props.history.push(ROUTES.LOGIN);
    }
  };

  signIn = async (data: RegisterData) => {
    this.serverError = "";
    try {
      await AuthStore.register(data);
      return true;
    } catch (error) {
      this.serverError = error.message;
      return false;
    }
  };

  render() {
    return (
      <Container>
        <Background />
        <Paper>
          <Content>
            <Logo />
            <Title>Welcome to SMART Study Portal!</Title>
            <Subtitle>
              Please fill in information below to finish account creation
            </Subtitle>

            <RegisterForm
              userId={this.userId}
              code={this.code}
              onSubmit={this.onSubmit}
              serverError={this.serverError}
              isLoading={AuthStore.isLoading || HelpStore.isLoading}
            />
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(Register);
