/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import SvgLogoH from "./assets/LogoH";
import SvgLogoHBlue from "./assets/LogoHBlue";
import SvgLogoHMin from "./assets/LogoHMin";

import { Logo } from "./styles";

const LogoBlue = () => (
  <Logo>
    <SvgLogoHBlue />
  </Logo>
);

const LogoMin = () => (
  <Logo>
    <SvgLogoHMin />
  </Logo>
);

const LogoWhite = ({ onClick }: { onClick?: () => void }) => (
  <Logo onClick={onClick}>
    <SvgLogoH />
  </Logo>
);

export { LogoBlue, LogoMin, LogoWhite };

// background-image: url(${props =>
// props.variant === "blue" ? blueLogo : (props.variant === "min" ? minLogo : logoWhiteSvg)});

// width: ${props => props.width ?? "100%"};
// height: ${props => props.height ?? "100%"};
