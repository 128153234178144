/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import Loader from "common/components/Loader";

type Props = {};

class ComponentLoader extends React.Component<Props> {
  render() {
    return <Loader />;
  }
}

export default ComponentLoader;
