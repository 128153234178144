/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import Email from "./Email";
import PinCode from "./PinCode";
import Visit from "./Visit";
import Schedule from "./Schedule";
import RequestIsSent from "./RequestIsSent";

type Props = {
  step: number;
};

const Steps = ({ step }: Props) => {
  switch (step) {
    case 1:
      return <Email />;
    case 2:
      return <PinCode />;
    case 3:
      return <Visit />;
    case 4:
      return <Schedule />;
    case 5:
      return <RequestIsSent />;
    default:
      return <Email />;
  }
};

export default Steps;
