/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { Field, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

type Props = {
  field: string;
  becomes?: any;
  removes?: any;
  set: string;
  to: any;
  callback?: () => void;
  doNotChangeOnInitialization?: boolean;
};

function WhenFieldChanges({
  field,
  becomes,
  removes,
  set,
  to,
  callback,
  doNotChangeOnInitialization,
}: Props) {
  return (
    <Field name={set} subscription={{}}>
      {({ input: { onChange } }) => (
        <FormSpy subscription={{}}>
          {() => (
            <OnChange name={field}>
              {(value, previous) => {
                if (doNotChangeOnInitialization && !previous) {
                  return;
                }

                if (becomes) {
                  if (Array.isArray(becomes)) {
                    if (becomes.includes(value)) {
                      onChange(to);
                      callback && callback();
                    }
                  } else {
                    if (value === becomes) {
                      onChange(to);
                      callback && callback();
                    }
                  }
                } else if (removes) {
                  if (Array.isArray(value)) {
                    if (!value.includes(removes)) {
                      onChange(to);
                      callback && callback();
                    }
                  }
                } else {
                  onChange(to);
                  callback && callback();
                }
              }}
            </OnChange>
          )}
        </FormSpy>
      )}
    </Field>
  );
}

export default WhenFieldChanges;
