/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { applySnapshot, types, flow } from "mobx-state-tree";
import { DictionaryItemModel } from "models/common";

type RawData = {
  items: Array<any>;
};

type DictionaryProperties = {
  fetch: (params?: any) => Promise<RawData>;
  getLabel: (item: any) => string;
  getValue: (item: any) => any;
  itemModel: any;
  extraItems?: Array<any>;
};

export function createCustomReadonlyDictionary({
  fetch,
  getValue,
  getLabel,
  itemModel,
  extraItems,
}: DictionaryProperties) {
  return types
    .model({
      items: types.array(itemModel),
      isLoading: types.optional(types.boolean, false),
    })
    .views(self => ({
      get options() {
        return [...(extraItems ?? []), ...self.items].map(item => ({
          value: getValue(item),
          label: getLabel(item),
        }));
      },
    }))
    .actions(self => ({
      fetch: flow(function*(...params: any) {
        try {
          self.isLoading = true;
          const data = yield fetch(...params);
          applySnapshot(self, data);
          return data;
        } catch (error) {
          console.log(error);
        } finally {
          self.isLoading = false;
        }
      }),
    }));
}

export default function createReadonlyDictionary({
  fetch,
  extraItems,
}: {
  fetch: (params?: any) => Promise<RawData>;
  extraItems?: Array<any>;
}) {
  return createCustomReadonlyDictionary({
    fetch,
    itemModel: DictionaryItemModel,
    getValue: ({ id }) => id,
    getLabel: ({ name }) => name,
    extraItems,
  });
}
