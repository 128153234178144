/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  endOfDay,
  isSameDay,
  startOfDay,
  subMinutes,
} from "date-fns";

import {
  convertDateToLocal,
  convertDateToTimezone,
  parseDateFromServer,
} from "studyAdmin/utils/calendar";

type AvailabilitySlot = {
  startTimeMins: number;
  durationMins: number;
};

type SlotByDay = {
  date: string;
  availabilitySlots: Array<AvailabilitySlot>;
};

type Slots = Array<SlotByDay>;

export const getAvailableSlots = ({
  items,
  start,
  end,
  visitDuration,
  timezone,
}: {
  items: Slots;
  start: Date;
  end: Date;
  visitDuration?: number;
  timezone?: string;
}) => {
  const overnightItems: Slots = [];
  const convertedSlots: Slots = [];
  items.map(({ date, availabilitySlots }) => {
    let start: Date = parseDateFromServer(date);
    availabilitySlots.map(slot => {
      const startDefault = convertDateToLocal(
        addMinutes(startOfDay(parseDateFromServer(date)), slot.startTimeMins),
      );
      start = timezone
        ? convertDateToTimezone(startDefault, timezone)
        : startDefault;
      const startTimeMins = differenceInMinutes(start, startOfDay(start));
      const end = addMinutes(start, slot.durationMins);

      if (isSameDay(start, subMinutes(end, 1))) {
        convertedSlots.push({
          date: start.toString(),
          availabilitySlots: [
            { startTimeMins, durationMins: slot.durationMins },
          ],
        });
      } else {
        const durationMinsForCurrentDay = differenceInMinutes(
          addMinutes(endOfDay(start), 1),
          start,
        );
        const durationMinsForNextDay =
          slot.durationMins - durationMinsForCurrentDay;
        const nextDay = startOfDay(addDays(start, 1));
        overnightItems.push({
          date: nextDay.toString(),
          availabilitySlots: [
            { startTimeMins: 0, durationMins: durationMinsForNextDay },
          ],
        });

        convertedSlots.push({
          date: start.toString(),
          availabilitySlots: [
            {
              startTimeMins: startTimeMins,
              durationMins: durationMinsForCurrentDay,
            },
          ],
        });
      }
    });
  });

  const convertedItemsFullList = [...convertedSlots, ...overnightItems];

  const mappedItems = eachDayOfInterval({ start, end }).map(day => {
    const items = convertedItemsFullList.filter(({ date }) =>
      isSameDay(new Date(date), day),
    );

    const availabilitySlots = items
      .map(({ availabilitySlots }) => availabilitySlots)
      .flat(1)
      .sort((a, b) => a.startTimeMins - b.startTimeMins);
    const [item] = items;

    return {
      date: item?.date ?? day,
      availabilitySlots: availabilitySlots || [],
    };
  });

  const slots = mappedItems.map(({ date, availabilitySlots }) => ({
    date,
    slots: availabilitySlots
      .map(({ startTimeMins, durationMins }) => {
        const timeToScheduleSlot = visitDuration
          ? durationMins - visitDuration
          : durationMins;
        const periods =
          timeToScheduleSlot < 0 ? 0 : Math.floor(timeToScheduleSlot / 15) + 1;

        return [...Array(periods).keys()].map(index =>
          addMinutes(startOfDay(new Date(date)), startTimeMins + index * 15),
        );
      })
      .flat(2),
  }));

  return slots;
};
