/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  applySnapshot,
  flow,
  SnapshotOrInstance,
  types,
} from "mobx-state-tree";

import { Study, StudyItem } from "models/Study";

import { IMAGE_TYPES } from "constants/imageTypes";

import api from "common/services/api";

import { getImageType } from "common/utils/images";
import { DictionaryItem } from "common/types/models";

const StudyFilter = types.model({
  value: types.string,
  label: types.string,
});

const StudyImage = types.model({ id: types.number, avatar: types.string });

const StudiesStore = types
  .model({
    items: types.array(StudyItem),
    itemsImages: types.array(StudyImage),
    details: types.maybe(Study),
    profilePhoto: types.maybe(types.string),
    statusFilter: types.maybeNull(StudyFilter),
    licenseFilter: types.maybeNull(types.array(StudyFilter)),
    isLoading: types.optional(types.boolean, false),
    isStudyCreating: types.optional(types.boolean, false),
    isLogoLoading: types.optional(types.boolean, false),
    isLogoUpdating: types.optional(types.boolean, false),
    leftPanelScroll: types.optional(types.number, 0),
    isPublishLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    resetProfileLogo() {
      self.profilePhoto = undefined;
    },
    applyStatusFilter(filter: DictionaryItem) {
      applySnapshot(self, { ...self, statusFilter: filter });
    },
    applyLicenseFilter(filter: DictionaryItem[]) {
      applySnapshot(self, { ...self, licenseFilter: filter });
    },
    resetStatusFilter() {
      self.statusFilter = null;
    },
    resetLicenseFilter() {
      self.licenseFilter = null;
    },
  }))
  .views(self => ({
    get isMobileSupported() {
      return self.details?.isMobileAppSupported;
    },
  }))
  .actions(self => ({
    fetch: flow(function*() {
      self.isLoading = true;
      try {
        type Response = { items?: SnapshotOrInstance<typeof StudyItem>[] };
        const response: Response = yield api.get("/study/profile/list", {
          StudyPublishStatus: self.statusFilter?.value || undefined,
          LicenceStatuses:
            self.licenseFilter?.map(item => item.value) || undefined,
        });
        applySnapshot(self, { ...self, items: response?.items || [] });
      } catch (error) {
        console.error("error", error);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchDetails: flow(function*(studyId: number) {
      self.isLoading = true;
      try {
        const details: SnapshotOrInstance<typeof Study> = yield api.get(
          `/study/${studyId}`,
        );
        applySnapshot(self, { ...self, details });
      } catch (error) {
        console.error("error", error);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchLogo: flow(function*(id: number) {
      self.resetProfileLogo();
      self.isLogoLoading = true;

      try {
        type Response = { blob?: Blob; contentDisposition?: string };
        const response: Response = yield api.getBlob(
          `/study/${id}/photo/download`,
          {
            imageType: getImageType(IMAGE_TYPES.WEB),
          },
        );
        if (response.blob) {
          self.profilePhoto = URL.createObjectURL(response.blob);
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLogoLoading = false;
      }
    }),
    setupStudy: flow(function*({ id, values }: { id: number; values: any }) {
      self.isStudyCreating = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.put(`/study/${id}/setup`, {
          ...values,
        });
        isSuccess = true;
      } catch (e) {
        console.log("error :>> ", e);
        error = e.message;
      } finally {
        self.isStudyCreating = false;
      }

      return { isSuccess, error };
    }),
    addSiteLocation: flow(function*({
      siteId,
      values,
    }: {
      siteId: number;
      values: any;
    }) {
      let isSuccess = false;
      let error = "";

      try {
        yield api.post(`/site/${siteId}/location`, {
          ...values,
        });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      }

      return { isSuccess, error };
    }),
    setLeftPanelScroll: (position: number) => {
      self.leftPanelScroll = position;
    },
    fetchAvatarsForStudies: flow(function*() {
      self.isLoading = true;
      try {
        const ids = self.items
          .map(item => (item.study.setupDate ? item.study.id : null))
          .filter(Boolean);

        const itemsAvatars: any = [];
        for (const id of ids) {
          type Response = { blob?: Blob; contentDisposition?: string };
          const response: Response = yield api.getBlob(
            `/study/${id}/photo/download`,
            {
              imageType: getImageType(IMAGE_TYPES.WEB),
            },
          );
          if (response.blob) {
            itemsAvatars.push({
              id,
              avatar: URL.createObjectURL(response.blob),
            });
          }
        }

        applySnapshot(self, { ...self, itemsImages: itemsAvatars });
      } finally {
        self.isLoading = false;
      }
    }),
    updateStudyLogo: flow(function*(studyId: number, values: any) {
      self.isLogoUpdating = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.putFormData(`/study/${studyId}/photo`, {
          ...values,
        });
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isLogoUpdating = false;
      }

      return { isSuccess, error };
    }),
    checkStudyLogo: flow(function*(studyId: number) {
      let status = "";

      try {
        type Response = { operationStatus: string };
        const response: Response = yield api.get(
          `/study/${studyId}/photo/status`,
          {
            imageType: getImageType(IMAGE_TYPES.WEB),
          },
        );
        status = response.operationStatus;
      } catch (e) {
        console.error("error", e);
      }

      return status;
    }),
    deleteStudyLogo: flow(function*(studyId: number) {
      self.isLogoUpdating = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.delete(`/study/${studyId}/photo`, {
          id: studyId,
        });
        self.resetProfileLogo();
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isLogoUpdating = false;
      }

      return { isSuccess, error };
    }),
    publish: flow(function*(studyId: number) {
      self.isPublishLoading = true;
      let success = false;
      let error = "";

      try {
        yield api.put(`/study/${studyId}/publish`, {});
        success = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isPublishLoading = false;
      }

      return { success, error };
    }),
    reset() {
      self.resetStatusFilter();
      self.resetLicenseFilter();
    },
  }));

export default StudiesStore.create();
