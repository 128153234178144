/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { format } from "date-fns";

import SelfScheduleStore from "stores/selfSchedule/SelfScheduleStore";

import { Row, Field } from "common/components/Form";

import Header from "../../Header";

import {
  ButtonBlue,
  ButtonWrapper,
  Container,
  Content,
  Controls,
  FieldValue,
  Lead,
} from "../styles";

type Props = {};

@observer
class RequestIsSent extends React.Component<Props> {
  onSubmit = () => {
    SelfScheduleStore.softReset(3);
  };

  render() {
    const { date, location, visit } = SelfScheduleStore;

    return (
      <Container>
        <Content>
          <Header />
          <Lead>
            {
              "Your appointment request has been submitted. You will receive a notification once the appointment is confirmed."
            }
          </Lead>
          {visit && (
            <Row>
              <Field title="Visit type">
                <FieldValue>{visit.visitType?.name}</FieldValue>
              </Field>
            </Row>
          )}
          {date && (
            <Row>
              <Field title="Date and time">
                <FieldValue>{format(date, "d LLL, p")}</FieldValue>
              </Field>
            </Row>
          )}
          {location?.name && (
            <Row>
              <Field title="Address">
                <FieldValue>{location.name}</FieldValue>
              </Field>
            </Row>
          )}
          {location?.addressFull && (
            <Row>
              <Field title="Address">
                <FieldValue>{location.addressFull}</FieldValue>
              </Field>
            </Row>
          )}
          {location?.siteDirection && (
            <Row>
              <Field title="Direction">
                <FieldValue>{location.siteDirection}</FieldValue>
              </Field>
            </Row>
          )}
        </Content>
        <Controls>
          <ButtonWrapper>
            <ButtonBlue onClick={this.onSubmit}>
              Schedule more visits
            </ButtonBlue>
          </ButtonWrapper>
        </Controls>
      </Container>
    );
  }
}

export default RequestIsSent;
