/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types } from "mobx-state-tree";

import api from "common/services/api";

const EmailConfirmStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    confirm: flow(function*({ userId, code }) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.post("/mobile/account/confirm", { userId, code });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
  }));

const emailConfirm = EmailConfirmStore.create();

export default emailConfirm;
