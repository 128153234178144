/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

export const Study = types.model({
  id: types.maybe(types.number),
  name: types.maybe(types.string),
  sites: types.array(types.maybeNull(types.string)),
});

export const Customer = types.model({
  id: types.maybe(types.number),
  name: types.maybe(types.string),
  studies: types.array(Study),
});

export const UserModel = types.model("UserModel", {
  userId: types.number,

  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  email: types.string,
  phone: types.maybe(types.string),
  customers: types.array(Customer),
  role: types.maybe(types.string),
});
