/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldRenderProps } from "react-final-form";

import Checkbox, { CheckboxExternalProps } from "common/components/Checkbox";

import withFieldControl from "../FieldControl";

type Props = {} & FieldRenderProps<any> & CheckboxExternalProps;

class CheckboxFieldControl extends React.Component<Props> {
  onClick = () => {
    const { input } = this.props;
    const event: any = {
      target: { value: !input.value },
    };
    input.onChange(event);
  };

  render() {
    const { input, label, large, ...props } = this.props;
    return (
      <Checkbox
        {...props}
        isChecked={input.value}
        onClick={this.onClick}
        label={label}
        large={large}
        accent
      />
    );
  }
}

export default withFieldControl(CheckboxFieldControl);
