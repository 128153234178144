/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Tooltip from "common/components/Tooltip";

import { Button, Counter, IconDot } from "./styles";

type Props = {
  icon: React.ReactNode;
  title: string;
  route: string;
  disabled?: boolean;
  counter?: number;
  iconWithDot?: boolean;
  iconDotColor?: string;
  beforeNavigating?: () => void;
} & RouteComponentProps;

class NavBarButton extends React.Component<Props> {
  get isActive() {
    const { location, route } = this.props;
    return location.pathname.toUpperCase().startsWith(route.toUpperCase());
  }

  onClick = () => {
    const { history, route, disabled, beforeNavigating } = this.props;
    if (disabled) return;

    if (history.location.pathname !== route) {
      beforeNavigating && beforeNavigating();
      history.push(route);
    }
  };

  render() {
    const { icon, title, counter, iconWithDot, iconDotColor } = this.props;
    return (
      <Tooltip
        content={title}
        offset={[0, -10]}
        theme="navbarButton"
        placement="right"
      >
        <Button isActive={this.isActive} onClick={this.onClick}>
          {icon}
          {iconWithDot && <IconDot color={iconDotColor} />}
          {counter && counter > 0 ? <Counter>{counter}</Counter> : null}
        </Button>
      </Tooltip>
    );
  }
}

export default withRouter(NavBarButton);
