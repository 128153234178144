/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

import { Domain, StatusNames } from "./Study";

import { formatDate } from "common/utils/dateUtils";

export const CustomerAdmin = types.model("CustomerAdmin", {
  name: types.maybe(types.string),
  email: types.maybe(types.string),
  id: types.number,
});

export const Feature = types.model("Feature", {
  id: types.number,
  name: types.string,
});

export const LicensedStudyModel = types
  .model({
    id: types.number,
    domains: types.array(Domain),
    studyName: types.maybe(types.string),
    licenceStartDate: types.string,
    licenceExpirationDate: types.string,
    licenceStatus: types.string,
    participantsCount: types.maybeNull(types.number),
    staffCount: types.maybeNull(types.number),
    publishStatus: types.enumeration(Object.values(StatusNames)),
    customerId: types.maybe(types.number),
    customerName: types.maybe(types.string),
    customerAdmins: types.array(CustomerAdmin),
    features: types.array(Feature),
    isSetupComplete: types.boolean,
  })
  .views(self => ({
    get currentLicensePeriod() {
      if (
        self.licenceStatus === "Active" ||
        self.licenceStatus === "Expiring"
      ) {
        return `${formatDate(self.licenceStartDate)} - ${formatDate(
          self.licenceExpirationDate,
        )}`;
      } else {
        return "";
      }
    },
    get isPublished() {
      return self.publishStatus === StatusNames.PUBLISHED;
    },
    get isExpired() {
      return (
        self.licenceStatus === "Expired" || self.licenceStatus === "Terminated"
      );
    },
  }));
