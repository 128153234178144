/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import IconButton from "common/components/Button/IconButton";

export const IconControlsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  display: none;

  transform: translate(-50%, -50%);

  z-index: 1;
`;

export const IconControl = styled(IconButton)`
  padding: 0px 7px;
`;

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 210px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;

  border-radius: 10px;

  &:hover {
    ${IconControlsContainer} {
      display: flex;
      flex-direction: row;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      border-radius: 10px;

      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border: solid 2px #f74a70;

      &:active {
        border: solid 2px #f74a70;
      }

      &:focus {
        border: solid 2px #f74a70;
      }
    `};

  ${({ image }) =>
    image
      ? css`
          background-image: url(${image});
        `
      : css`
          background-color: rgba(169, 180, 201, 0.24);
        `};

  .filepicker {
    height: 210px;

    border: none;
    background-color: transparent;
  }

  .dz-message {
    display: none;
  }

  .dropzone.dz-drag-hover {
    border: solid 2px #d8ddec;
  }

  .dropzone .dz-preview .dz-details .dz-filename:hover {
    display: none;
  }

  .dropzone .dz-preview.dz-error .dz-error-mark {
    display: none;
  }

  .dropzone .dz-preview.dz-error .dz-error-message {
    display: none;
  }

  .dropzone .dz-preview .dz-image {
    display: none;
  }

  .dz-details {
    display: none;
  }
`;

export const PlaceholderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
`;
