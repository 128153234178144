/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types } from "mobx-state-tree";

import createTableStore from "stores/templates/TableStore";
import DocumentModel from "models/DocumentModel";

import api from "common/services/api";

type Params = {
  getFetchUrl: (self: any) => string;
  getDownloadUrl: (id: number) => string;
  getDeleteUrl: (id: number) => string;
  getAddUrl: () => string;
  getEditUrl: (id: number) => string;
};

function createDocumentsStore({
  getFetchUrl,
  getDeleteUrl,
  getDownloadUrl,
  getAddUrl,
  getEditUrl,
}: Params) {
  return createTableStore({
    fetch: function({ self, params }) {
      return api.get(getFetchUrl(self), params);
    },
    itemModel: DocumentModel,
  })
    .props({
      isDeleteLoading: types.optional(types.boolean, false),
      isAddLoading: types.optional(types.boolean, false),
      isEditLoading: types.optional(types.boolean, false),
    })
    .actions(self => ({
      downloadDocument: flow(function*(id: number) {
        yield api.download(getDownloadUrl(id));
      }),
      deleteDocument: flow(function*(id: number) {
        self.isDeleteLoading = true;
        let isSuccess = false;

        try {
          yield api.delete(getDeleteUrl(id), {});
          isSuccess = true;
        } finally {
          self.isDeleteLoading = false;
        }

        return isSuccess;
      }),
      addDocument: flow(function*(values: any) {
        self.isAddLoading = true;
        let isSuccess = false;
        let error = "";

        try {
          yield api.postFormData(getAddUrl(), { ...values });
          isSuccess = true;
        } catch (e) {
          console.error("error", error);
          error = e.message;
        } finally {
          self.isAddLoading = false;
        }

        return { isSuccess, error };
      }),
      editDocument: flow(function*(id: number, values: any) {
        self.isEditLoading = true;
        let isSuccess = false;
        let error = "";

        try {
          yield api.putFormData(`${getEditUrl(id)}`, { ...values });
          isSuccess = true;
        } catch (e) {
          console.error("error", error);
          error = e.message;
        } finally {
          self.isEditLoading = false;
        }

        return { isSuccess, error };
      }),
    }));
}

export const StudyDocumentsStore = createDocumentsStore({
  getFetchUrl: ({ studyId }) => `/study/${studyId}/document/list`,
  getAddUrl: () => "/study/document",
  getEditUrl: id => `/study/document/${id}`,
  getDeleteUrl: id => `/study/document/${id}`,
  getDownloadUrl: id => `/study/document/${id}/download`,
})
  .props({
    studyId: types.maybe(types.number),
  })
  .actions(self => ({
    init: flow(function*(studyId: number) {
      self.studyId = studyId;
      yield self.fetch();
    }),
  }))
  .create();

export const ParticipantDocumentsStore = createDocumentsStore({
  getFetchUrl: ({ participantId }) =>
    `/participant/${participantId}/document/list`,
  getAddUrl: () => "/participant/document",
  getEditUrl: id => `/participant/document/${id}`,
  getDeleteUrl: id => `/participant/document/${id}`,
  getDownloadUrl: id => `/participant/document/${id}/download`,
})
  .props({
    participantId: types.maybe(types.number),
  })
  .actions(self => ({
    init: flow(function*(participantId: number) {
      self.participantId = participantId;
      yield self.fetch();
    }),
  }))
  .create();
