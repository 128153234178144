/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { ValueType, components, MenuListComponentProps } from "react-select";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { OptionProps } from "react-select/src/components/Option";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { FieldInputProps } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller/dist/InfiniteScroll";

import SvgI16ExitDark from "common/assets/icons/I16ExitDark";
import SvgI16Trash from "common/assets/icons/I16Trash";

import TrimmedText from "common/components/TrimmedText";
import Popup from "common/components/Popup";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import Snackbar from "common/components/Snackbar";
import Loader from "common/components/Loader";

import { isObject } from "common/utils";

import { DictionaryItem } from "common/types/models";

import { COLORS } from "common/constants/layout";

import {
  Select,
  CreatableSelect,
  Container,
  ClearButton,
  ClearButtonContainer,
  Wrapper,
  Label,
  MultiValuePlaceholder,
  MultiValueContainer,
  MultiValues,
  MultiValue,
  MultiValueLabel,
  MultiValueClear,
  OptionContainer,
  RemoveOptionButton,
  Error,
} from "./styles";

type DropdownDataProvider = {
  options: Array<DictionaryItem>;
  isLoading: boolean;
};

export type FilterOptionsType = { data: any; label: string; value: any };

export type DropdownExternalProps = {
  options?: Array<DictionaryItem>;
  defaultOptions?: Array<DictionaryItem>;
  disabled?: boolean;
  searchable?: boolean;
  multi?: boolean;
  loading?: boolean;
  placeholder?: string;
  provider?: DropdownDataProvider;
  value: DictionaryItem;
  label?: string;
  canStretchOnError?: boolean;
  hideIndicator?: boolean;
  filterOption?: (options: FilterOptionsType, search: string) => boolean;
  hideSelectedOptions?: boolean;
  menuPosition?: string;
  isClearable?: boolean;
  onInputChange?: (value: string) => void;
  maxMenuHeight?: number;
  size?: "small" | "middle";
  withClearButton?: boolean;
  shouldShowDeleteOptionIcon?: (option: DictionaryItem) => boolean;
  isCreatable?: boolean;
  maxWidth?: number;
  withPagination?: boolean;
  loadMore?: () => void;
  needMore?: boolean;
};

type CreatableSelectProps = {
  onCreateOption: (option: string) => Promise<DictionaryItem | undefined>;
  onRemoveOption: (id: string) => Promise<void>;
};

type Props = {
  onChange: (item: ValueType<DictionaryItem>) => void;
  error?: string | Object;
  input: FieldInputProps<string>;
} & DropdownExternalProps &
  CreatableSelectProps;

const REMOVE_OPTION_ICON_COLORS = {
  GREY: "rgba(255, 255, 255, 0.6)",
  WHITE: "rgba(255, 255, 255)",
};

const MultiValueContainerComp = (props: MultiValueProps<DictionaryItem>) => {
  const {
    selectProps: { inputValue },
  } = props;

  return !inputValue ? (
    <MultiValuePlaceholder>
      {props.selectProps.placeholder}
    </MultiValuePlaceholder>
  ) : null;
};

const OptionDefaultComp = (props: OptionProps<DictionaryItem>) => {
  const { Option } = components;
  const {
    data: { label },
  } = props;

  return (
    <Option {...props}>
      <TrimmedText text={label} lines={1}>
        {label}
      </TrimmedText>
    </Option>
  );
};

const OptionCreatableComp =
  (onClick: (option: DictionaryItem) => void, isLastOption: boolean) =>
  (props: OptionProps<DictionaryItem>) => {
    const { Option } = components;
    const {
      data: { label, value, __isNew__: isNew },
      isSelected,
    } = props;

    const onIconClick = () => onClick({ label, value });

    return (
      <Option {...props}>
        <OptionContainer>
          <TrimmedText text={label} lines={1}>
            {label}
          </TrimmedText>
          {!isNew && !isLastOption && (
            <RemoveOptionButton
              icon={<SvgI16Trash />}
              color={
                isSelected ? REMOVE_OPTION_ICON_COLORS.GREY : COLORS.TEXT_LIGHT
              }
              hoverColor={
                isSelected ? REMOVE_OPTION_ICON_COLORS.WHITE : COLORS.CORNFLOWER
              }
              onClick={onIconClick}
              optionIsSelected={isSelected}
              shouldStopPropagation
            />
          )}
        </OptionContainer>
      </Option>
    );
  };

const MenuListInfinityScroll = (
  props: MenuListComponentProps<DictionaryItem>,
) => {
  const { MenuList } = components;

  return (
    <MenuList {...props}>
      <InfiniteScroll
        pageStart={1}
        loadMore={props.selectProps.loadMore}
        hasMore={props.selectProps.hasMore}
        loader={<Loader />}
        useWindow={false}
      >
        {props.children}
      </InfiniteScroll>
    </MenuList>
  );
};

@observer
export default class Dropdown extends React.Component<Props> {
  @observable optionToDelete: DictionaryItem | undefined = undefined;
  @observable createOptionError: string = "";
  @observable deleteOptionError: string = "";

  @observable currentValueOptions: DictionaryItem[] = [];

  get sizeStyles() {
    const { size, maxWidth: maxWidthValue } = this.props;

    const maxWidth = maxWidthValue ? `${maxWidthValue}px` : maxWidthValue;

    switch (size) {
      case "small":
        return {
          minHeight: "32px",
          dropdownIndicatorPadding: "4px",
          valueContainerPadding: "0 8px",
          maxWidth,
        };
      case "middle":
        return {
          minHeight: "36px",
          dropdownIndicatorPadding: "4px",
          valueContainerPadding: "0 14px",
          maxWidth,
        };
      default:
        return {
          minHeight: "48px",
          dropdownIndicatorPadding: "8px",
          valueContainerPadding: "0 16px",
          maxWidth,
        };
    }
  }

  get shouldUpdateCurrentValues() {
    const { withPagination, loadMore } = this.props;

    return withPagination && !!loadMore;
  }

  @computed get options() {
    const { provider, options = [], defaultOptions = [] } = this.props;

    if (!defaultOptions.length && !this.currentValueOptions.length) {
      return provider?.options || options;
    }

    const currentOptions = [
      ...(provider?.options || options),
      ...defaultOptions,
      ...this.currentValueOptions,
    ];

    const uniqueOptionsMap = new Map<string, any>();
    currentOptions.map(option =>
      uniqueOptionsMap.set(
        `${
          isObject(option.value)
            ? option.value.uniqueId
              ? option.value.uniqueId
              : option.value.id
            : option.value
        }`,
        option,
      ),
    );

    return Array.from(uniqueOptionsMap.values());
  }

  @computed
  get values() {
    const { input } = this.props;
    const value = input?.value || [];

    return value.map(item =>
      this.options?.find(findItem =>
        isObject(item)
          ? findItem.value.uniqueId
            ? findItem.value.uniqueId === item?.uniqueId
            : findItem.value.id === item?.id
          : findItem.value === item,
      ),
    );
  }

  @computed
  get value() {
    const { provider, options, input } = this.props;
    const currentOptions = provider?.options || options;
    return (
      currentOptions?.find(item =>
        isObject(item.value)
          ? item.value.uniqueId
            ? item.value.uniqueId === input.value?.uniqueId
            : item.value.id === input?.value?.id
          : item.value === input?.value,
      ) || input?.value
    );
  }

  get optionComponent() {
    const { isCreatable, provider, options } = this.props;

    const isLastOption =
      provider?.options.length === 1 || options?.length === 1;

    return isCreatable
      ? OptionCreatableComp(this.openRemoveOptionPopup, isLastOption)
      : OptionDefaultComp;
  }

  get menuListComponent() {
    const { withPagination, loadMore } = this.props;

    const { MenuList } = components;

    return withPagination && loadMore ? MenuListInfinityScroll : MenuList;
  }

  @computed
  get components() {
    const { hideIndicator } = this.props;
    const components = {
      MultiValueContainer: MultiValueContainerComp,
      Option: this.optionComponent,
      MenuList: this.menuListComponent,
    };
    const withoutIndicator = {
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    };
    return hideIndicator ? { ...components, ...withoutIndicator } : components;
  }

  get error() {
    const { error } = this.props;

    if (!error) {
      return undefined;
    }

    return isObject(error) ? Object.values(error).join("") : error;
  }

  onChange = (value: ValueType<DictionaryItem>) => {
    if (this.shouldUpdateCurrentValues && Array.isArray(value)) {
      this.currentValueOptions = [...this.currentValueOptions, ...value];
    }

    this.props.onChange(value);
  };

  onRemoveItem = (value: ValueType<DictionaryItem>) => () => {
    const values = this.values.filter(item => item !== value);

    if (this.shouldUpdateCurrentValues) {
      this.currentValueOptions = this.currentValueOptions.filter(
        ({ value: optionValue }) => optionValue !== value,
      );
    }

    this.props.onChange(values);
  };

  onClearValues = () => {
    this.props.onChange([]);
  };

  getOptionValue = (option: DictionaryItem) => {
    return option?.value && isObject(option.value)
      ? option?.value?.uniqueId || option?.value?.id
      : option.value;
  };

  onCreateOption = async (option: string) => {
    const { onCreateOption, multi } = this.props;

    try {
      const newOption = await onCreateOption(option);

      if (newOption) {
        this.onChange(multi ? [...this.values, newOption] : newOption);
      }
    } catch (error) {
      this.createOptionError = error?.message;
    }
  };

  onRemoveOption = async () => {
    const { onRemoveOption, multi } = this.props;
    this.resetDeleteOptionError();

    if (!this.optionToDelete) {
      return;
    }

    try {
      await onRemoveOption(this.optionToDelete.value);
      this.closeRemoveOptionPopup();

      if (!multi) {
        this.onChange(undefined);
      }
    } catch (error) {
      this.deleteOptionError = error?.message;
    }
  };

  openRemoveOptionPopup = (option: DictionaryItem) => {
    this.optionToDelete = option;
  };

  closeRemoveOptionPopup = () => {
    this.optionToDelete = undefined;
    this.resetDeleteOptionError();
  };

  resetCreateOptionError = () => {
    this.createOptionError = "";
  };

  resetDeleteOptionError = () => {
    this.deleteOptionError = "";
  };

  render() {
    const {
      disabled,
      searchable,
      multi,
      loading,
      placeholder,
      input,
      label,
      provider,
      canStretchOnError,
      filterOption,
      hideSelectedOptions,
      onInputChange,
      maxMenuHeight,
      menuPosition,
      isClearable = false,
      withClearButton,
      shouldShowDeleteOptionIcon,
      isCreatable,
      loadMore,
      needMore,
    } = this.props;

    const SelectComponent = isCreatable ? CreatableSelect : Select;

    return (
      <>
        {!!this.optionToDelete && (
          <Popup onClose={this.closeRemoveOptionPopup}>
            <ConfirmationDialog
              title="Delete Option"
              text={`Are you sure you’d like to delete ${this.optionToDelete.label} completely from the system?`}
              acceptText="Delete"
              declineText="Cancel"
              onAccept={this.onRemoveOption}
              onDecline={this.closeRemoveOptionPopup}
              isLoading={provider?.isLoading}
              error={this.deleteOptionError}
            />
          </Popup>
        )}
        <Container>
          <Wrapper>
            <SelectComponent
              {...input}
              key={multi ? this.values : this.value}
              value={multi ? this.values : this.value}
              options={this.options}
              onChange={this.onChange}
              isDisabled={provider?.isLoading || loading || disabled}
              isSearchable={searchable}
              isMulti={multi}
              isLoading={provider?.isLoading || loading}
              placeholder={placeholder}
              error={this.error}
              menuPosition={menuPosition || "fixed"}
              components={this.components}
              filterOption={filterOption}
              onCreateOption={isCreatable ? this.onCreateOption : undefined}
              hideSelectedOptions={hideSelectedOptions}
              isClearable={isClearable}
              onInputChange={onInputChange}
              getOptionValue={this.getOptionValue}
              maxMenuHeight={maxMenuHeight}
              size={this.sizeStyles}
              loadMore={loadMore}
              hasMore={!!needMore}
            />
            {label && <Label>{label}</Label>}
          </Wrapper>
          {multi && this.values.length > 0 && (
            <MultiValueContainer>
              <MultiValues withClearButton={withClearButton}>
                {this.values.map(item => (
                  <MultiValue
                    key={isObject(item) ? item?.value?.id : item?.value}
                    hideClearIcon={
                      shouldShowDeleteOptionIcon &&
                      !shouldShowDeleteOptionIcon(item)
                    }
                  >
                    <MultiValueLabel>{item?.label}</MultiValueLabel>
                    {!disabled && (
                      <MultiValueClear onClick={this.onRemoveItem(item)}>
                        <SvgI16ExitDark />
                      </MultiValueClear>
                    )}
                  </MultiValue>
                ))}
              </MultiValues>
              {withClearButton && (
                <ClearButtonContainer>
                  <ClearButton
                    icon={<SvgI16Trash />}
                    text={"Clear"}
                    onClick={this.onClearValues}
                  />
                </ClearButtonContainer>
              )}
            </MultiValueContainer>
          )}
          {this.error && (
            <Error isStatic={canStretchOnError}>{this.error}</Error>
          )}
        </Container>

        <Snackbar
          show={!!this.createOptionError}
          onHide={this.resetCreateOptionError}
          text={this.createOptionError}
          type={"Alert"}
          timer={5000}
        />
      </>
    );
  }
}
