/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";

import ProfileStore from "stores/Profile";

import FormControl, { TextFieldControl } from "common/components/FormControl";
import { Title } from "common/components/Form";
import { ButtonBlue } from "common/components/Button";
import Avatar from "common/components/EditableAvatar";

import {
  emailValidator,
  lengthValidator,
  phoneNumberValidator,
} from "common/utils/validators";
import { fetchImageWithCheck } from "common/utils/images";

import { MASKS } from "common/constants/forms";

import {
  Container,
  Row,
  Cell,
  Error,
  Buttons,
  ButtonBack,
  ButtonSubmit,
} from "./styles";

type FormSubmitData = {
  values: any;
};

type Props = {
  onClose: () => void;
  onSubmit: (data: FormSubmitData) => void;
  isLoading: boolean;
  initialValues: any;
  error?: string;
};

@observer
class AddStudyForm extends React.Component<Props> {
  onAvatarSubmit = async (formFile: File) => {
    const { isSuccess, error } = await ProfileStore.editAvatar({
      formFile,
    });

    if (isSuccess) {
      fetchImageWithCheck(
        () => ProfileStore.checkAvatar(),
        () => ProfileStore.fetchAvatar(),
      );
    }

    return { isSuccess, error };
  };

  onAvatarDelete = () => {
    return ProfileStore.deleteAvatar();
  };

  render() {
    const { onClose, onSubmit, initialValues, error } = this.props;
    return (
      <FormControl onSubmit={onSubmit} initialValues={initialValues}>
        <Container>
          <Title title="My Profile" />
          <Avatar
            onSubmit={this.onAvatarSubmit}
            onDelete={this.onAvatarDelete}
            name={ProfileStore.name}
            profilePhoto={ProfileStore.profilePhoto}
          />
          <Row>
            <Cell>
              <TextFieldControl
                name="firstName"
                title="First name"
                placeholder="First name"
                validate={lengthValidator(100)}
                canStretchOnError
                required
              />
            </Cell>
            <Cell>
              <TextFieldControl
                name="lastName"
                title="Last name"
                placeholder="Last name"
                validate={lengthValidator(100)}
                canStretchOnError
                required
              />
            </Cell>
          </Row>
          <Row>
            <TextFieldControl
              name="phoneNumber"
              title="Mobile number"
              placeholder="+1(XXX)-XXX-XXXX"
              mask={MASKS.PHONE}
              validate={phoneNumberValidator}
            />
          </Row>
          <Row>
            <TextFieldControl
              name="email"
              title="Email"
              placeholder="Email"
              validate={emailValidator}
              disabled
            />
          </Row>
          {error && (
            <Row>
              <Error>{error}</Error>
            </Row>
          )}
        </Container>
        <Buttons>
          <ButtonBack onClick={onClose}>Cancel</ButtonBack>
          <ButtonSubmit render={this.renderButton} />
        </Buttons>
      </FormControl>
    );
  }

  renderButton = ({ onClick }: { onClick: () => void }) => (
    <ButtonBlue onClick={onClick} isLoading={this.props.isLoading}>
      Save
    </ButtonBlue>
  );
}

export default AddStudyForm;
