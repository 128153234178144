/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI24Users(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        fill="#919BB8"
        d="M14.5 2c2.21 0 4 1.763 4 3.937 0 .828-.15 1.976-.5 3.08 1.21.441 2.148 1.153 2.824 2.096C21.67 12.294 22 13.666 22 14.998a1 1 0 01-.516.876c-.39.215-1.065.52-1.97.823-.665.222-1.352.404-2.057.539.357.782.543 1.578.543 2.262a1 1 0 01-.571.903c-.44.209-1.2.51-2.207.807-1.675.495-3.44.792-5.222.792-1.783 0-3.547-.297-5.222-.792-1.006-.298-1.767-.598-2.207-.807A1 1 0 012 19.498c0-1.213.575-2.763 1.644-3.974a6.99 6.99 0 012.486-1.793c-.442-1.326-.63-2.733-.63-3.732a4.5 4.5 0 015.021-4.47C10.728 3.547 12.431 2 14.5 2zm-.63 11.734c.93.405 1.72.969 2.37 1.67a13.668 13.668 0 003.727-1.02c-.083-.748-.324-1.485-.769-2.106-.46-.644-1.12-1.134-2.027-1.436-.625.968-1.496 1.657-2.671 1.657a2.76 2.76 0 01-.29-.015c-.09.415-.203.836-.34 1.25zm.63-3.235c.944 0 2-2.296 2-4.562 0-1.063-.888-1.937-2-1.937s-2 .874-2 1.937c0 .108.002.217.007.325A4.495 4.495 0 0114.5 9.999c0 .157-.005.324-.014.5h.014zm-9.357 6.348a4.843 4.843 0 00-1.062 2.017c.38.145.803.29 1.264.426 1.503.444 3.082.71 4.655.71 1.573 0 3.152-.266 4.655-.71.46-.136.883-.28 1.262-.425a4.88 4.88 0 00-1.061-2.01 5.028 5.028 0 00-1.818-1.303c-.705 1.133-1.696 1.946-3.038 1.946-1.343 0-2.335-.814-3.04-1.949a5.015 5.015 0 00-1.817 1.298zM10 15.498c1.244 0 2.5-2.773 2.5-5.499a2.5 2.5 0 00-5 0c0 2.726 1.256 5.5 2.5 5.5z"
      />
    </svg>
  );
}

export default SvgI24Users;
