/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  applySnapshot,
  types,
  flow,
  SnapshotOrInstance,
} from "mobx-state-tree";

import { filterEmptyValues } from "common/services/api";

const DEFAULT_PAGE_SIZE = 50;

const TablePaginationModel = types.model({
  page: types.number,
  size: types.number,
  pages: types.number,
  totalRows: types.number,
});

export const TableOrderModel = types
  .model({
    column: types.maybe(types.string),
    desc: types.maybe(types.boolean),
  })
  .views(self => ({
    get orderBy() {
      return self.desc ? undefined : self.column;
    },
    get orderByDesc() {
      return self.desc ? self.column : undefined;
    },
  }));

const TableFilterModel = types.model({
  column: types.maybe(types.string),
  value: types.frozen({}),
});

type TableDataFetchProperties = {
  self: any;
  params: { [key: string]: any };
  body?: { [key: string]: any };
};

type TableStoreProperties = {
  itemModel: any;
  fetch: (args: TableDataFetchProperties) => any;
  exportData?: (args: TableDataFetchProperties) => any;
  pageSize?: number;
  searchBy?: string;
  initialOrder?: SnapshotOrInstance<typeof TableOrderModel>;
  initialFilter?: SnapshotOrInstance<typeof TableFilterModel>;
};

export default function createTableStore({
  itemModel,
  fetch,
  exportData,
  pageSize,
  searchBy,
  initialOrder,
  initialFilter,
}: TableStoreProperties) {
  return types
    .model({
      count: types.maybe(types.number),
      items: types.optional(types.array(itemModel), []),
      context: types.maybe(TablePaginationModel),
      isLoading: types.optional(types.boolean, false),
      isExporting: types.optional(types.boolean, false),
      orderContext: types.optional(TableOrderModel, initialOrder ?? {}),
      filterContext: types.optional(
        types.array(TableFilterModel),
        initialFilter ? [initialFilter] : [],
      ),
      search: types.maybeNull(types.string),
    })
    .views(self => ({
      get totalCount() {
        return self.count ?? 0;
      },
      get totalRows() {
        return self.context?.totalRows ?? 0;
      },
      get totalPages() {
        return self.context?.pages ?? 0;
      },
      get page() {
        return self.context?.page ?? 0;
      },
      get orderColumn() {
        return self.orderContext.column || "";
      },
      get isOrderDesc() {
        return !!self.orderContext.desc;
      },
      get filters() {
        const filters: { [column: string]: any } = {};
        for (const { column, value } of self.filterContext) {
          if (column && value) {
            filters[column] = value;
          }
        }
        return filters;
      },
    }))
    .actions(self => ({
      fetch: flow(function*(params?: any, body?: any) {
        self.isLoading = true;
        try {
          const { orderBy, orderByDesc } = self.orderContext || {};
          const response = yield fetch({
            self,
            params: filterEmptyValues({
              page: self.context?.page ?? 1,
              size: pageSize || DEFAULT_PAGE_SIZE,
              orderBy,
              orderByDesc,
              ...self.filters,
              ...(searchBy && { [searchBy]: self.search }),
            }),
            body,
          });

          self.count = response?.count ?? 0;
          self.items = response?.items ?? [];
          self.context = response?.context;
          return response;
        } finally {
          self.isLoading = false;
        }
      }),
      export: flow(function*() {
        if (!exportData)
          throw new Error("Specify export method for store to use it.");

        self.isExporting = true;
        try {
          const { orderBy, orderByDesc } = self.orderContext || {};
          yield exportData({
            self,
            params: filterEmptyValues({
              orderBy,
              orderByDesc,
              ...self.filters,
              ...(searchBy && { [searchBy]: self.search }),
            }),
          });
        } finally {
          self.isExporting = false;
        }
      }),
      applyPage(page: number) {
        if (self.context) {
          self.context.page = page;
        }
      },
      applyOrder({ column, desc }: SnapshotOrInstance<typeof TableOrderModel>) {
        applySnapshot(self.orderContext, { column, desc });
      },
      clearOrder() {
        applySnapshot(self.orderContext, {});
      },
      applyFilter({
        column,
        value,
      }: SnapshotOrInstance<typeof TableFilterModel>) {
        applySnapshot(self.filterContext, [
          ...self.filterContext.filter(entry => entry.column !== column),
          { column, value },
        ]);

        self.resetPage();
      },
      applyMultiFilter({
        column,
        value,
      }: SnapshotOrInstance<typeof TableFilterModel>) {
        const otherFilters = self.filterContext.filter(
          entry => entry.column !== column,
        );

        let newValue = value;
        const currentFilter = self.filterContext.find(
          entry => entry.column === column,
        );

        if (currentFilter) {
          const values = currentFilter.value
            ? currentFilter.value.split(",")
            : [];
          if (values.includes(value)) {
            newValue = values.filter(item => item !== newValue).join();
          } else {
            values.push(newValue);
            newValue = values.join();
          }
        }
        applySnapshot(self.filterContext, [
          ...otherFilters,
          { column, value: newValue },
        ]);

        self.resetPage();
      },
      clearFilter(column: string) {
        applySnapshot(
          self.filterContext,
          self.filterContext.filter(entry => entry.column !== column),
        );
      },
      clearFilters() {
        self.filterContext.clear();
      },
      findFilterValue(column: string): string {
        return (
          self.filterContext?.find(entry => entry.column === column)?.value ??
          ""
        );
      },
      applySearch(query: string) {
        self.search = query;
        self.resetPage();
      },
      clearSearch() {
        self.search = null;
      },
      resetPage() {
        if (self.context) {
          self.context.page = 1;
        }
      },
      reset() {
        self.items = [];
        self.resetPage();
        self.clearSearch();

        self.clearOrder();
        if (initialOrder) {
          self.applyOrder(initialOrder);
        }

        self.clearFilters();
        if (initialFilter) {
          self.applyFilter(initialFilter);
        }
      },
    }));
}
