/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { Portal } from "react-portal";

import SvgI24Exit from "common/assets/icons/I24Exit";

import { Container, Paper, ExitButton, Content } from "./styles";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  canCloseByOuterClick?: boolean;
  withoutExitButton?: boolean;
  exitButtonZIndex?: number;
};

class Popup extends React.Component<Props> {
  onClickOutside = () => {
    const { canCloseByOuterClick, onClose } = this.props;
    canCloseByOuterClick && onClose();
  };

  render() {
    const {
      children,
      onClose,
      exitButtonZIndex,
      withoutExitButton,
    } = this.props;

    return (
      <Portal>
        <Container onClick={this.onClickOutside}>
          <Paper>
            <Content>
              {!withoutExitButton && (
                <ExitButton
                  icon={<SvgI24Exit />}
                  onClick={onClose}
                  exitButtonZIndex={exitButtonZIndex}
                />
              )}
              {children}
            </Content>
          </Paper>
        </Container>
      </Portal>
    );
  }
}

export default Popup;
