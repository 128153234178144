/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import InputMask from "react-input-mask";

import DefaultIconButton from "common/components/Button/IconButton";

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InputBorder = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;

  box-sizing: content-box;

  height: 48px;
  min-width: 76px;
  width: 100%;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
  position: relative;
`;

export const Input = styled(InputMask)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: ${({ withoutControls }) =>
    !withoutControls ? "0 26px 0 16px" : "0 16px"};

  color: ${props => (props.disabled ? "rgba(51, 51, 51, 0.4)" : "#333333")};
  background-color: ${props => (props.disabled ? "#f0f2f7" : "transparent")};

  border-radius: 7px;
  outline: none;

  border: ${props =>
    props.hasError ? "solid 2px #f74a70;" : "solid 2px #d8ddeb"};

  &:active {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }

  &:focus {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }

  font-family: OpenSans-Regular;
  font-size: 16px;

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;

  color: #f74a70;

  margin-top: 4px;
`;

export const Label = styled.span`
  font-size: 14px;
  color: #919bb8;
  margin-left: 8px;
`;

export const Note = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

export const NoteText = styled.span`
  font-size: 14px;
  color: #5682f6;
  margin-left: 6px;
  white-space: nowrap;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconButton = styled(DefaultIconButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;

  padding-top: ${props => props.paddingTop ?? "0px"};
  padding-bottom: ${props => props.paddingBottom ?? "0px"};

  & > svg > path,
  & > svg > use {
    fill: #d8ddeb;
  }
`;
