/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import styledWrapper from "common/components/StyledWrapper";
import { Menu as DefaultMenu } from "common/components/Menu";
import DefaultFab from "common/components/Button/Fab";

export const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;

  flex-shrink: 0;

  overflow: hidden;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Fab = styled(DefaultFab)``;

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover ${Fab} {
    background-color: rgba(86, 130, 246, 0.133);
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 16px;
`;

export const Name = styled.p`
  font-family: OpenSans-SemiBold;
  font-size: 16px;

  color: white;
`;

export const Role = styled.span`
  font-family: OpenSans-Regular;
  font-size: 13px;

  line-height: 18px;

  color: #a4aaf6;
`;

export const Menu = styledWrapper(DefaultMenu)`
    position: absolute;
    top: 60px;
    right: 155px;
`;
