/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import api from "common/services/api";

import { createCustomReadonlyDictionary } from "stores/templates/ReadonlyDictionaryStore";

import { Location } from "./SelfScheduleStore";

const SelfScheduleLocationsDictionary = createCustomReadonlyDictionary({
  itemModel: Location,
  fetch: async function(activityId: number) {
    return api.get(
      `/self-schedule/activity/${activityId}/eligible-location/list`,
    );
  },
  getValue: value => value,
  getLabel: ({ name }) => name,
});

export default SelfScheduleLocationsDictionary.create();
