/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import DefaultLink from "common/components/Link";
import DefaultPaper from "common/components/Paper";
import DefaultLoader from "common/components/Loader";

import { COLORS } from "common/constants/layout";
import { MEDIA_QUERIES } from "constants/layout";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1198px;
  margin: 0 auto;

  padding: 0 23px;
`;

export const Content = styled.div`
  padding: 50px 60px 60px 60px;

  max-width: 590px;

  text-align: center;

  white-space: pre-wrap;

  ${MEDIA_QUERIES.MOBILE} {
    max-width: 440px;

    padding: 25px 30px 30px 30px;
  }
`;

export const Title = styled.p`
  font-family: OpenSans-Bold;
  font-size: 30px;
  color: ${COLORS.GRAY};
  margin-bottom: 18px;
  text-align: center;
  margin: 50px 0;
`;

export const Link = styled(DefaultLink)`
  font-size: 16px;
`;

export const Loader = styled(DefaultLoader)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Paper = styled(DefaultPaper)``;

export const Text = styled.p``;
