/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const OPERATION_STATUS = {
  PROCESSING: "Processing",
  FAILED: "Failed",
  SUCCESS: "Success",
};
