/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Wrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ inactive }) =>
    inactive &&
    css`
      text-decoration: line-through;
    `}

  ${({ inactiveColor }) =>
    inactiveColor &&
    css`
      text-decoration-color: ${inactiveColor};
    `}

  ${({ lines }) =>
    !!lines &&
    css`
      @supports (-webkit-line-clamp: ${lines}) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
      }
    `}
`;
