/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI24Exit(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        fill="#919bb8"
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L12 10.585l6.293-6.292a1 1 0 011.414 1.414L13.415 12l6.292 6.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L12 13.415l-6.293 6.292a1 1 0 11-1.414-1.414L10.585 12 4.293 5.707a1 1 0 01-.083-1.32z"
      />
    </svg>
  );
}

export default SvgI24Exit;
