/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";
import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 36px 0;
`;

export const Title = styled.p`
  margin-top: 12px;
  font-size: 18px;
  color: ${COLORS.GRAY};
  font-family: "OpenSans-SemiBold";
  user-select: none;
`;

export const Details = styled.p`
  font-size: 16px;
  color: ${COLORS.GRAY};
  max-width: 320px;
  font-family: "OpenSans-Regular";
  user-select: none;
`;
