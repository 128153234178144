/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI16Calendar(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#A9B4C9"
        fillRule="evenodd"
        d="M11 0a1 1 0 01.993.883L12 1h1c1.057 0 1.922.956 1.995 2.167l.005.166v9.334c0 1.232-.82 2.242-1.857 2.327L13 15H3c-1.057 0-1.922-.956-1.995-2.167L1 12.667V3.333c0-1.232.82-2.242 1.857-2.327L3 1h1A1 1 0 015.993.883L6 1h4a1 1 0 011-1zm2 7.5H3V13h10V7.5zM13 3h-1a1 1 0 01-1.993.117L10 3H6a1 1 0 01-1.993.117L4 3H3v2.5h10V3z"
      ></path>
    </svg>
  );
}

export default SvgI16Calendar;
