/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldInputProps } from "react-final-form";

import {
  Container,
  Wrapper,
  Input,
  Label,
  Note,
  NoteText,
  Error,
} from "./styles";

import { MaskProperties } from "common/constants/forms";
import SvgI16Attention from "common/assets/icons/I16Attention";

export type TextInputExternalProps = {
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  autoComplete?: string;
  mask?: MaskProperties;
  label?: string;
  note?: string;
  canStretchOnError?: boolean;
  readOnly?: boolean;
  small?: boolean;
};

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
  input?: FieldInputProps<string>;
} & TextInputExternalProps;

class TextInput extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange && onChange(e.currentTarget.value);
  };

  render() {
    const {
      value,
      error,
      disabled,
      placeholder,
      readOnly,
      type,
      required,
      autoComplete,
      mask,
      input,
      label,
      note,
      canStretchOnError,
      small,
    } = this.props;

    return (
      <Container hasError={!!error} small={small}>
        <Wrapper>
          <Input
            value={value}
            onChange={this.onChange}
            type={type}
            placeholder={placeholder}
            hasError={!!error}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete}
            readOnly={readOnly}
            small={small}
            {...input}
            {...mask}
          />
          {label && <Label>{label}</Label>}
          {note && (
            <Note>
              <SvgI16Attention />
              <NoteText>{note}</NoteText>
            </Note>
          )}
        </Wrapper>
        {error && (
          <Error isStatic={canStretchOnError} small={small}>
            {error}
          </Error>
        )}
      </Container>
    );
  }
}

export default TextInput;
