/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import ReactDOM from "react-dom";

import App from "common/entry";

import "core-js/es/object";
import "core-js/es/array";

import "./index.css";

import "mobx-react-lite/batchingForReactDom";

ReactDOM.render(<App />, document.getElementById("root"));
