/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import DefaultLoadable, {
  OptionsWithoutRender,
  LoadingComponentProps,
} from "react-loadable";

import ComponentLoader from "common/components/Loader/ComponentLoader";

type LoadableOptions = {
  LoaderLayout?: React.ElementType;
} & OptionsWithoutRender<any>;

const Loadable = (loader: any, options?: LoadableOptions) => {
  const { LoaderLayout } = options || {};

  options = {
    loader,
    ...options,
    ...{
      loading: LoaderLayout
        ? (props: LoadingComponentProps) => (
            <LoaderLayout>
              <ComponentLoader {...props} />
            </LoaderLayout>
          )
        : ComponentLoader,
      delay: 200,
      timeout: 10000,
    },
  };

  return DefaultLoadable(options);
};

export default Loadable;
