/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

export const CustomerStaffModel = types.model("CustomerStaffModel", {
  id: types.number,
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  email: types.maybe(types.string),
  phone: types.maybeNull(types.string),
});
