/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI24ChevronLeft(props) {
  return (
    <svg width={12} height={24} {...props}>
      <path
        fill="#A9B4C9"
        fillRule="evenodd"
        d="M9.742 4.258c.379.378.408.974.088 1.386l-.088.098L3.485 12l6.257 6.258a1.05 1.05 0 01-1.386 1.572l-.098-.088-7-7a1.05 1.05 0 010-1.484l7-7a1.05 1.05 0 011.484 0z"
      />
    </svg>
  );
}

export default SvgI24ChevronLeft;
