/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import TrimmedText from "common/components/TrimmedText";

import {
  TitleContainer,
  LeftContent,
  RightContent,
  TitleWrapper,
  TitleText,
  Badge,
} from "./Title.styles";

type Props = {
  title: string;
  badge?: React.ReactNode;
  controls?: React.ReactNode;
  leftPanel?: React.ReactNode;
  className?: any;
};

export default function Title({ title, badge, controls, className }: Props) {
  return (
    <TitleContainer className={className}>
      <LeftContent>
        <TitleWrapper badge={badge}>
          <TrimmedText text={title} lines={1}>
            <TitleText>{title}</TitleText>
          </TrimmedText>
        </TitleWrapper>
        {badge && <Badge>{badge}</Badge>}
      </LeftContent>
      {controls && <RightContent>{controls}</RightContent>}
    </TitleContainer>
  );
}
