/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
type Validator = (value: any) => string;

export const compose = (...validators: Array<Validator>) => (value: any) => {
  let result;
  for (let index = 0; index < validators.length; index++) {
    result = validators[index](value);
    if (result) return result;
  }
  return "";
};

// TODO add memoization

export const emailValidator = (email: string) => {
  // See https://emailregex.com
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!email || email.length <= 0) return "";
  if (!re.test(email)) return "A valid email address is required.";

  return "";
};

export const passwordValidator = (password: string) => {
  // TODO: improve validation rule
  if (!password || password.length < 6)
    return "Password length should be at least 6 characters.";

  return "";
};

export const emptyValidator = (value: any) => {
  return Number.isFinite(value) ||
    Boolean(typeof value === "string" ? value.trim() : value)
    ? ""
    : "This field is required.";
};

const phoneNumberRegexp = new RegExp(
  `^[+][0-9][(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$`,
);

export const phoneNumberValidator = (phoneNumber = "") => {
  return phoneNumberRegexp.test(phoneNumber.trim()) || phoneNumber === ""
    ? null
    : "Please enter a valid phone number.";
};

const zipCodeRegexp = new RegExp(`^[0-9]{5}`);

export const zipValidator = (value = "") =>
  zipCodeRegexp.test(value.trim()) ? null : "Please enter a valid zip code.";

export const armsValidator = (value = "") =>
  parseInt(value) > 0 ? null : "Please enter a valid number of arms.";

export const lengthValidator = (maxLength = 1) => (value = "") =>
  value.length > maxLength
    ? `The length must be ${maxLength} characters or fewer.`
    : "";

export const firstOfArrayValidator = (validator: Validator) => (
  values: Array<any>,
): string => {
  for (const value of values) {
    const result = validator(value);
    if (result) return result;
  }
  return "";
};

export const emptyArrayValidator = (validator: Validator) => (
  values: Array<any>,
): string => {
  return (Array.isArray(values) && values.length > 0) ||
    (!Array.isArray(values) && validator(values) === "")
    ? ""
    : "This field is required.";
};

export const uniqueValuesSplitByCommaValidator = (value: string = "") => {
  const result = value
    .split(",")
    .filter((el, index, arr) => arr.indexOf(el) !== index)
    .map(value => `"${value}"`);

  return result.length
    ? `The ${result.length > 1 ? `options` : `option`} ${result.join(
        ", ",
      )} already exists`
    : "";
};

export const emptyOptionValidator = (value: string = "") => {
  const hasEmptyOption = value.split(",").some(value => value === " ");

  return hasEmptyOption ? "Empty option is not allowed" : "";
};

export const labResultFieldNameValidator = (
  restrictedValues: string[] = [],
  restrictedValuesRegExp?: RegExp,
) => (value: string = "") => {
  const valueIsAcceptable = !restrictedValues
    .map(restrictedValue => value.includes(restrictedValue))
    .filter(Boolean).length;

  return valueIsAcceptable && !restrictedValuesRegExp?.test(value)
    ? ""
    : `At least 1 letter or an underscore required. Some characters are not allowed: ${restrictedValues.join(
        " ",
      )} `;
};

export const decimalNumberAsStringValidator = (value = "") =>
  value.charAt(value.length - 1) === "." ||
  (value.charAt(0) === "-" && value.length === 1) ||
  /^[-]?[0]{2,}[.]?.*$/.test(value)
    ? "The number format is incorrect"
    : "";

export const decimalNumberAsStringLengthValidator = (maxLength = 1) => (
  value = "",
) => {
  return value.replace(/\D+/g, "").length > maxLength
    ? `The length must be ${maxLength} digits or fewer.`
    : "";
};

export const countOfDaysValidator = (maxCountOfDays = 0) => (value = 0) =>
  value > maxCountOfDays
    ? `Please specify less than ${maxCountOfDays} days.`
    : "";

export const restrictedValuesValidator = (restrictedValues: string[] = []) => (
  value: string = "",
) => {
  const isError = restrictedValues
    .map(restrictedValue => value.includes(restrictedValue))
    .filter(Boolean).length;

  return isError ? `Restricted characters: ${restrictedValues.join(" ")} ` : "";
};

export const imageTypesValidator = (type: string, acceptedTypes: string) => {
  if (type) {
    if (!acceptedTypes.includes(type)) {
      const mappedTypes = acceptedTypes
        .split(",")
        .map(type => type.split("/")[1])
        .map(type => `.${type}`)
        .join(", ");

      return `Incorrect format. Available formats: ${mappedTypes}`;
    }
  }

  return "";
};

export const fileSizeValidator = (sizeInMb: number) => (value?: File) => {
  const sizeInBytes = sizeInMb * 1024 * 1024;

  if (!value) return value;

  return value.size > sizeInBytes
    ? `Uploaded file exceeds the file size limit: ${sizeInMb} MB`
    : "";
};
