/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { format, parseISO, roundToNearestMinutes, set } from "date-fns";

export const TABLE_DATE_FORMAT = "MM/dd/yyyy";
export const SERVER_DATE_FORMAT = "yyyy-MM-dd";

export const SERVER_DATE_FORMAT_TIMEZONE = "yyyy/MM/dd HH:mm";

export const formatDate = (date?: string, customFormat?: string) => {
  return date ? format(parseISO(date), customFormat ?? TABLE_DATE_FORMAT) : "-";
};

export const formatDateToUTC = (date: Date): string => {
  return new Date(format(date, SERVER_DATE_FORMAT)).toISOString();
};

export const formatDateTimeToUTC = (date: Date): string => {
  return new Date(format(date, SERVER_DATE_FORMAT_TIMEZONE)).toISOString();
};

export const ignoreTimezone = (date: string = "") => {
  return date.slice(0, -1);
};

export const roundTime = (date: Date, nearestTo: number = 15) =>
  nearestTo <= 30
    ? roundToNearestMinutes(date, {
        nearestTo,
      })
    : set(date, { minutes: 0 });
