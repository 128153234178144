/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types, applySnapshot } from "mobx-state-tree";
import { format } from "date-fns";

import api from "common/services/api";

import { SERVER_DATE_FORMAT } from "common/utils/dateUtils";

export const EXPORT_TYPES = {
  TIMELINE: "Timeline",
  MESSAGES: "Messages",
  NOTES: "Notes",
};

export const Statistic = types.model({
  name: types.maybe(types.string),
  count: types.maybe(types.number),
  percent: types.maybe(types.number),
});

export const StudyDocument = types.model({
  id: types.number,
  name: types.string,
});

export const Activity = types.model({
  id: types.number,
  name: types.string,
  type: types.string,
  upcoming: types.number,
  due: types.number,
  scheduled: types.number,
  completed: types.number,
  missed: types.number,
});

export const Milestone = types.model({
  name: types.string,
  activities: types.array(Activity),
});

export const MilestoneSummary = types.model({
  name: types.string,
  milestones: types.array(Milestone),
});

export const MilestonesSummary = types.model({
  items: types.array(MilestoneSummary),
});

export const ParticipantsSummary = types.model({
  prospect: Statistic,
  screened: Statistic,
  eligible: Statistic,
  ineligible: Statistic,
  enrolled: Statistic,
  enrolledInArms: types.array(Statistic),
  stopped: Statistic,
  completed: Statistic,
  active: Statistic,
  total: types.number,
});

export const StaffSummary = types.model({
  total: types.number,
  items: types.array(Statistic),
});

export const StudyDocuments = types.model({
  total: types.number,
  items: types.array(StudyDocument),
});

export const StudyTasks = types.model({
  completed: types.number,
  inProgress: types.number,
  missed: types.number,
  open: types.number,
  total: types.number,
});

export const MilestonesSummaryStore = types
  .model({
    milestonesSummary: types.optional(MilestonesSummary, { items: [] }),
    participantsSummary: types.maybe(ParticipantsSummary),
    staffSummary: types.maybe(StaffSummary),
    studyDocuments: types.maybe(StudyDocuments),
    studyTasks: types.maybe(StudyTasks),
    isLoading: types.optional(types.boolean, false),
    isParticipantsLoading: types.optional(types.boolean, false),
    isExportLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get items() {
      return self.milestonesSummary.items
        .map((arm, armIndex) => {
          return arm.milestones.map((milestone, milestoneIndex) => {
            return milestone.activities.map((activity, activityIndex) => ({
              ...activity,
              milestone: milestone.name,
              arm: arm.name,
              armIndex,
              milestoneIndex,
              activityIndex,
            }));
          });
        })
        .flat(2);
    },
  }))
  .actions(self => ({
    fetch: flow(function*(siteId?: number) {
      self.isLoading = true;
      try {
        const response = yield api.get("/dashboard/study-admin", { siteId });

        applySnapshot(self, response);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchParticipants: flow(function*(
      status: string,
      activityId: number,
      siteId?: number,
    ) {
      self.isParticipantsLoading = true;
      try {
        return yield api.get("/dashboard/study-admin/participants", {
          ActivityId: activityId,
          ParticipantActivityState: status,
          siteId,
        });
      } finally {
        self.isParticipantsLoading = false;
      }
    }),
    export: flow(function*(type: string, params: any) {
      self.isExportLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        if (type === EXPORT_TYPES.TIMELINE) {
          yield api.download("/study/participant-timeline/export", params);
        } else if (type === EXPORT_TYPES.MESSAGES) {
          const { startDate, endDate, ...restParams } = params;
          const start = format(startDate, SERVER_DATE_FORMAT);
          const end = format(endDate, SERVER_DATE_FORMAT);
          yield api.download(
            `/study/chat/export/timeline/${start}/${end}`,
            restParams,
          );
        } else if (type === EXPORT_TYPES.NOTES) {
          yield api.download("/study/participant/note/export", params);
        }

        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isExportLoading = false;
      }

      return { isSuccess, error };
    }),
    reset() {
      applySnapshot(self, {});
    },
  }));

export default MilestonesSummaryStore.create();
