/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI16Loader(props) {
  return (
    <svg width={16} height={16} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeDasharray="25"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="#5682F6" strokeWidth="2">
          <path
            d="M8 13c2.761 0 5-2.239 5-5s-2.239-5-5-5-5 2.239-5 5 2.239 5 5 5z"
            transform="translate(-1255 -167) translate(1243 159) translate(12 8)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 8 8"
              to="360 8 8"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
}

export default SvgI16Loader;
