/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  margin-bottom: ${({ canStretchOnError }) =>
    canStretchOnError ? "0" : "8px"};
`;

export const Wrapper = styled.div`
  min-height: 132px;

  padding: 12px 14px;

  cursor: text;

  border-radius: 7px;
  border: ${({ hasError, focused }) =>
    hasError
      ? "solid 2px #f74a70;"
      : focused
      ? "solid 2px #5682f6"
      : "solid 2px #d8ddeb"};
`;

export const Emails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Placeholder = styled.p`
  position: absolute;
  color: #adadad;
`;

export const Input = styled.div`
  margin: 0 8px 4px 0;
  min-width: 1px;
  word-break: break-all;

  &:focus {
    outline: none;
  }
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};
  bottom: ${({ isStatic }) => (isStatic ? undefined : "-24px")};

  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 4px;
`;

export const Badge = styled.div`
  position: relative;

  padding: 6px 34px 6px 12px;

  background-color: rgba(38, 197, 118, 0.1);
  border-radius: 6px;

  font-family: OpenSans-SemiBold;
  font-size: 14px;

  word-break: break-all;

  margin: 0 8px 4px 0;

  ${({ isValid }) =>
    !isValid &&
    css`
      background-color: rgba(247, 74, 112, 0.1);
    `}
`;

export const Email = styled.div`
  overflow-wrap: anywhere;

  &:focus {
    outline: none;
  }
`;

export const Exit = styled.span`
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%);

  padding: 10px;

  cursor: pointer;

  &:hover path,
  &:hover use {
    fill: ${({ isValid }) => (isValid ? "#26c576" : "#f74a70")};
  }
`;
