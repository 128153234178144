/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export function correctTextFromQueryParams(text: string) {
  return text.replace(/\s/g, "+"); // + - in parameters is not a space encoded, it is just a base64 symbol
}
