/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Container = styled.div`
  * {
    font-family: OpenSans;
  }

  ${({ fitWidth }) =>
    fitWidth &&
    css`
      width: 100%;
    `}

  ${({ fitHeight }) =>
    fitHeight &&
    css`
      height: 100%;
    `}

  .filepicker {
    background-color: #fff;
    border-radius: 7px;
    border: dashed 2px #d8ddec;
  }

  ${({ error }) =>
    error &&
    css`
      .filepicker {
        border-color: #f74a70;
      }
    `}

  .dz-message {
    font-size: 14px;
    color: #919bb8;
  }

  .dropzone .dz-preview .dz-details .dz-filename:hover {
    display: flex;
    justify-content: center;
    white-space: normal;
  }

  .dropzone .dz-preview.dz-error .dz-error-mark {
    display: none;
  }

  .dropzone .dz-preview.dz-error .dz-error-message {
    opacity: 1;
    margin-top: 20px;
  }

  .dropzone .dz-preview .dz-image {
    border: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ hideProgressBar }) =>
    hideProgressBar &&
    css`
      .dz-progress {
        display: none;
      }
    `}
`;

export const Error = styled.p`
  position: absolute;

  font-size: 13px;

  color: #f74a70;

  margin-top: 4px;
`;
