/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";
import DefaultSelect from "react-select";
import DefaultCreatableSelect from "react-select/creatable";

import styledWrapper from "common/components/StyledWrapper/StyledWrapper";

import IconButton from "common/components/Button/IconButton";
import Fab from "common/components/Button/Fab";

import { COLORS } from "common/constants/layout";

const styles = {
  control: (provided, props) => ({
    ...provided,
    height: "100%",
    borderWidth: "2px",
    borderRadius: "7px",
    borderColor: props.isFocused
      ? "#5682f6"
      : props.selectProps.error
      ? "#f74a70"
      : "#d8ddeb",
    backgroundColor: props.selectProps.isDisabled ? "#f0f2f7" : "#fff",
    boxShadow: 0,
    minHeight: props.selectProps.size.minHeight,
  }),
  placeholder: provided => ({
    ...provided,
    color: "rgba(51,51,51,0.4)",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided, props) => ({
    ...provided,
    padding: props.selectProps.size.valueContainerPadding,
    height: "inherit",
  }),
  dropdownIndicator: (provided, props) => ({
    ...provided,
    padding: props.selectProps.size.dropdownIndicatorPadding,
  }),
  input: provided => ({
    ...provided,
    fontFamily: "OpenSans-Regular",
    fontSize: "16px",
  }),
  menu: provided => ({
    ...provided,
    marginTop: 1,
    borderRadius: "0 0 7px 7px",
    border: "none",
    boxShadow: "0 8px 24px -8px rgba(0, 26, 216, 0.42)",
  }),
  menuList: provided => ({
    ...provided,
    paddingTop: 10,
  }),
  option: (provided, props) => {
    const { isFocused, isSelected, isDisabled } = props;
    const backgroundColor =
      (isFocused && isSelected) || isSelected
        ? "#5682f6"
        : isFocused
        ? "rgba(164, 170, 246, 0.08)"
        : "white";
    const color =
      (isFocused && isSelected) || isSelected
        ? "#fff"
        : isFocused
        ? "#5682f6"
        : isDisabled
        ? COLORS.TEXT_LIGHT
        : "#37383d";
    return {
      ...provided,
      padding: "8px 16px",
      backgroundColor,
      color,
    };
  },
};

const selectStyles = css`
  width: 100%;
  height: ${({ size }) => size.minHeight};

  ${({ size }) =>
    size.maxWidth &&
    css`
      max-width: ${size.maxWidth};
    `};

  & input::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }
`;

export const Select = styled(DefaultSelect).attrs({ styles })`
  ${selectStyles};
`;

export const CreatableSelect = styled(DefaultCreatableSelect).attrs({ styles })`
  ${selectStyles};
`;

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  font-size: 14px;
  color: #919bb8;
  margin-left: 8px;
  white-space: nowrap;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  width: 100%;
`;

export const RemoveOptionButton = styledWrapper(
  styled(Fab)(css`
    min-height: 22px;
    min-width: 22px;

    ${({ optionIsSelected }) =>
      optionIsSelected &&
      css`
        &:not(:disabled):hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      `}
  `),
)(css`
  display: flex;

  padding: 0;

  border: none;
  background: transparent;
  cursor: pointer;
`);

export const MultiValuePlaceholder = styled.div`
  color: rgba(51, 51, 51, 0.4);
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;

  &:not(:first-child) {
    display: none;
  }
`;

export const MultiValueContainer = styled.div`
  display: flex;
  position: relative;
`;

export const MultiValues = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ withClearButton }) =>
    withClearButton &&
    css`
      padding-right: 80px;
    `}
`;

export const MultiValue = styled.div`
  border-radius: 6px;
  padding: 6px 8px 7px 12px;
  background-color: rgba(86, 130, 246, 0.1);

  display: flex;
  align-items: center;

  margin-right: 12px;
  margin-top: 12px;

  ${({ hideClearIcon }) =>
    hideClearIcon &&
    css`
      padding: 6px 12px 7px 12px;

      ${MultiValueClear} {
        display: none;
      }
    `}
`;

export const MultiValueLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #37383d;
`;

export const MultiValueClear = styled.span`
  display: flex;
  margin-left: 8px;

  cursor: pointer;
`;

export const ClearButtonContainer = styled.div`
  display: flex;

  position: absolute;
  right: 0;
`;

export const ClearButton = styled(IconButton)`
  margin-top: 12px;
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 4px;
`;
