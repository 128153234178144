/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { Quill } from "react-quill";
import { DeltaStatic, DeltaOperation } from "quill";

import Tooltip from "../Tooltip";

import { TooltipWrapper } from "./styles";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extraSmall", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comicSans",
  "courierNew",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

// Preserve size format
const preserveSizeFormat = (node: any, delta: DeltaStatic) => {
  const match = node.className.match(/ql-size-(.*)/);
  const fontSize = node.style["font-size"];
  // When className is not present, check style attribute
  const styleMatch = fontSize && fontSize !== "16px";
  if (match || styleMatch) {
    delta.map(function(op: DeltaOperation) {
      if (!op.attributes) op.attributes = {};
      // Grab the size from `ql-size-{x}`
      if (match) {
        op.attributes.size = match[1];
      } else if (styleMatch) {
        const large = fontSize === "13px" || fontSize === "0.8125rem";
        op.attributes.size = large ? "large" : "huge";
      }
      return op;
    });
  }
  return delta;
};

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  clipboard: {
    matchVisual: false,
    matchers: [
      ["span", preserveSizeFormat],
      ["a", preserveSizeFormat],
    ],
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
];

type WrapperProps = {
  children: React.ReactElement;
  enabled?: boolean;
  title: string;
};

type ItemProps = { enabled?: boolean };

const ToolbarWrapper = ({ children, enabled, title }: WrapperProps) =>
  enabled ? <Tooltip content={title}>{children}</Tooltip> : null;

const FontFamily = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Font family"} enabled={enabled}>
    <div>
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comicSans">Comic Sans</option>
        <option value="courierNew">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
    </div>
  </ToolbarWrapper>
);

const FontSize = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Font size"} enabled={enabled}>
    <div>
      <select className="ql-size" defaultValue="medium">
        <option value="extraSmall">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select>
    </div>
  </ToolbarWrapper>
);

const Styles = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Styles"} enabled={enabled}>
    <div>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </div>
  </ToolbarWrapper>
);

const Bold = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Bold"} enabled={enabled}>
    <button className="ql-bold" />
  </ToolbarWrapper>
);

const Italic = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Italic"} enabled={enabled}>
    <button className="ql-italic" />
  </ToolbarWrapper>
);

const Underline = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Underline"} enabled={enabled}>
    <button className="ql-underline" />
  </ToolbarWrapper>
);

const StrikeThrough = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Strikethrough"} enabled={enabled}>
    <button className="ql-strike" />
  </ToolbarWrapper>
);

const NumberedList = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Numbered list"} enabled={enabled}>
    <button className="ql-list" value="ordered" />
  </ToolbarWrapper>
);

const BulletList = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Bullet list"} enabled={enabled}>
    <button className="ql-list" value="bullet" />
  </ToolbarWrapper>
);

const DecreaseIndent = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Decrease indent"} enabled={enabled}>
    <button className="ql-indent" value="-1" />
  </ToolbarWrapper>
);

const IncreaseIndent = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Increase indent"} enabled={enabled}>
    <button className="ql-indent" value="+1" />
  </ToolbarWrapper>
);

const Super = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Super"} enabled={enabled}>
    <button className="ql-script" value="super" />
  </ToolbarWrapper>
);

const Sub = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Sub"} enabled={enabled}>
    <button className="ql-script" value="sub" />
  </ToolbarWrapper>
);

const Blockquote = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Blockquote"} enabled={enabled}>
    <button className="ql-blockquote" />
  </ToolbarWrapper>
);

const Align = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Align"} enabled={enabled}>
    <div>
      <select className="ql-align" />
    </div>
  </ToolbarWrapper>
);

const TextColor = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Text color"} enabled={enabled}>
    <div>
      <select className="ql-color" />
    </div>
  </ToolbarWrapper>
);

const HighlightColor = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Highlight color"} enabled={enabled}>
    <div>
      <select className="ql-background" />
    </div>
  </ToolbarWrapper>
);

const Link = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Link (text selection is required)"} enabled={enabled}>
    <button className="ql-link" />
  </ToolbarWrapper>
);

const Image = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Image"} enabled={enabled}>
    <button className="ql-image" />
  </ToolbarWrapper>
);

const Video = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Video"} enabled={enabled}>
    <button className="ql-video" />
  </ToolbarWrapper>
);

const CodeBlock = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Code Block"} enabled={enabled}>
    <button className="ql-code-block" />
  </ToolbarWrapper>
);

const Clean = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Clean"} enabled={enabled}>
    <button className="ql-clean" />
  </ToolbarWrapper>
);

const Undo = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Undo"} enabled={enabled}>
    <button className="ql-undo">
      <CustomUndo />
    </button>
  </ToolbarWrapper>
);

const Redo = ({ enabled }: ItemProps) => (
  <ToolbarWrapper title={"Redo"} enabled={enabled}>
    <button className="ql-redo">
      <CustomRedo />
    </button>
  </ToolbarWrapper>
);

const FontBlock = () => (
  <span className="ql-formats">
    <TooltipWrapper>
      <FontFamily enabled />
      <FontSize enabled />
      <Styles enabled />
    </TooltipWrapper>
  </span>
);

const FontStyleBlock = ({
  enableStrikeThrough = true,
}: {
  enableStrikeThrough?: boolean;
}) => (
  <span className="ql-formats">
    <TooltipWrapper>
      <Bold enabled />
      <Italic enabled />
      <Underline enabled />
      <StrikeThrough enabled={enableStrikeThrough} />
    </TooltipWrapper>
  </span>
);

const ListBlock = () => (
  <span className="ql-formats">
    <TooltipWrapper>
      <NumberedList enabled />
      <BulletList enabled />
      <DecreaseIndent enabled />
      <IncreaseIndent enabled />
    </TooltipWrapper>
  </span>
);

const TextExtendedBlock = () => (
  <span className="ql-formats">
    <TooltipWrapper>
      <Super enabled />
      <Sub enabled />
      <Blockquote enabled />
    </TooltipWrapper>
  </span>
);

const TextStyleBlock = () => (
  <span className="ql-formats">
    <TooltipWrapper>
      <Align enabled />
      <TextColor enabled />
      <HighlightColor enabled />
    </TooltipWrapper>
  </span>
);

const LinkBlock = ({ enableVideo }: { enableVideo?: boolean }) => (
  <span className="ql-formats">
    <TooltipWrapper>
      <Link enabled />
      <Image enabled />
      <Video enabled={enableVideo} />
    </TooltipWrapper>
  </span>
);

const CleanBlock = () => (
  <span className="ql-formats">
    <TooltipWrapper>
      <CodeBlock enabled />
      <Clean enabled />
    </TooltipWrapper>
  </span>
);

const HistoryBlock = () => (
  <span className="ql-formats">
    <TooltipWrapper>
      <Undo enabled />
      <Redo enabled />
    </TooltipWrapper>
  </span>
);

type Props = {
  config?: string;
  toolbarName?: string;
};

export const EditorToolbar = ({ config, toolbarName }: Props) => {
  const name = toolbarName ? `toolbar-${toolbarName}` : `toolbar`;

  switch (config) {
    case "short":
      return (
        <div id={name}>
          <FontStyleBlock enableStrikeThrough={false} />
        </div>
      );
    case "message":
      return (
        <div id={name}>
          <Bold enabled />
          <Italic enabled />
          <Underline enabled />
          <Link enabled />
        </div>
      );
    case "video":
      return (
        <div id={name}>
          <FontBlock />
          <FontStyleBlock />
          <ListBlock />
          <TextExtendedBlock />
          <TextStyleBlock />
          <LinkBlock enableVideo />
          <CleanBlock />
          <HistoryBlock />
        </div>
      );
    default:
      return (
        <div id={name}>
          <FontBlock />
          <FontStyleBlock />
          <ListBlock />
          <TextExtendedBlock />
          <TextStyleBlock />
          <LinkBlock />
          <CleanBlock />
          <HistoryBlock />
        </div>
      );
  }
};

export default EditorToolbar;
