/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Logo = styled.div`
  background-image: ${({ src }) => src};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;
