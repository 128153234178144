/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Container, Title, Step } from "./styles";

type Props = {
  title: string;
  page: number;
};

function StepTitle({ title, page }: Props) {
  return (
    <Container>
      <Step>Step {page + 1}</Step>
      <Title>{title}</Title>
    </Container>
  );
}

export default StepTitle;
