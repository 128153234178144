/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`;

export const Label = styled.p`
  font-family: OpenSans;
  font-size: 14px;
  color: ${({ accent }) => (accent ? "#37383d" : "#919bb8")};

  margin-left: 10px;

  user-select: none;

  ${({ large }) =>
    large &&
    css`
      font-size: 16px;
      margin-left: 25px;
    `}
`;

export const Border = styled.div`
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;

  border-radius: 4px;
  border: solid 1px ${COLORS.TEXT_LIGHT};
  background-color: ${({ disabled, isChecked }) =>
    disabled && isChecked ? "#a9b4c9" : isChecked ? "#5682f6" : "transparent"};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled, isChecked }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${isChecked ? "#4a53df" : "#dce3f5"};
      }
    `}
`;
