/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types, flow } from "mobx-state-tree";
import api from "common/services/api";

import { CustomerAdminModel } from "models/CustomerAdminModel";
import createTableStore from "stores/templates/TableStore";

const CustomerAdminsStore = createTableStore({
  fetch: function({ params }) {
    return api.get(`/customer/customer-admin/list`, params);
  },
  itemModel: CustomerAdminModel,
})
  .props({
    isInviteLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    inviteUser: flow(function*(values: any) {
      let isSuccess = false;
      let error = "";
      self.isInviteLoading = true;

      try {
        yield api.post(`/customer/customer-admin/invite`, {
          ...values,
        });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isInviteLoading = false;
      }

      return { isSuccess, error };
    }),
  }));

export default CustomerAdminsStore.create();
