/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  applySnapshot,
  flow,
  Instance,
  onSnapshot,
  types,
} from "mobx-state-tree";

import api, { assignAuth } from "common/services/api";

import { AuthModel } from "models/Auth";

const Phone = types.model("Phone", {
  id: types.string,
  maskedPhoneNumber: types.string,
});

const Possibilities = types.model("Possibilities", {
  email: types.maybe(types.string),
  phones: types.array(Phone),
  sendTypes: types.array(types.string),
});

export type PossibilitiesType = Instance<typeof Possibilities>;

const ChangeEmailStore = types
  .model({
    possibilities: types.maybe(Possibilities),
    tokenData: types.maybe(AuthModel),
    isLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    findPhoneNumberById(id: string) {
      return self.possibilities?.phones.find(phone => phone.id === id)
        ?.maskedPhoneNumber;
    },
  }))
  .actions(self => ({
    validateEmail: flow(function*(values: any) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.post("/change-email/participant/email/validate", {
          ...values,
        });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
    getPossibilities: flow(function*(values: any) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        const possibilities = yield api.get("/change-email/possibilities", {
          ...values,
        });
        applySnapshot(self, { ...self, possibilities });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
    sendPinCode: flow(function*(values: any) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.post("/change-email/pin/send", { ...values });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
    resendPinCode: flow(function*(values: any) {
      let error = "";

      try {
        yield api.post("/change-email/pin/send", { ...values });
      } catch (e) {
        console.error("error", error);
        error = e.message;
      }

      return { error };
    }),
    confirmPinCode: flow(function*(values: any) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        const result = yield api.post("/change-email/pin/confirm", {
          ...values,
        });
        if (result?.accessToken) {
          self.tokenData = result;
        }
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
    changeEmail: flow(function*(values: any) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.post("/change-email", { ...values });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
    confirmNewEmail: flow(function*(
      userId: number,
      email: string,
      code: string,
    ) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.post("/account/change-email-confirm", {
          userId,
          email,
          code,
        });
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
    setTokenData: (tokenData?: Instance<typeof AuthModel>) => {
      self.tokenData = tokenData;
    },
    reset() {
      window.sessionStorage.removeItem("tokenData");
      applySnapshot(self, {});
    },
    afterCreate() {
      onSnapshot(self, ({ tokenData }) => {
        assignAuth(tokenData);
        if (tokenData) {
          window.sessionStorage.setItem("tokenData", JSON.stringify(tokenData));
        }
      });
    },
  }));

const changeEmail = ChangeEmailStore.create();

export default changeEmail;
