/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import AuthDashboardStore from "stores/AuthDashboard";

import Icon from "common/components/Icon";
import { LogoMin } from "common/components/Logo";
import Avatar from "common/components/Avatar";

import SelectorItem from "./SelectorItem";

import SvgI24Dashboard from "common/assets/icons/I24Dashboard";
import SvgI24Users from "common/assets/icons/I24Users";
import SvgI24Flask from "common/assets/icons/I24Flask";

import { COLORS } from "common/constants/layout";

import { DictionaryItem } from "common/types/models";

import {
  Columns,
  Column,
  ColumnHeader,
  IconContainer,
  HeaderTitle,
  SmartLogo,
} from "./styles";

export type SelectPayload = {
  roleId: number;
  customerId?: number;
  studyId?: number;
  siteId?: number;
};

type Props = {
  onSelect: (data: SelectPayload) => void;
};

@observer
class Selector extends React.Component<Props> {
  @observable selectedCardId: string = "";

  @computed
  get systemRoles(): DictionaryItem[] | [] {
    const { roles } = AuthDashboardStore.data?.system || {};

    return roles?.map(role => role.asDictionaryItem) || [];
  }

  @computed
  get spaces(): Array<{
    selectIndex: number;
    customerId: number;
    title: string;
    role: DictionaryItem;
  }> {
    return (
      AuthDashboardStore.data?.spaces?.map(({ id, title, role }, index) => ({
        selectIndex: index,
        customerId: id,
        title,
        role: role.asDictionaryItem,
      })) || []
    );
  }

  @computed
  get studies(): Array<{
    selectIndex: number;
    customer: DictionaryItem;
    study: DictionaryItem;
    roles: Array<DictionaryItem>;
    sites: Array<DictionaryItem | undefined>;
    studyId: number;
  }> {
    return (
      AuthDashboardStore.data?.studies?.map(
        ({ customer, study, roles }, index) => ({
          selectIndex: index,
          customer: customer.asDictionaryItem,
          studyId: study.id,
          study: {
            value: study.asDictionaryItem.value,
            label: study.name || `Untitled study #${study.id}`,
          },
          roles: roles.map(({ role, site }) => ({
            value: role.asDictionaryItem.value,
            label: role.name + (site ? ` (${site.name})` : ""),
          })),
          sites: roles.map(
            ({ site }) =>
              site && {
                value: site.asDictionaryItem.value,
                label: site.name || "",
              },
          ),
        }),
      ) || []
    );
  }

  onSelect = (option: DictionaryItem) => {
    this.selectedCardId = option.value;
    this.props.onSelect({ roleId: Number.parseInt(option.value) });
  };

  onSelectCustomerRole = ({
    selectIndex,
    customerId,
    role,
  }: {
    selectIndex: string;
    customerId: number;
    role: DictionaryItem;
  }) => {
    this.selectedCardId = selectIndex;
    this.props.onSelect({
      roleId: Number.parseInt(role.value),
      customerId,
    });
  };

  onSelectStudyRole = ({
    selectIndex,
    study,
    customer,
    site,
    role,
  }: {
    selectIndex: string;
    study: DictionaryItem;
    customer?: DictionaryItem;
    site?: DictionaryItem;
    role: DictionaryItem;
  }) => {
    this.selectedCardId = selectIndex;
    const data = {
      roleId: Number.parseInt(role.value),
      studyId: Number.parseInt(study.value),
      ...{ customerId: customer && Number.parseInt(customer.value) },
      ...{ siteId: site && Number.parseInt(site.value) },
    };
    this.props.onSelect(data);
  };

  render() {
    const systemRoles = this.systemRoles;
    const spaces = this.spaces;
    const studies = this.studies;
    return (
      <Columns>
        {!!systemRoles.length && (
          <Column>
            <Header
              title="System"
              icon={<SvgI24Dashboard />}
              color={COLORS.CORNFLOWER}
              background="rgba(86,130,246,0.121)"
            />
            <SelectorItem
              onSelect={this.onSelect}
              title="SMART"
              roleOptions={this.systemRoles}
              icon={
                <SmartLogo>
                  <LogoMin />
                </SmartLogo>
              }
              isSelected={
                !!systemRoles.find(role => role.value === this.selectedCardId)
              }
            />
          </Column>
        )}
        {!!spaces.length && (
          <Column>
            <Header
              title="Space"
              icon={<SvgI24Users />}
              color={COLORS.YELLOW}
              background="rgba(255,187,51,0.2)"
            />
            {spaces.map(({ selectIndex, customerId, title, role }) => (
              <CustomerCard
                key={selectIndex}
                customerId={customerId}
                title={title}
                selectIndex={selectIndex}
                roleOptions={[role]}
                onSelect={this.onSelectCustomerRole}
                isSelected={this.selectedCardId === `space${selectIndex}`}
              />
            ))}
          </Column>
        )}
        {!!studies.length && (
          <Column>
            <Header
              title="Study"
              icon={<SvgI24Flask />}
              color={COLORS.GREEN}
              background="rgba(38,197,118,0.121)"
            />
            {studies.map(
              ({ selectIndex, customer, study, roles, sites, studyId }) => (
                <StudyCard
                  key={selectIndex}
                  customer={customer}
                  study={study}
                  roles={roles}
                  studyId={studyId}
                  sites={sites}
                  selectIndex={selectIndex}
                  onSelect={this.onSelectStudyRole}
                  isSelected={this.selectedCardId === `study${selectIndex}`}
                />
              ),
            )}
          </Column>
        )}
      </Columns>
    );
  }
}

export default Selector;

type HeaderProps = {
  background: string;
  color: string;
  icon: React.ReactNode;
  title: string;
};

function Header({ background, color, icon, title }: HeaderProps) {
  return (
    <ColumnHeader>
      <IconContainer backgroundColor={background}>
        <Icon icon={icon} color={color} />
      </IconContainer>
      <HeaderTitle>{title}</HeaderTitle>
    </ColumnHeader>
  );
}

type CustomerCardProps = {
  customerId: number;
  title: string;
  roleOptions: Array<DictionaryItem>;
  selectIndex: number;
  onSelect: (data: {
    customerId: number;
    role: DictionaryItem;
    selectIndex: string;
  }) => void;
  isSelected: boolean;
};

@observer
class CustomerCard extends React.Component<CustomerCardProps> {
  @computed
  get profilePhoto() {
    const { customerId } = this.props;

    const customerImage = AuthDashboardStore.customersImages.find(
      image => image.customerId === customerId,
    );

    return customerImage?.avatar;
  }

  onSelect = (option: DictionaryItem) => {
    const { selectIndex, customerId, onSelect } = this.props;
    onSelect({
      customerId,
      role: option,
      selectIndex: `space${selectIndex}`,
    });
  };

  render() {
    const { title, roleOptions, isSelected } = this.props;
    return (
      <SelectorItem
        onSelect={this.onSelect}
        title={title}
        roleOptions={roleOptions}
        icon={
          <Avatar
            name={title}
            profilePhoto={this.profilePhoto}
            size={72}
            fontSize={24}
          />
        }
        isSelected={isSelected}
      />
    );
  }
}

type StudyCardProps = {
  selectIndex: number;
  studyId: number;
  study: DictionaryItem;
  customer: DictionaryItem;
  sites: Array<DictionaryItem>;
  roles: Array<DictionaryItem>;
  onSelect: (data: {
    selectIndex: string;
    study: DictionaryItem;
    customer?: DictionaryItem;
    site?: DictionaryItem;
    role: DictionaryItem;
  }) => void;
  isSelected: boolean;
};

@observer
class StudyCard extends React.Component<StudyCardProps> {
  @computed
  get profilePhoto() {
    const { studyId } = this.props;

    const customerImage = AuthDashboardStore.studiesImages.find(
      image => image.studyId === studyId,
    );

    return customerImage?.avatar;
  }

  onSelect = (option: DictionaryItem, index: number) => {
    const { study, customer, sites, onSelect, selectIndex } = this.props;
    onSelect({
      study,
      customer,
      role: option,
      site: sites[index],
      selectIndex: `study${selectIndex}`,
    });
  };

  render() {
    const { study, roles, isSelected } = this.props;
    return (
      <SelectorItem
        key={study.value}
        onSelect={this.onSelect}
        title={study.label}
        roleOptions={roles}
        icon={
          <Avatar
            name={study.label}
            profilePhoto={this.profilePhoto}
            size={72}
            fontSize={24}
          />
        }
        isSelected={isSelected}
      />
    );
  }
}
