/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

import { Employee } from "models/common";

const DocumentModel = types
  .model({
    id: types.number,
    name: types.maybe(types.string),
    typeName: types.maybe(types.string),
    description: types.maybe(types.string),
    created: types.maybe(types.string),
    updated: types.maybe(types.string),
    reporter: Employee,
    isEditable: types.boolean,
    isDeletable: types.boolean,
    availableToSites: types.maybe(
      types.array(types.model({ id: types.number, name: types.string })),
    ),
  })
  .views(self => ({
    get date() {
      return self.updated ?? self.created;
    },
    get uploadedBy() {
      return self.reporter.name;
    },
    get inactiveEmployee() {
      return self.reporter.isInactive;
    },
  }));

export default DocumentModel;
