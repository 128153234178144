/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 19px;
`;

export const Title = styled.div`
  font-family: OpenSans-Bold;
  font-size: 30px;
  line-height: 41px;

  color: ${COLORS.GRAY};
`;

export const Subtitle = styled.div`
  max-width: 100%;

  margin-top: 16px;

  color: ${({ lightSubtitle }) =>
    lightSubtitle ? `${COLORS.TEXT_LIGHT}` : `${COLORS.GRAY}`};

  white-space: wrap;
`;
