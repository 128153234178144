/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI120PlaceholderStudy(props) {
  return (
    <svg width={120} height={120} {...props}>
      <g fill="#A9B4C9" fillRule="evenodd">
        <path
          d="M13.028 72.096a.764.764 0 01.357.94l-.055.116-1.028 1.782 1.738 1.002c.35.202.495.624.358.993l-.045.099-.012.022a.764.764 0 01-.95.326l-.094-.046-1.783-1.028-1.001 1.738a.816.816 0 01-1.091.313.764.764 0 01-.358-.94l.055-.116 1.027-1.783-1.737-1.001a.817.817 0 01-.358-.993l.045-.098.012-.023a.764.764 0 01.95-.326l.094.046 1.782 1.027 1.002-1.737a.816.816 0 011.092-.313zm98-30a.764.764 0 01.357.94l-.055.116-1.028 1.782 1.738 1.002c.35.202.495.624.358.993l-.045.099-.012.022a.764.764 0 01-.95.326l-.094-.046-1.783-1.028-1.001 1.738a.816.816 0 01-1.091.313.764.764 0 01-.358-.94l.055-.116 1.027-1.783-1.737-1.001a.817.817 0 01-.358-.993l.045-.098.012-.023a.764.764 0 01.95-.326l.094.046 1.782 1.027 1.002-1.737a.816.816 0 011.092-.313zM80.704 20.188a1.5 1.5 0 01.585 2.04l-1.939 3.498 3.498 1.94a1.5 1.5 0 01.65 1.909l-.065.13a1.5 1.5 0 01-1.91.648l-.13-.064-3.498-1.939-1.939 3.498a1.5 1.5 0 01-2.624-1.454l1.939-3.499-3.498-1.939a1.5 1.5 0 01-.649-1.91l.064-.129a1.5 1.5 0 011.91-.649l.13.064 3.498 1.939 1.94-3.498a1.5 1.5 0 012.038-.585z"
          opacity={0.656}
        />
        <path
          d="M29.968 71.604c1.055.412 2.173.83 3.355 1.252l1.448.51-.001 9.238-7.27.001a5.5 5.5 0 010-11h2.468zm83.532.001a5.5 5.5 0 010 11H112V77a3 3 0 00-2.824-2.995L109 74h-1a3 3 0 00-2.995 2.824L105 77v5.604l-14.28.001c-.083-3.872-.55-8.246-.766-11H113.5zM10 46h42.185l-2.098.703c-13.632 4.63-33.361 12.506-33.361 15.72 0 .19.022.382.067.577H10a8 8 0 01-8-8v-1a8 8 0 018-8zm69 0a8 8 0 017.177 4.462 375.38 375.38 0 00-13.072-4.463L79 46z"
          opacity={0.295}
        />
        <path
          d="M44 94.605c-5.952 0-3.768-1.071-9.32-4.12-.879-.483-1.38-2.034-1.38-3.457V72.657c0-2.212-.967-2.05.573-2.05l8.622 3.441c-.497 2.942-.745 6.581-.745 10.918 0 4.338.75 7.55 2.25 9.64z"
          opacity={0.381}
        />
        <path d="M60.186 40.432a6.418 6.418 0 014.087-.182l.286.09 41.216 13.985c3.278 1.112 5.014 4.612 3.876 7.818a6.085 6.085 0 01-.65 1.308L109 71.612c2.934.308 5.236 2.693 5.352 5.629l.005.233v10.105h-12V77.474c0-2.434 1.5-4.523 3.642-5.423v-6.198l-13 5.198L93 88.809c0 1.853-1.013 3.573-2.676 4.573l-.221.126C80.936 98.495 71.729 101 62.5 101c-9.229 0-18.436-2.505-27.603-7.492-1.72-.936-2.804-2.617-2.891-4.458L32 88.809V73.052l-13.807-4.767c-3.272-1.13-4.988-4.64-3.833-7.84a6.2 6.2 0 013.36-3.563l.251-.103 42.215-16.347zm29.658 31.881l-25.578 10.23a6.417 6.417 0 01-4.47.106l-24.641-8.508v14.603c0 .826.476 1.59 1.25 2.007 8.733 4.702 17.425 7.042 26.095 7.042 8.67 0 17.362-2.34 26.095-7.042.722-.39 1.185-1.08 1.244-1.843l.006-.164-.001-16.431zm18.513 2.466c-1.449 0-2.633 1.084-2.722 2.453l-.006.165v6.982h5.455v-6.982c0-1.338-1.047-2.442-2.397-2.599l-.17-.015-.16-.004zm-45.171-30.57a2.14 2.14 0 00-1.28-.029l-.178.06-42.214 16.347a2.07 2.07 0 00-1.204 1.222c-.368 1.018.137 2.13 1.132 2.557l.146.056L61.19 78.787a2.14 2.14 0 001.31.028l.18-.063 41.828-16.728c.5-.2.9-.581 1.12-1.06l.066-.164.042-.134a2.036 2.036 0 00-1.18-2.413l-.154-.059L63.186 44.21z" />
      </g>
    </svg>
  );
}

export default SvgI120PlaceholderStudy;
