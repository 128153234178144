/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types, flow, applySnapshot, getSnapshot } from "mobx-state-tree";

import api from "common/services/api";

import { SYSTEM_ID, STUDY_ID } from "common/constants/labResults";

export const NotificationGroup = types.model("NotificationGroup", {
  siteId: types.number,
  customerUserIds: types.array(types.number),
});

export const NotificationRule = types.model("NotificationRule", {
  selectedValue: types.string,
  notificationGroups: types.array(NotificationGroup),
});

export const LabResultField = types.model("LabResultField", {
  id: types.maybe(types.string),
  order: types.maybe(types.number),
  name: types.maybe(types.string),
  fieldType: types.maybe(types.string),
  availableValues: types.maybe(types.array(types.string)),
  isMetaField: types.maybe(types.boolean),
  notificationRules: types.optional(types.array(NotificationRule), []),
  note: types.maybe(types.string),
});

const LabResultsStore = types
  .model({
    fields: types.array(LabResultField),
    isLoading: types.optional(types.boolean, false),
    isExporting: types.optional(types.boolean, false),
  })
  .views(self => ({
    get mappedItems() {
      return self.fields.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
    },
    get isSystemIdSelected() {
      return self.fields.some(({ name }) => name === SYSTEM_ID);
    },
    get isStudyIdSelected() {
      return self.fields.some(({ name }) => name === STUDY_ID);
    },
    get isExportAvailable() {
      return self.fields.length > 2;
    },
  }))
  .actions(self => ({
    fetch: flow(function*({ studyId }) {
      self.isLoading = true;
      try {
        const { items } = yield api.get(
          `/study/${studyId}/lab-result-template/field/list`,
        );

        applySnapshot(self, { ...self, fields: items });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    create: flow(function*({ studyId, field }) {
      self.isLoading = true;
      try {
        yield api.post(`/study/${studyId}/lab-result-template/field`, {
          ...field,
        });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    update: flow(function*({ studyId, field }) {
      self.isLoading = true;
      try {
        yield api.put(
          `/study/${studyId}/lab-result-template/field/${field.id}`,
          {
            ...field,
          },
        );
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    delete: flow(function*({ studyId, field }) {
      self.isLoading = true;
      try {
        yield api.delete(
          `/study/${studyId}/lab-result-template/field/${field.id}`,
        );
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    updateOrder: flow(function*({ studyId, ids }) {
      self.isLoading = true;
      try {
        yield api.put(
          `/study/${studyId}/lab-result-template/field/list/order`,
          { ids },
        );
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    export: flow(function*({ studyId }) {
      self.isExporting = true;
      try {
        yield api.download(`/study/${studyId}/lab-result-template/export`);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isExporting = false;
      }
    }),
    reorder(startIndex: number, endIndex: number) {
      const { fields } = getSnapshot(self);

      const result = fields.map(({ id }) => id);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    reset() {
      applySnapshot(self, {});
    },
  }));

export default LabResultsStore.create();
