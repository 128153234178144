/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const BREAKPOINTS = { DESKTOP_MEDIUM: 1600, DESKTOP: 1366, MOBILE: 480 };

export const MEDIA_QUERIES = {
  DESKTOP: `@media screen and (min-width: ${BREAKPOINTS.DESKTOP_MEDIUM + 1}px)`,
  MEDIUM_DESKTOP: `@media screen and (max-width: ${BREAKPOINTS.DESKTOP_MEDIUM}px)`,
  SMALL_DESKTOP: `@media screen and (max-width: ${BREAKPOINTS.DESKTOP}px)`,
  MOBILE: `@media screen and (max-width: ${BREAKPOINTS.MOBILE}px)`,
};

export const LEFT_PANEL = {
  WIDTH: {
    NORMAL: `420px`,
    SMALL: `308px`,
  },
};

export const SmallDesktopContainer = styled.div`
  display: none;
  ${MEDIA_QUERIES.SMALL_DESKTOP} {
    display: block;
  }
`;

export const DesktopContainer = styled.div`
  display: none;
  ${MEDIA_QUERIES.DESKTOP} {
    display: block;
  }
`;

export const MobileContainer = styled.div`
  display: none;
  ${MEDIA_QUERIES.MOBILE} {
    display: block;
  }
`;
