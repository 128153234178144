/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import {
  ValueType,
  ValueContainerProps,
  components,
  OptionProps,
} from "react-select";
import { FieldInputProps } from "react-final-form";

import {
  Select,
  Container,
  Label,
  Checkbox,
  SelectAllOption,
  Error,
} from "./styles";

import { DictionaryItem } from "common/types/models";

type DropdownDataProvider = {
  options: Array<DictionaryItem>;
  isLoading: boolean;
};

export type CheckboxesDropdownExternalProps = {
  options?: Array<DictionaryItem>;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  provider?: DropdownDataProvider;
  value: DictionaryItem;
  selectAll?: string;
};

type Props = {
  onChange: (item: ValueType<DictionaryItem>[]) => void;
  error?: string;
  input: FieldInputProps<string>;
} & CheckboxesDropdownExternalProps;

const Value = () => null;

const ValueContainer = (props: ValueContainerProps<DictionaryItem>) => {
  const value = props.getValue();
  const optionsLabel = value?.map(item => item.label).join(", ");

  return (
    <components.ValueContainer {...props}>
      <Label>{optionsLabel}</Label>
      {props.children}
    </components.ValueContainer>
  );
};

const Option = (selectAll?: string) => (props: OptionProps<DictionaryItem>) => {
  return (
    <components.Option {...props}>
      {props.label === selectAll ? (
        <SelectAllOption>{props.label}</SelectAllOption>
      ) : (
        <Checkbox
          label={props.label}
          isChecked={props.isSelected}
          onClick={() => null}
        />
      )}
    </components.Option>
  );
};

@observer
export default class CheckboxesDropdown extends React.Component<Props> {
  get values() {
    const { provider, options, input } = this.props;
    const currentOptions = provider?.options || options;
    const value = input?.value || [];
    return value.map(item =>
      currentOptions?.find(findItem => findItem.value === item),
    );
  }

  get options() {
    const { options, provider, selectAll } = this.props;
    const currentOptions = provider?.options || options;
    const selectAllItem = {
      label: selectAll,
      value: selectAll,
    };
    return selectAll ? [selectAllItem, ...currentOptions] : currentOptions;
  }

  onChange = (value: ValueType<DictionaryItem>[]) => {
    const { options, provider, selectAll } = this.props;
    const currentOptions = provider?.options || options;
    const hasSelectAll = value.some(item => item?.value === selectAll);
    this.props.onChange(hasSelectAll ? currentOptions : value);
  };

  render() {
    const {
      disabled,
      loading,
      placeholder,
      error,
      input,
      provider,
      selectAll,
    } = this.props;

    return (
      <Container>
        <Select
          {...input}
          value={this.values}
          options={this.options}
          onChange={this.onChange}
          isDisabled={disabled}
          isLoading={provider?.isLoading || loading}
          placeholder={placeholder}
          isMulti={true}
          isClearable={false}
          isSearchable={false}
          hideSelectedOptions={false}
          error={error}
          menuPosition="fixed"
          components={{
            MultiValue: Value,
            ValueContainer,
            Option: Option(selectAll),
          }}
        />
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
}
