/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import SelfScheduleStore from "stores/selfSchedule/SelfScheduleStore";

import SvgI16Attention from "common/assets/icons/I16Attention";

import { Row, Field } from "common/components/Form";
import TextInput from "common/components/Input/TextInput";

import Header from "../../Header";

import { emailValidator } from "common/utils/validators";

import {
  ButtonBlue,
  ButtonHint,
  ButtonWrapper,
  Container,
  Content,
  Controls,
  Hint,
  HintText,
} from "../styles";

type Props = {};

@observer
class Email extends React.Component<Props> {
  @observable email: string = SelfScheduleStore.email || "";
  @observable error: string = "";

  onEmailChange = (value: string) => {
    this.email = value.trim();
    this.resetError();
  };

  checkEmail = () => {
    this.error = emailValidator(this.email) ? "Incorrect email." : "";
    return !this.error;
  };

  resetError = () => {
    this.error = "";
  };

  onSubmit = async () => {
    const { stepForward } = SelfScheduleStore;

    const isChecked = this.checkEmail();

    if (isChecked) {
      try {
        await SelfScheduleStore.sendPinCode({
          email: this.email,
        });

        stepForward();
      } catch (error) {
        this.error = error.message;
      }
    }
  };

  render() {
    const { isLoading } = SelfScheduleStore;

    return (
      <Container>
        <Content>
          <Header />
          <Hint>
            <SvgI16Attention />
            <HintText>
              Please provide the email that you used to register for the study
            </HintText>
          </Hint>
          <Row>
            <Field title="Email" required>
              <TextInput
                value={this.email}
                onChange={this.onEmailChange}
                type="text"
                placeholder="Specify your email"
                error={this.error}
                canStretchOnError
              />
            </Field>
          </Row>
        </Content>
        <Controls>
          <ButtonWrapper>
            <ButtonBlue
              onClick={this.onSubmit}
              isLoading={isLoading}
              width={130}
            >
              Send code
            </ButtonBlue>
          </ButtonWrapper>
          <ButtonHint>
            We will send you a pin code to your email address to verify your
            identity.
          </ButtonHint>
        </Controls>
      </Container>
    );
  }
}

export default Email;
