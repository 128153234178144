/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI16Dropdown(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#F6F9FF"
        d="M5.707 6.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 10-1.414-1.414L8 8.586 5.707 6.293z"
      />
    </svg>
  );
}

export function SvgI16DropdownRotated(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#F6F9FF"
        d="M5.707 6.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 10-1.414-1.414L8 8.586 5.707 6.293z"
        style={{ transformOrigin: "center", transform: "rotateZ(180deg)" }}
      />
    </svg>
  );
}

export default SvgI16Dropdown;
