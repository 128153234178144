/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const ASSIGNEE_TYPE = {
  ASSIGNED_TO_ANYONE: "AssignedToAnyone",
  UNASSIGNED: "Unassigned",
  ASSIGNED_TO_ME: "AssignedToMe",
};

export const ASSIGNEE_LABEL = {
  ASSIGNED_TO_ANYONE: "Assigned to anyone",
  UNASSIGNED: "Unassigned",
  ASSIGNED_TO_ME: "Assigned to me",
};

export const ASSIGNEE_OPTIONS = [
  {
    id: ASSIGNEE_TYPE.ASSIGNED_TO_ANYONE,
    label: ASSIGNEE_LABEL.ASSIGNED_TO_ANYONE,
  },
  { id: ASSIGNEE_TYPE.UNASSIGNED, label: ASSIGNEE_LABEL.UNASSIGNED },
  { id: ASSIGNEE_TYPE.ASSIGNED_TO_ME, label: ASSIGNEE_LABEL.ASSIGNED_TO_ME },
];

export const STATUS_MISSED = { id: "Missed", label: "Missed" };

export const STATUS_OPTIONS = [
  {
    id: "Open",
    label: "Open",
  },
  {
    id: "InProgress",
    label: "In Progress",
  },
  {
    id: "Completed",
    label: "Completed",
  },
  STATUS_MISSED,
];
