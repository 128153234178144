/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { Portal } from "react-portal";

import Fab from "common/components/Button/Fab/Fab";
import I16Attention from "common/assets/icons/I16Attention";

import { Container, Text } from "./styles";

export type SnackbarTypes = "Alert" | "Info" | "Warning";

type Props = {
  timer?: number;
  show: boolean;
  text: string;
  onHide: () => void;
  type?: SnackbarTypes;
  position?: "top" | "bottom";
};

@observer
export default class Snackbar extends React.Component<Props> {
  lastTimerId: number | null = null;

  clear = () => {
    if (this.lastTimerId) {
      clearTimeout(this.lastTimerId);
      this.lastTimerId = null;
    }
  };

  componentDidUpdate() {
    this.clear();

    if (this.props.show) {
      const { onHide, timer } = this.props;
      this.lastTimerId = window.setTimeout(onHide, timer ?? 6000);
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  render() {
    const { position, show, text, type } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Portal>
        <Container position={position ?? "bottom"} type={type ?? "Alert"}>
          <Fab icon={<I16Attention />} color={"white"} />
          <Text>{text}</Text>
        </Container>
      </Portal>
    );
  }
}
