/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { observable } from "mobx";

import EmailConfirmStore from "stores/EmailConfirmStore";

import Background from "common/components/Layout/Background/BackgroundLight";
import { Header } from "common/components/Layout";

import { isIOS, isMacOS } from "common/utils/window";
import { correctTextFromQueryParams } from "common/utils/queryParamsUtils";

import { Container, Content, Title, Link, Loader, Paper, Text } from "./styles";

type Props = {} & RouteComponentProps;

const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=edu.emory.university.mysmartmobile";
const APP_STORE_LINK =
  "https://apps.apple.com/us/app/mysmartmobile/id1546095478";

@observer
class ChangeEmail extends React.Component<Props> {
  @observable confirmIsSuccessful: boolean = false;
  @observable error: string = "";

  get userId(): number | null {
    const userId = new URLSearchParams(this.props.location.search).get(
      "userId",
    );
    return userId ? Number.parseInt(userId) : null;
  }

  get code(): string | null {
    const code =
      new URLSearchParams(this.props.location.search).get("code") ?? null;

    return code ? correctTextFromQueryParams(code) : code;
  }

  async componentDidMount() {
    if (!this.userId || !this.code) {
      this.confirmIsSuccessful = false;
      return;
    }

    const { isSuccess, error } = await EmailConfirmStore.confirm({
      userId: this.userId,
      code: this.code,
    });

    this.confirmIsSuccessful = isSuccess;
    this.error = error;
  }

  onLinkClick = async () => {
    const storeLink = isIOS || isMacOS ? APP_STORE_LINK : GOOGLE_PLAY_LINK;

    window.location.assign(storeLink);
  };

  render() {
    return (
      <Container>
        <Background />
        <Header />
        <Title>{"Account confirmation"}</Title>
        <Paper>
          <Content>
            {EmailConfirmStore.isLoading ? (
              <Loader />
            ) : this.confirmIsSuccessful ? (
              <Text>
                {"Your participant account has been confirmed.\nUse the "}
                <Link onClick={this.onLinkClick}>
                  {"SMART mobile application"}
                </Link>
                {" to sign in."}
              </Text>
            ) : (
              <Text>{this.error}</Text>
            )}
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(ChangeEmail);
