/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Container, Title, Subtitle } from "./styles";

type Props = {
  title: string;
  subtitle?: string;
  lightSubtitle?: boolean;
};

function FormTitle({ title, subtitle, lightSubtitle }: Props) {
  return (
    <Container>
      <Title>{title}</Title>

      <Subtitle lightSubtitle={lightSubtitle}>{subtitle}</Subtitle>
    </Container>
  );
}

export default FormTitle;
