/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  cursor: pointer;

  ${({ childrenWithEllipsis }) =>
    childrenWithEllipsis &&
    css`
      width: 100%;
    `}
`;

export const StyledTippy = styled(Tippy)`
  .tippy-content {
    font-family: OpenSans;
    font-size: 12px;
    line-height: 1.67;

    padding: 10px 12px;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  ${({ theme }) =>
    theme === "navbarButton" &&
    css`
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      background-color: #232429 !important;
      border-radius: 6px !important;

      .tippy-content {
        padding: 3px 10px;

        color: ${COLORS.TEXT_LIGHT};
        font-size: 14px;
        font-weight: 600;
        line-height: 1.43;
      }
    `}
`;
