/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { FormSpy } from "react-final-form";

import { Row } from "common/components/Form";
import FormControl, { TextFieldControl } from "common/components/FormControl";
import { ButtonBlue } from "common/components/Button/Button";

import { emailValidator } from "common/utils/validators";

import { Form, Error, Text, ButtonSubmit } from "../styles";

type Props = {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  error: string;
  resetError?: () => void;
};

@observer
class EnterEmailForm extends React.Component<Props> {
  emailValidate(email: string) {
    return emailValidator(email) ? "Incorrect email" : "";
  }

  resetError = () => {
    const { error, resetError } = this.props;
    if (!error || !resetError) {
      return;
    }

    resetError();
  };

  render() {
    const { onSubmit, error } = this.props;

    return (
      <FormControl onSubmit={onSubmit}>
        <FormSpy subscription={{ values: true }} onChange={this.resetError} />
        <Form>
          <Row>
            <Text>
              This page allows you to update the email address tied to your
              participant account in SMART system. To change the email please
              specify the address that you <b>currently use</b> for your
              participant account.
            </Text>
          </Row>
          <Row>
            <TextFieldControl
              name="email"
              title="Email"
              placeholder="Specify your current email"
              validate={this.emailValidate}
              canStretchOnError
              required
            />
          </Row>
          <Row>
            <Text>
              <b>PLEASE NOTE:</b> If you participate in multiple studies, your
              email address will be changed for all of them throughout SMART
              system. All emails will be redirected to the new address and the
              mobile app login credentials will change.
            </Text>
          </Row>
          {error && (
            <Row>
              <Error>{error}</Error>
            </Row>
          )}
          <ButtonSubmit render={this.renderButton} />
        </Form>
      </FormControl>
    );
  }

  renderButton = ({ onClick }: { onClick: () => void }) => (
    <ButtonBlue onClick={onClick} isLoading={this.props.isLoading}>
      Next
    </ButtonBlue>
  );
}

export default EnterEmailForm;
