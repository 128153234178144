/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types, onSnapshot } from "mobx-state-tree";

import api, { assignAuth, assignOnRefresh } from "common/services/api";

import { AuthModel } from "models/Auth";
import {
  RegisterData,
  ResetPasswordData,
  ValidateTokenData,
} from "common/types/models";

import { ROLE_TYPES } from "constants/roles";
import { DASHBOARD_TYPES } from "constants/dashboard";

import { getDashboard, getRoleNameByRoleId } from "common/utils/auth";

const AuthStore = types
  .model("AuthStore", {
    tokenData: types.maybe(AuthModel),
    dashboard: types.maybe(types.enumeration("Dashboard", DASHBOARD_TYPES)),
    isLoading: types.maybe(types.boolean),
    authRole: types.maybe(types.enumeration("AuthRole", ROLE_TYPES)),
  })
  .views(self => ({
    get isSignedIn() {
      return !!self.tokenData?.accessToken;
    },

    hasRole(roles: Array<string>) {
      return self.authRole && roles.includes(self.authRole);
    },
  }))
  .actions(self => ({
    signOut: () => {
      window.sessionStorage.removeItem("tokenData");
      self.tokenData = undefined;
      window.sessionStorage.removeItem("dashboard");
      self.dashboard = undefined;
      window.sessionStorage.removeItem("authRole");
      self.authRole = undefined;
    },
  }))
  .actions(self => ({
    setTokenData: (tokenData: any) => {
      self.tokenData = tokenData;
    },
    setDashboard: (dashboard: any) => {
      self.dashboard = dashboard;
    },
    setAuthRole: (authRole: any) => {
      self.authRole = authRole;
    },
  }))
  .actions(self => ({
    refreshToken: flow(function*() {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/refresh", {
          token: auth.tokenData?.refreshToken,
        });
        if (result.accessToken) {
          self.tokenData = result;
        }
        return result;
      } catch (error) {
        console.error("error", error);
        self.signOut();
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    unassignRole: flow(function*() {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/unassign-role", {});

        if (result.accessToken) {
          self.tokenData = result;
          self.authRole = "NONE";
        }
        return result;
      } catch (error) {
        console.error("error", error);

        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .actions(self => ({
    afterCreate() {
      const tokenData = window.sessionStorage.getItem("tokenData");
      assignOnRefresh(self.refreshToken);
      if (tokenData) {
        const authObject = JSON.parse(tokenData);
        self.tokenData = authObject;
        assignAuth(authObject);
      }
      const dashboard = window.sessionStorage.getItem("dashboard");
      if (dashboard) {
        self.dashboard = dashboard;
      }
      const authRole = window.sessionStorage.getItem("authRole");
      if (authRole) {
        self.authRole = authRole;
      }
      onSnapshot(self, ({ authRole, dashboard, tokenData }) => {
        assignAuth(tokenData);
        assignOnRefresh(self.refreshToken);
        if (tokenData) {
          window.sessionStorage.setItem("tokenData", JSON.stringify(tokenData));
        }
        if (dashboard) {
          window.sessionStorage.setItem("dashboard", dashboard);
        }
        if (authRole) {
          window.sessionStorage.setItem("authRole", authRole);
        }
      });
    },
    signIn: flow(function*(email: string, password: string) {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/login", {
          email: email,
          password: password,
        });

        if (result.tokenData?.accessToken) {
          self.tokenData = result.tokenData;
        }
        if (result.dashboard) {
          self.dashboard = result.dashboard;

          self.authRole = getDashboard(result.dashboard);
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    assignRole: flow(function*(
      roleId: number,
      customerId?: number,
      studyId?: number,
      siteId?: number,
    ) {
      try {
        self.isLoading = true;

        const payload = {
          roleId,
          ...{ customerId },
          ...{ studyId },
          ...{ siteId },
        };
        const result = yield api.post("/account/assign-role", payload);

        if (result.accessToken) {
          self.tokenData = result;

          self.authRole = getRoleNameByRoleId(roleId);
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    register: flow(function*(data: RegisterData) {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/register", data);
        if (result.tokenData?.accessToken) {
          self.tokenData = result.tokenData;
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    forgotPassword: flow(function*(email: string) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";
      try {
        yield api.post(`/account/forgot-password`, { email });
        isSuccess = true;
      } catch (e) {
        console.error(e);
        error = e.message;
      } finally {
        self.isLoading = false;
      }
      return { isSuccess, error };
    }),
    resetPassword: flow(function*(data: ResetPasswordData, isMobile?: boolean) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";
      try {
        isMobile
          ? yield api.post(`/mobile/account/reset-password`, data)
          : yield api.post(`/account/reset-password`, data);
        isSuccess = true;
      } catch (e) {
        console.error(e);
        error = e.message;
      } finally {
        self.isLoading = false;
      }
      return { isSuccess, error };
    }),
    validateToken: flow(function*(data: ValidateTokenData) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";
      try {
        yield api.post(`/mobile/account/reset-password/validate`, data);
        isSuccess = true;
      } catch (e) {
        console.error(e);
        error = e.message;
      } finally {
        self.isLoading = false;
      }
      return { isSuccess, error };
    }),
  }));

export const auth = AuthStore.create();

export default auth;
