/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { flow, types, cast } from "mobx-state-tree";
import api from "common/services/api";
import createTableStore from "stores/templates/TableStore";

export const SOURCE = {
  SMS: "Sms",
  EMAIL: "Email",
};

export const MessageTemplate = types.model({
  id: types.number,
  name: types.maybe(types.string),
  subjectTemplate: types.maybe(types.string),
  bodyTemplate: types.maybe(types.string),
  source: types.maybe(types.string),
});

export const DictionaryItemModel = types.model({
  value: types.string,
  label: types.string,
});

const StudyMessageTemplatesStore = createTableStore({
  fetch: function({ self, params }) {
    return api.get(`/study/${self.studyId}/message-template/list`, {
      ...params,
      Sources: self.sourcesFilter,
    });
  },
  itemModel: MessageTemplate,
  initialOrder: {
    column: "name",
    desc: false,
  },
})
  .props({
    studyId: types.maybe(types.number),
    isDeleteLoading: types.maybe(types.boolean),
    isAddLoading: types.optional(types.boolean, false),
    isEditLoading: types.maybe(types.boolean),
    sourcesFilter: types.maybe(types.array(types.string)),
  })
  .actions(self => ({
    initAndFetch: flow(function*(studyId: number) {
      self.studyId = studyId;
      yield self.fetch();
    }),
    applyTypeFilterAndFetch: flow(function*(sourcesFilter: string[]) {
      self.sourcesFilter = cast(sourcesFilter);
      yield self.fetch();
    }),
    deleteMessageTemplate: flow(function*(messageTemplateId: number) {
      self.isDeleteLoading = true;
      let success = false;

      try {
        yield api.delete(`/message-template/${messageTemplateId}`, {});
        success = true;
      } finally {
        self.isDeleteLoading = false;
      }

      return { success };
    }),
    addTemplate: flow(function*(studyId: number, values: any) {
      let isSuccess = false;
      let error = "";
      self.isAddLoading = true;

      try {
        yield api.post(`/study/${studyId}/message-template`, { ...values });
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isAddLoading = false;
      }

      return { isSuccess, error };
    }),
    editTemplate: flow(function*(templateId: number, values: any) {
      let isSuccess = false;
      let error = "";
      self.isEditLoading = true;

      try {
        yield api.put(`/message-template/${templateId}`, { ...values });
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isEditLoading = false;
      }

      return { isSuccess, error };
    }),
  }));

export default StudyMessageTemplatesStore.create();
