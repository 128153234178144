/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Container } from "./styles";

type Props = {
  icon: React.ReactNode;
  className?: any;
  color?: string | null;
  hoverColor?: string;
};

class Icon extends React.Component<Props> {
  render() {
    const { icon, color, hoverColor, className } = this.props;
    return (
      <Container className={className} color={color} hoverColor={hoverColor}>
        {icon}
      </Container>
    );
  }
}

export default Icon;
