/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { computed, observable, toJS } from "mobx";
import { Instance } from "mobx-state-tree";
import { ValueType } from "react-select";
import { format, isEqual } from "date-fns";

import SelfScheduleStore, {
  Location as LocationModel,
} from "stores/selfSchedule/SelfScheduleStore";
import SelfScheduleLocationsDictionary from "stores/selfSchedule/SelfScheduleLocationsDictionary";
import ManageTimeZonesDictionary from "studyAdmin/stores/dictionary/ManageTimeZonesDictionary";

import SvgI16Attention from "common/assets/icons/I16Attention";

import { Field, Row } from "common/components/Form";
import Dropdown from "common/components/Input/Dropdown";
import Snackbar from "common/components/Snackbar";
import Tooltip from "common/components/Tooltip";

import Calendar from "./Calendar";
import Header from "../../Header";

import { convertDateToTimezone } from "studyAdmin/utils/calendar";

import { DictionaryItem } from "common/types/models";

import { TIMEZONE_IS_DIFFERENT_TOOLTIP_TEXT } from "studyAdmin/constants/calendar";

import {
  ButtonBlue,
  ButtonWrapper,
  Container,
  Content,
  Controls,
  IconContainer,
  FieldValue,
} from "../styles";

type Props = {};

@observer
class Schedule extends React.Component<Props> {
  @observable calendarDate: Date = new Date();
  @observable location: Instance<typeof LocationModel> | undefined;
  @observable selectedSlot: Date | undefined;
  @observable error: string = "";
  @observable needSnackbar: boolean = false;
  @observable timezoneIsDifferentFromSiteTimezone: boolean = false;
  @observable timezoneIsDifferentTooltipText: string = "";

  @computed
  get localTimezone() {
    const localTimezoneUTC = `(${format(new Date(), "zzzz").replace(
      "GMT",
      "UTC",
    )})`;

    const localTimezoneIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return ManageTimeZonesDictionary.getOriginalTimezoneByPartOfText(
      localTimezoneUTC,
      localTimezoneIANA,
    );
  }

  @computed get chosenTimezone() {
    return SelfScheduleStore.timezone;
  }

  @computed get chosenTimezoneValue() {
    return SelfScheduleStore.timezoneValue;
  }

  @computed
  get currentTimezone() {
    return this.chosenTimezone || this.localTimezone;
  }

  @computed
  get siteTimezone() {
    return ManageTimeZonesDictionary.getOriginalTimezoneByText(
      this.location?.siteTimeZone?.name,
    );
  }

  @computed
  get siteTimezoneValue() {
    return this.siteTimezone?.timezoneIANA || this.siteTimezone?.timezoneOffset;
  }

  @computed
  get localTimezoneValue() {
    return (
      this.localTimezone?.timezoneIANA || this.localTimezone?.timezoneOffset
    );
  }

  @computed
  get currentTimezoneValue() {
    return (
      this.chosenTimezone?.timezoneIANA ||
      this.chosenTimezone?.timezoneOffset ||
      this.localTimezoneValue
    );
  }

  checkTimezoneIsDifferentFromSite = () => {
    if (!this.siteTimezoneValue) {
      this.timezoneIsDifferentFromSiteTimezone = false;
      return;
    }

    if (this.chosenTimezoneValue) {
      this.timezoneIsDifferentFromSiteTimezone = !isEqual(
        convertDateToTimezone(this.calendarDate, this.chosenTimezoneValue),
        convertDateToTimezone(this.calendarDate, this.siteTimezoneValue),
      );
      this.timezoneIsDifferentTooltipText =
        TIMEZONE_IS_DIFFERENT_TOOLTIP_TEXT.CHOSEN_TIMEZONE;
      return;
    }

    this.timezoneIsDifferentFromSiteTimezone = !isEqual(
      convertDateToTimezone(this.calendarDate, this.siteTimezoneValue),
      this.currentTimezoneValue
        ? convertDateToTimezone(this.calendarDate, this.currentTimezoneValue)
        : this.calendarDate,
    );
    this.timezoneIsDifferentTooltipText =
      TIMEZONE_IS_DIFFERENT_TOOLTIP_TEXT.LOCAL_TIMEZONE;
  };

  async componentDidMount() {
    const { visit, stepBackward } = SelfScheduleStore;

    const activityId = visit?.id;

    if (activityId) {
      this.resetError();

      try {
        await SelfScheduleLocationsDictionary.fetch(activityId);
        await ManageTimeZonesDictionary.fetch();
      } catch (error) {
        this.error = error.message;
      }
    } else {
      stepBackward();
    }
  }

  onCalendarDateChange = (date: Date) => {
    this.calendarDate = date;
  };

  onLocationChange = (item: any) => {
    this.location = item.value;
    this.onCalendarDateChange(new Date());
    this.checkTimezoneIsDifferentFromSite();
  };

  onTimezoneChange = (option: ValueType<DictionaryItem>) => {
    const timezone = ManageTimeZonesDictionary.getOriginalTimezoneByValue(
      option.value,
    );

    if (timezone && timezone.value !== this.localTimezone.value) {
      SelfScheduleStore.setTimezone(toJS(timezone));
    } else {
      SelfScheduleStore.resetTimezone();
    }

    this.checkTimezoneIsDifferentFromSite();
  };

  onSelectSlot = (slot: Date) => {
    this.selectedSlot = slot;
  };

  resetError = () => {
    this.error = "";
  };

  onSnackbarHide = () => {
    this.needSnackbar = false;
  };

  onSubmit = async () => {
    const { stepForward } = SelfScheduleStore;

    try {
      SelfScheduleStore.setDate(this.selectedSlot);
      SelfScheduleStore.setLocation(this.location);

      await SelfScheduleStore.createRequest(this.selectedSlot);

      stepForward();
    } catch (error) {
      this.needSnackbar = true;

      this.error = error.message;
    }
  };

  render() {
    const { isLoading } = SelfScheduleStore;

    return (
      <Container>
        <Content>
          <Header />
          <Row>
            <Field title="Location" required>
              <Dropdown
                input={{ value: this.location }}
                onChange={this.onLocationChange}
                provider={SelfScheduleLocationsDictionary}
                placeholder="Select visit"
              />
            </Field>
          </Row>
          {this.location && (
            <>
              {this.location.addressFull && (
                <Row>
                  <Field title="Address">
                    <FieldValue>{this.location.addressFull}</FieldValue>
                  </Field>
                </Row>
              )}
              {this.location.siteDirection && (
                <Row>
                  <Field title="Direction">
                    <FieldValue>{this.location.siteDirection}</FieldValue>
                  </Field>
                </Row>
              )}
              <Row>
                <Field
                  title="Display time zone"
                  action={
                    this.timezoneIsDifferentFromSiteTimezone && (
                      <Tooltip content={this.timezoneIsDifferentTooltipText}>
                        <IconContainer>
                          <SvgI16Attention />
                        </IconContainer>
                      </Tooltip>
                    )
                  }
                  actionShiftedLeft={true}
                  required
                >
                  <Dropdown
                    input={{
                      value: this.currentTimezone?.value,
                    }}
                    onChange={this.onTimezoneChange}
                    placeholder="Select"
                    provider={ManageTimeZonesDictionary}
                  />
                </Field>
              </Row>
            </>
          )}
          {this.location && (
            <Calendar
              date={this.calendarDate}
              onDateChange={this.onCalendarDateChange}
              location={this.location}
              selectedSlot={this.selectedSlot}
              onSelectSlot={this.onSelectSlot}
              timezone={this.currentTimezone.value}
            />
          )}
        </Content>

        <Controls>
          <ButtonWrapper>
            <ButtonBlue
              onClick={this.onSubmit}
              disabled={!this.selectedSlot}
              isLoading={isLoading}
              width={120}
            >
              Schedule
            </ButtonBlue>
          </ButtonWrapper>
        </Controls>

        <Snackbar
          show={this.needSnackbar}
          onHide={this.onSnackbarHide}
          text={this.error}
          type={"Alert"}
          timer={5000}
        />
      </Container>
    );
  }
}

export default Schedule;
