/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  ParticipantDocumentsStore,
  StudyDocumentsStore,
} from "stores/DocumentsStore";
import StudyMessageTemplatesStore from "stores/study/StudyMessageTemplatesStore";
import LabResultsStore from "stores/study/LabResultsStore";
import StudyStaffStore from "stores/study/StudyStaffStore";

import CustomersStore from "superAdmin/stores/CustomersStore";
import LicensedStudiesStore, {
  CustomerLicensedStudiesStore,
} from "superAdmin/stores/LicensedStudiesStore";
import UsersStore from "superAdmin/stores/UsersStore";

import CustomerAdminsStore from "customerAdmin/stores/CustomerAdminsStore";
import CustomerStaffStore from "customerAdmin/stores/CustomerStaffStore";
import StudiesStore from "customerAdmin/stores/StudiesStore";

import MilestonesSummaryStore from "studyAdmin/stores/MilestonesSummaryStore";
import ParticipantsStore from "studyAdmin/stores/ParticipantsStore";
import ParticipantLabResultsStore from "studyAdmin/stores/ParticipantLabResultsStore";
import TasksStore from "studyAdmin/stores/TasksStore";

export const TABLE_STORES = [
  CustomerAdminsStore,
  CustomerStaffStore,
  StudyDocumentsStore,
  StudyMessageTemplatesStore,
  StudyStaffStore,
  ParticipantsStore,
  ParticipantDocumentsStore,
  ParticipantLabResultsStore,
  TasksStore,
  CustomersStore,
  CustomerLicensedStudiesStore,
  LicensedStudiesStore,
  UsersStore,
  MilestonesSummaryStore,
  LabResultsStore,
];

export const STORES = [StudiesStore];

export const resetTableStores = () => {
  TABLE_STORES.forEach(store => store.reset());
};

export const resetStores = () => {
  STORES.forEach(store => store.reset);
};
