/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import ReactResizeDetector from "react-resize-detector";

import Tooltip from "common/components/Tooltip";

import { Container, Wrapper } from "./styles";

type Props = {
  lines?: number;
  text?: string | React.ReactNode;
  children: any;
  inactiveEmployee?: boolean;
  inactiveColor?: string;
  alwaysShowContent?: boolean;
};

type TrimmedProps = {
  lines?: number;
  inactive?: boolean;
  inactiveColor?: string;
  className?: any;
  children: any;
  getRef?: (ref: any) => void;
};

const CONTENT_IS_TRIMMED_ACCURACY = 2;

const Trimmed = ({
  getRef,
  lines,
  children,
  className,
  inactive,
  inactiveColor,
}: TrimmedProps) => (
  <Wrapper
    lines={lines}
    ref={getRef}
    className={className}
    inactive={inactive}
    inactiveColor={inactiveColor}
  >
    {children}
  </Wrapper>
);

@observer
class TrimmedText extends React.Component<Props> {
  @observable contentRef: any = null;
  @observable shouldOpenTooltip: boolean = false;

  get tooltipText() {
    const { text, inactiveEmployee } = this.props;
    if (inactiveEmployee) {
      return "This account was deactivated.";
    }
    return text;
  }

  get inactive() {
    const { inactiveEmployee } = this.props;
    return inactiveEmployee;
  }

  @computed get isContentTrimmed() {
    const { alwaysShowContent = false } = this.props;

    if (alwaysShowContent) {
      return true;
    }

    if (this.contentRef) {
      const { offsetHeight, offsetWidth, scrollHeight, scrollWidth } =
        this.contentRef;

      return (
        Math.abs(scrollHeight - offsetHeight) >= CONTENT_IS_TRIMMED_ACCURACY ||
        Math.abs(scrollWidth - offsetWidth) >= CONTENT_IS_TRIMMED_ACCURACY
      );
    }

    return false;
  }

  componentDidMount() {
    this.shouldOpenTooltip = this.isContentTrimmed;
  }

  getRef = (ref: any) => {
    this.contentRef = ref;
  };

  onResize = (width: any, height: any) => {
    this.shouldOpenTooltip = this.isContentTrimmed;
  };

  render() {
    const { lines, text, children } = this.props;

    if (!lines) return children ?? text;

    return (
      <Tooltip
        content={this.tooltipText}
        shouldOpen={this.inactive || this.shouldOpenTooltip}
        childrenWithEllipsis
      >
        <Container>
          <ReactResizeDetector onResize={this.onResize}>
            <Trimmed
              {...this.props}
              getRef={this.getRef}
              inactive={this.inactive}
            />
          </ReactResizeDetector>
        </Container>
      </Tooltip>
    );
  }
}

export default TrimmedText;
