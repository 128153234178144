/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI16Trash(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#5682f6"
        d="M13 3a1 1 0 010 2v8a1 1 0 01-1 1H4a1 1 0 01-1-1V5a1 1 0 110-2h10zm-2 2H5v7h6V5zm-1-5a1 1 0 010 2H6a1 1 0 110-2h4z"
      />
    </svg>
  );
}

export default SvgI16Trash;
