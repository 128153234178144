/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import SelfScheduleStore from "stores/selfSchedule/SelfScheduleStore";

import { StepHeader, StepLead } from "../styles";

type Props = {};

class Header extends React.Component<Props> {
  get lead() {
    const { email, step } = SelfScheduleStore;

    switch (step) {
      case 1:
        return `Step 1 of 4 - Specify information about you`;
      case 2:
        return `Step 2 of 4 - Enter code that we sent to your email: ${email}`;
      case 3:
        return `Step 3 of 4 - Select a visit that you’d like to schedule`;
      case 4:
        return `Step 4 of 4 - Select a location and appropriate time`;
      default:
        return ``;
    }
  }

  get header() {
    const { step } = SelfScheduleStore;

    switch (step) {
      case 1:
        return `Enter Participant Email`;
      case 2:
        return `Verify`;
      case 3:
        return `Choose visit`;
      case 4:
        return `Choose Place and Time`;
      case 5:
        return `Request is Submitted`;
      default:
        return ``;
    }
  }

  render() {
    return (
      <>
        {this.lead && <StepLead>{this.lead}</StepLead>}
        <StepHeader>{this.header}</StepHeader>
      </>
    );
  }
}

export default Header;
