/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};

  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Marker = styled.div`
  width: 16px;
  height: 16px;

  background-color: rgba(169, 180, 201, 0.24);
  border: 2px solid;
  border-color: ${({ isSelected }) =>
    isSelected ? "rgba(86, 130, 246, 0.18)" : "transparent"};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &::after {
    content: "";
    display: flex;

    width: 8px;
    height: 8px;

    border-radius: 50%;

    background-color: ${({ isSelected }) =>
      isSelected ? COLORS.CORNFLOWER : "transparent"};
  }
`;

export const Text = styled.span`
  font-family: "OpenSans-Regular";
  font-size: 14px;

  color: ${({ isSelected }) => (isSelected ? COLORS.CORNFLOWER : "#919bb8")};

  margin-left: 10px;

  word-break: break-all;
`;
