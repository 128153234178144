/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
type Color = {
  r: number;
  g: number;
  b: number;
};

type Props = {
  color: Color;
  background?: Color;
};

class ColorConverter {
  constructor(props: Props) {
    this._color = props.color;
    this._background = props.background || { r: 255, g: 255, b: 255 };
  }

  get color() {
    return this.colorToString(this.rgba2rgb(1));
  }

  get backgroundColor() {
    return this.colorToString(this.rgba2rgb(0.16));
  }

  get borderColor() {
    return {
      solid: this.colorToString(this.rgba2rgb(0.32)),
      dashed: this.colorToString(this.rgba2rgb(0.6)),
    };
  }

  rgba2rgb = (alpha: number) => ({
    r: (1 - alpha) * this._background.r + alpha * this._color.r,
    g: (1 - alpha) * this._background.g + alpha * this._color.g,
    b: (1 - alpha) * this._background.b + alpha * this._color.b,
  });

  colorToString = ({ r, g, b }: Color) => {
    r = Number.parseInt(r);
    g = Number.parseInt(g);
    b = Number.parseInt(b);

    return `rgb(${r}, ${g}, ${b})`;
  };
}

export default ColorConverter;
