/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import I120PlaceholderStudy from "common/assets/icons/I120PlaceholderStudy";
import Icon from "common/components/Icon";
import { COLORS } from "common/constants/layout";
import { Container, Title, Details } from "./styles";

type Props = {
  className?: any;
  icon?: React.ReactNode;
  title?: string;
  details?: string | React.ReactNode;
  color?: string | null;
};

export default class Placeholder extends React.Component<Props> {
  render() {
    const {
      icon = <I120PlaceholderStudy />,
      title,
      details,
      color = COLORS.CORNFLOWER,
      className,
    } = this.props;

    return (
      <Container className={className}>
        <Icon icon={icon} color={color} />
        <Title>{title}</Title>
        {details && <Details>{details}</Details>}
      </Container>
    );
  }
}
