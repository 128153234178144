/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const IMAGE_TYPES = {
  ORIGINAL: "Original",
  WEB: "Web",
  WEB_ICON: "WebIcon",
};
