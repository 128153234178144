/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { applySnapshot, flow, types } from "mobx-state-tree";

import api from "common/services/api";

import createTableStore from "stores/templates/TableStore";

import { getImageType } from "common/utils/images";

import { UserModel } from "models/UserModel";
import { IMAGE_TYPES } from "constants/imageTypes";

const Logo = types.model("Logo", {
  id: types.number,
  logo: types.string,
});

const UsersStore = createTableStore({
  fetch: function({ params }) {
    return api.get(`/user/list`, params);
  },
  exportData: function({ self, params }) {
    const url = params.roleId
      ? `/user/list/export?RoleId=${params.roleId}`
      : `/user/list/export`;
    return api.downloadPost(url, {
      ids: self.selectedIds,
    });
  },
  itemModel: UserModel,
  searchBy: "name",
})
  .props({
    customerLogos: types.array(Logo),
    selectedIds: types.optional(types.array(types.number), []),
    isDeleteLoading: types.optional(types.boolean, false),
    isInviteLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    select(ids: Array<number>) {
      applySnapshot(self.selectedIds, ids);
    },

    fetchLogos: flow(function*() {
      self.isLoading = true;
      try {
        const ids = new Map();

        self.items.map(item =>
          item.customers.map(customer => ids.set(customer.id, customer.id)),
        );

        const logos: any = [];
        for (const id of ids.keys()) {
          type Response = { blob?: Blob; contentDisposition?: string };
          const response: Response = yield api.getBlob(
            `/customer/${id}/photo/download`,
            {
              imageType: getImageType(IMAGE_TYPES.WEB),
            },
          );
          if (response.blob) {
            logos.push({
              id,
              logo: URL.createObjectURL(response.blob),
            });
          }
        }

        applySnapshot(self, { ...self, customerLogos: logos });
      } finally {
        self.isLoading = false;
      }
    }),

    deleteUser: flow(function*(userId: number) {
      self.isDeleteLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.delete(`/user/${userId}`, {});
        isSuccess = true;
      } catch (e) {
        error = e.message;
      } finally {
        self.isDeleteLoading = false;
      }

      return { isSuccess, error };
    }),

    deleteUsers: flow(function*(userIds: Array<number>) {
      self.isDeleteLoading = true;
      let isSuccess = false;

      try {
        yield api.delete(`/user`, { ids: userIds });
        isSuccess = true;
      } finally {
        self.isDeleteLoading = false;
      }

      return isSuccess;
    }),

    inviteLocatorManager: flow(function*(payload) {
      self.isInviteLoading = true;

      try {
        yield api.post("/locator/sti/managers/invite", payload);
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isInviteLoading = false;
      }
    }),
  }))
  .views(self => ({
    getLogoByCustomerId(id: number) {
      return self.customerLogos.find(x => x.id === id)?.logo;
    },
  }));

export default UsersStore.create();
