/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import SvgI5Star from "common/assets/icons/I5Star";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ shifted }) =>
    shifted &&
    css`
      margin-left: -8px;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`;

export const Title = styled.span`
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;

  ${({ titleTextAlign }) =>
    titleTextAlign &&
    css`
      text-align: ${titleTextAlign};
    `}

  color: #919bb8;

  ${({ shifted }) =>
    shifted &&
    css`
      margin-left: 8px;
    `}
`;

export const Star = styled(SvgI5Star)`
  margin: -4px 0 0 3px;
  position: relative;
  top: -5px;

  flex-shrink: 0;
`;

export const Action = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: ${({ actionShiftedLeft }) =>
    actionShiftedLeft ? "10px" : "auto"};
  margin-right: -12px;
`;
