/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import {
  flow,
  types,
  SnapshotOrInstance,
  applySnapshot,
} from "mobx-state-tree";

import createTableStore from "stores/templates/TableStore";

import api from "common/services/api";

import {
  formatDate,
  ignoreTimezone,
  SERVER_DATE_FORMAT,
} from "common/utils/dateUtils";

export const LabResultFilter = types.model("LabResultFilter", {
  templateFieldName: types.string,
  templateAvailableValues: types.optional(types.array(types.string), []),
});

export const LabResultValue = types.model("LabResultValue", {
  name: types.string,
  value: types.maybe(types.string),
  type: types.string,
});

export const LabResult = types.model("LabResult", {
  id: types.number,
  participantName: types.maybe(types.string),
  date: types.maybe(types.string),
  values: types.array(LabResultValue),
});

export const LabResultEditValueItem = types.model({
  id: types.string,
  name: types.string,
  value: types.string,
  fieldType: types.string,
  isMetaField: types.boolean,
  availableValues: types.maybe(types.array(types.string)),
});

export const LabResultEditValue = types.model("LabResultEditValue", {
  id: types.number,
  items: types.optional(types.array(LabResultEditValueItem), []),
});

const ParticipantLabResultsStore = createTableStore({
  fetch: function({ self, params, body = {} }) {
    return api.postWithQueryParams(
      `/participant/${self.participantId}/lab-result/list`,
      body,
      params,
    );
  },
  itemModel: LabResult,
  initialOrder: {
    column: "date",
    desc: true,
  },
  initialFilter: {},
})
  .props({
    participantId: types.maybe(types.number),
    labResult: types.maybe(LabResultEditValue),
    templateFilters: types.optional(types.array(LabResultFilter), []),
    isLoading: types.optional(types.boolean, false),
    isLabResultLoading: types.optional(types.boolean, false),
    isAddLoading: types.optional(types.boolean, false),
    isEditLoading: types.optional(types.boolean, false),
    isDeleteLoading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get rows() {
      return self.items.map(item => {
        const result: any = {};
        item.values.map(
          (valueObj: SnapshotOrInstance<typeof LabResultValue>) => {
            const fieldName = valueObj.name;
            const fieldValue = valueObj.value;
            result[fieldName] = fieldValue;
          },
        );
        result.id = item.id;
        result.date = ignoreTimezone(item.date);
        return result;
      });
    },
    get labResultValue() {
      if (!self.labResult) {
        return;
      }

      const result: any = {};
      self.labResult.items.map(item => {
        const fieldType = item.fieldType;
        const fieldName = item.name;
        const fieldValue =
          fieldType === "Date" && item.value
            ? new Date(item.value)
            : item.value;
        result[fieldName] = fieldValue;
      });
      result.id = self.labResult.id;
      return result;
    },
    get customFieldsFilters() {
      return self.templateFilters.length
        ? { templateFilters: self.templateFilters }
        : {};
    },
  }))
  .actions(self => ({
    clearCustomFieldFilter(fieldId: string) {
      const modifiedFilters = self.templateFilters.filter(
        filter => filter.templateFieldName !== fieldId,
      );
      applySnapshot(self, {
        ...self,
        templateFilters: modifiedFilters,
      });
    },
    fetchWithCustomFieldsFilters: flow(function*() {
      yield self.fetch({}, self.customFieldsFilters);
    }),
  }))
  .actions(self => ({
    initAndFetch: flow(function*(participantId: number) {
      self.participantId = participantId;
      self.isLoading = true;

      try {
        yield self.fetchWithCustomFieldsFilters();
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    applyCustomFieldFilter(fieldId: string, value: string) {
      const currentFilters = self.templateFilters;
      const currentfilter = currentFilters.find(
        filter => filter.templateFieldName === fieldId,
      );
      if (currentfilter) {
        const currentFilterValue = currentfilter.templateAvailableValues.find(
          filterValue => filterValue === value,
        );
        if (currentFilterValue) {
          const modifiedFilterValues = currentfilter.templateAvailableValues.filter(
            filterValue => filterValue !== value,
          );
          modifiedFilterValues.length
            ? (currentfilter.templateAvailableValues = modifiedFilterValues)
            : self.clearCustomFieldFilter(fieldId);
        } else {
          currentfilter.templateAvailableValues.push(value);
        }
      } else {
        currentFilters.push({
          templateFieldName: fieldId,
          templateAvailableValues: [value],
        });
      }
      applySnapshot(self, {
        ...self,
        templateFilters: currentFilters,
      });
    },
    fetchLabResult: flow(function*(labResultId: number) {
      self.isLabResultLoading = true;
      try {
        const response = yield api.get(`/lab-result/${labResultId}`);
        applySnapshot(self, { ...self, labResult: response });
      } catch (error) {
        console.log("error :>> ", error);
        throw error;
      } finally {
        self.isLabResultLoading = false;
      }
    }),
    addLabResult: flow(function*(
      values: any,
      templateDateFieldNames: string[],
    ) {
      self.isAddLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        const fields = Object.keys(values)
          .filter(key => key !== "date")
          .map(key => ({
            fieldName: key,
            fieldValue: templateDateFieldNames.includes(key)
              ? formatDate(
                  new Date(values[key]).toISOString(),
                  SERVER_DATE_FORMAT,
                )
              : values[key],
          }));
        yield api.post(`/participant/${self.participantId}/lab-result`, {
          date: formatDate(
            new Date(values.date).toISOString(),
            SERVER_DATE_FORMAT,
          ),
          fields,
        });
        isSuccess = true;
      } catch (e) {
        console.log("error :>> ", e);
        error = e.message;
      } finally {
        self.isAddLoading = false;
      }

      return { isSuccess, error };
    }),
    editLabResult: flow(function*(
      values: any,
      templateDateFieldNames: string[],
    ) {
      self.isEditLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        const fields = Object.keys(values)
          .filter(key => key !== "date" && key !== "id")
          .map(key => ({
            fieldName: key,
            fieldValue: templateDateFieldNames.includes(key)
              ? formatDate(
                  new Date(values[key]).toISOString(),
                  SERVER_DATE_FORMAT,
                )
              : values[key],
          }));
        yield api.put(`/lab-result/${values.id}`, {
          fields,
        });
        isSuccess = true;
      } catch (e) {
        console.log("error :>> ", e);
        error = e.message;
      } finally {
        self.isEditLoading = false;
      }

      return { isSuccess, error };
    }),
    deleteLabResult: flow(function*(labResultId: number) {
      self.isDeleteLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.delete(`/lab-result/${labResultId}`);
        isSuccess = true;
      } catch (e) {
        console.log("error :>> ", e);
        error = e.message;
      } finally {
        self.isDeleteLoading = false;
      }

      return { isSuccess, error };
    }),
    resetLabResult() {
      applySnapshot(self, { ...self, labResult: undefined });
    },
    resetCustomFieldsFilters() {
      applySnapshot(self, { ...self, templateFilters: [] });
    },
  }));

export default ParticipantLabResultsStore.create();
