/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";
import styledWrapper from "common/components/StyledWrapper";

import Link from "common/components/Link";
import DefaultPaper from "common/components/Paper";
import SubmitButtonControl from "common/components/FormControl/SubmitButtonControl";

import { COLORS } from "common/constants/layout";
import { MEDIA_QUERIES } from "constants/layout";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1198px;
  margin: 0 auto;

  padding: 0 23px;
`;

export const Paper = styled(DefaultPaper)`
  :last-child {
    margin-bottom: 25px;
  }
`;

export const Content = styled.div`
  padding: 50px 60px 60px 60px;

  max-width: 590px;

  ${MEDIA_QUERIES.MOBILE} {
    max-width: 440px;

    padding: 25px 30px 30px 30px;
  }
`;

export const Title = styled.p`
  font-family: OpenSans-Bold;
  font-size: 30px;
  color: ${COLORS.GRAY};
  margin-bottom: 18px;
  text-align: center;
  margin: 50px 0;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 380px;
`;

export const ButtonSubmit = styledWrapper(SubmitButtonControl)`
  min-width: 120px;
  margin-top: auto;
  align-self: flex-start;
`;

export const Text = styled.p`
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const Error = styled.p`
  font-size: 16px;
  font-family: "OpenSans-Regular";

  color: #f74a70;
`;

export const NotificationText = styled.p`
  max-width: 590px;
  margin: 20px auto 30px;

  ${MEDIA_QUERIES.MOBILE} {
    max-width: 440px;
  }

  font-size: 13px;
  text-align: center;
  color: #a9b4c9;
  line-height: 1.25;
`;

export const SmallLink = styled(Link)`
  font-size: 13px;
`;
