/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import SelfScheduleStore from "stores/selfSchedule/SelfScheduleStore";

import { ButtonOutline } from "common/components/Button";
import Link from "common/components/Link";
import { Row, Field } from "common/components/Form";
import TextInput from "common/components/Input/TextInput";

import Header from "../../Header";

import { convertSecondsToTime } from "common/utils/timeUtils";

import {
  ButtonBlue,
  ButtonWrapper,
  Container,
  Content,
  Controls,
  Timer,
  TimerText,
} from "../styles";

type Props = {};

@observer
class PinCode extends React.Component<Props> {
  @observable pinCode: string = "";
  @observable isTimerOn: boolean = false;
  @observable error: string = "";

  @observable timer: any = null;
  @observable seconds: number = 60;

  componentDidMount() {
    this.startTimer();
  }

  startTimer = () => {
    this.seconds = 60;
    this.isTimerOn = true;
    this.timer = setInterval(this.countDown, 1000);
  };

  countDown = () => {
    this.seconds--;

    if (this.seconds === 0) {
      this.isTimerOn = false;
      clearInterval(this.timer);
    }
  };

  resendPinCode = async () => {
    const { email, stepBackward } = SelfScheduleStore;

    try {
      await SelfScheduleStore.sendPinCode({
        email,
      });

      this.startTimer();
    } catch (error) {
      stepBackward();
    }
  };

  onPinCodeChange = (value: string) => {
    this.pinCode = value ? value.replace(/\D+/, "").slice(0, 5) : "";

    this.resetError();
  };

  resetError = () => {
    this.error = "";
  };

  onSubmit = async () => {
    const { stepForward } = SelfScheduleStore;

    this.resetError();

    try {
      await SelfScheduleStore.confirmPinCode({
        pin: Number.parseInt(this.pinCode),
      });

      stepForward();
    } catch (error) {
      this.error = error.message;
    }
  };

  render() {
    const { isLoading, stepBackward } = SelfScheduleStore;

    return (
      <Container>
        <Content>
          <Header />
          <Row>
            <Field title="Pin code" required>
              <TextInput
                value={this.pinCode}
                onChange={this.onPinCodeChange}
                type="text"
                placeholder="Enter a pin code"
                error={this.error}
                canStretchOnError
              />
            </Field>
          </Row>
          <Timer>
            {this.isTimerOn ? (
              <TimerText>{`Code sent. Resend in ${convertSecondsToTime(
                this.seconds,
              )} seconds`}</TimerText>
            ) : (
              <>
                <TimerText>
                  Didn’t get a code?{" "}
                  <Link onClick={this.resendPinCode}>Resend</Link>
                </TimerText>
              </>
            )}
          </Timer>
        </Content>
        <Controls>
          <ButtonWrapper>
            <ButtonOutline onClick={stepBackward}>Back</ButtonOutline>
          </ButtonWrapper>

          <ButtonWrapper>
            <ButtonBlue onClick={this.onSubmit} isLoading={isLoading}>
              Next
            </ButtonBlue>
          </ButtonWrapper>
        </Controls>
      </Container>
    );
  }
}

export default PinCode;
