/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import ReactQuill from "react-quill";
import { debounce } from "debounce";

import "./css";
import { Container, ToolbarWrapper } from "./styles";

import EditorToolbar, { modules, formats } from "./EditorToolbar";

type Props = {
  config?: string;
  placeholder?: string;
  content: string;
  stickyToolbar?: boolean;
  onChange: (content: string) => void;
  readOnly?: boolean;
  changeDebounce?: number;
  editorRef?: any;
  withBottomToolbar?: boolean;
  toolbarName?: string;
  editorName?: string;
};

const EDITOR_SCROLL_CONTAINER_ID = "editorScrollContainer";

@observer
class Editor extends React.Component<Props> {
  onChange = debounce(this.props.onChange, this.props.changeDebounce ?? 500);

  render() {
    const {
      config,
      stickyToolbar,
      content,
      placeholder,
      readOnly,
      editorRef,
      withBottomToolbar,
      toolbarName,
      editorName,
    } = this.props;

    const container = toolbarName ? `#toolbar-${toolbarName}` : "#toolbar";
    const id = editorName ? `react-quill-${editorName}` : "react-quill";

    return (
      <Container
        stickyToolbar={stickyToolbar}
        withBottomToolbar={withBottomToolbar}
      >
        {!withBottomToolbar && (
          <ToolbarWrapper readOnly={readOnly}>
            <EditorToolbar config={config} toolbarName={toolbarName} />
          </ToolbarWrapper>
        )}
        <ReactQuill
          placeholder={
            readOnly ? "The text is no longer editable" : placeholder
          }
          theme="snow"
          value={content}
          onChange={this.onChange}
          modules={{
            ...modules,
            toolbar: {
              ...modules.toolbar,
              container,
            },
          }}
          formats={formats}
          id={id}
          bounds={`#${id}`}
          scrollingContainer={
            document.getElementById(EDITOR_SCROLL_CONTAINER_ID) || undefined
          }
          readOnly={readOnly}
          ref={editorRef}
        />
        {withBottomToolbar && (
          <ToolbarWrapper readOnly={readOnly}>
            <EditorToolbar config={config} toolbarName={toolbarName} />
          </ToolbarWrapper>
        )}
      </Container>
    );
  }
}

export default Editor;
