/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { Field } from "react-final-form";

type Props = {
  when: string;
  is: string | string[] | number | number[] | boolean;
  children: React.ReactNode;
  leaveInDom?: boolean;
};

function Condition({ when, is, children, leaveInDom }: Props) {
  const alt = leaveInDom ? <div hidden>{children}</div> : null;
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => {
        if (Array.isArray(is)) {
          return is.includes(value) ? children : alt;
        }
        if (Array.isArray(value)) {
          return value.includes(is) ? children : alt;
        }
        return value === is ? children : alt;
      }}
    </Field>
  );
}

export default Condition;
