/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import DefaultDatePicker from "react-datepicker";
import { FieldInputProps } from "react-final-form";

import "react-datepicker/dist/react-datepicker.min.css";

import { Container, Error } from "./styles";

export type TimePickerExternalProps = {
  timeIntervals?: number;
  disabled?: boolean;
  placeholder?: string;
  placement?: string;
  minTime?: Date;
  maxTime?: Date;
  fullWidth?: boolean;
  maxWidth?: number;
};

type Props = {
  onChange: (date: Date) => void;
  error?: string;
  input: FieldInputProps<Date>;
} & TimePickerExternalProps;

@observer
export default class TimePicker extends React.Component<Props> {
  get sizeStyles() {
    const { fullWidth, maxWidth: maxWidthValue } = this.props;

    const maxWidth = maxWidthValue ? `${maxWidthValue}px` : maxWidthValue;

    return { fullWidth, maxWidth };
  }

  onChange = (date: Date) => {
    this.props.onChange(date);
  };

  render() {
    const {
      timeIntervals,
      disabled,
      placeholder,
      error,
      input,
      placement,
      minTime,
      maxTime,
      fullWidth,
    } = this.props;

    return (
      <Container
        fullWidth={fullWidth}
        size={this.sizeStyles}
        disabled={disabled}
        hasError={error}
      >
        <DefaultDatePicker
          {...input}
          selected={input?.value}
          onChange={this.onChange}
          placeholderText={placeholder}
          disabled={disabled}
          popperPlacement={placement}
          minTime={minTime}
          maxTime={maxTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={timeIntervals ?? 15}
          dateFormat="h:mm aa"
          autoComplete={"off"}
          popperModifiers={{
            flip: {
              boundariesElement: "scrollParent",
            },
          }}
        />
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
}
