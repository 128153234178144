/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI24Flask(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        fill="#919BB8"
        d="M8.554 16h6.892L14 12.385 14.02 10h-4.003L10 12.385 8.554 16zm-.8 2l-.8 2 10.098.015L16.246 18H7.754zM16 10v2l3.257 8.143c.41 1.025-.152 1.857-1.26 1.857H6.003c-1.106 0-1.668-.835-1.259-1.857L8 12v-2h-.504A.995.995 0 016.5 9c0-.552.445-1 .996-1h9.008c.55 0 .996.444.996 1 0 .552-.445 1-.996 1H16zm-2-6a1 1 0 110-2 1 1 0 010 2zm-3.5 3a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
      />
    </svg>
  );
}

export default SvgI24Flask;
