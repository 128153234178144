/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";
import styledWrapper from "common/components/StyledWrapper";

import { LogoBlue } from "common/components/Logo";
import { Container as RowContainer } from "common/components/Form/Row/styles";
import { SeparatorHorizontal } from "common/components/Separator";

import { MEDIA_QUERIES } from "constants/layout";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100%;
`;

export const Content = styled.div`
  padding: 50px 60px 60px 60px;

  max-width: 392px;

  ${MEDIA_QUERIES.MOBILE} {
    max-width: 310px;

    padding: 15px 25px 25px 25px;

    ${RowContainer} {
      margin-bottom: 10px;
    }
  }
`;

export const Logo = styledWrapper(LogoBlue)`
  margin-bottom: 21px;
`;

export const Separator = styledWrapper(SeparatorHorizontal)`
  margin-bottom: 21px;
`;
