/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { format } from "date-fns";

export const convertSecondsToTime = (timeInSeconds: number): string => {
  const seconds = timeInSeconds % 60;

  const ss = seconds < 10 ? `0${seconds}` : seconds;
  const mm = Math.floor(timeInSeconds / 60);
  return `${mm}:${ss}`;
};

export const formattedTime = (
  date: Date,
  fullTime: boolean = false,
): string => {
  const hours = format(date, "h");
  const minutes = format(date, "mm");
  const midday = format(date, "a");

  return fullTime || minutes !== "00"
    ? `${hours}:${minutes} ${midday}`
    : `${hours} ${midday}`;
};
