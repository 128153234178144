/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const PHONE_PLACEHOLDER = {
  SHORT: "-",
  LONG: "+1(XXX)-XXX-XXXX",
};

export function formatPhone(
  value?: string,
  placeholder?: string,
): string | null {
  if (!value) return placeholder ?? PHONE_PLACEHOLDER.SHORT;

  const valueToFormat = value.charAt(0) === "+" ? value.substring(1) : value;

  if (/^\d{11}$/.test(valueToFormat)) {
    const matches = valueToFormat.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (matches) {
      return `+${matches[1]}(${matches[2]})-${matches[3]}-${matches[4]}`;
    }
  }

  return value;
}
