/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldRenderProps } from "react-final-form";

import TextInput, {
  TextInputExternalProps,
} from "common/components/Input/TextInput";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

type Props = {
  title?: string;
  required?: boolean;
  action?: React.ReactNode;
  shifted?: boolean;
} & TextInputExternalProps;

function TextFieldControl({
  title,
  required,
  action,
  input,
  meta,
  small,
  shifted,
  actionShiftedLeft,
  error,
  ...props
}: FieldRenderProps<any> & Props) {
  return (
    <Field
      title={title}
      required={required}
      action={action}
      shifted={shifted}
      actionShiftedLeft={actionShiftedLeft}
    >
      <TextInput
        input={input}
        error={(meta.touched && meta.error) || error}
        small={small}
        {...props}
      />
    </Field>
  );
}

export default withFieldControl(TextFieldControl);
