/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

import { ContactInformationModel } from "models/ContactInformationModel";

export const StudyStatuses = ["Published", "Draft"];

export type StudyStatus = "Published" | "Draft";

export type DomainStudyStatus = "Verified" | "NotVerified";

export enum StatusNames {
  PUBLISHED = "Published",
  DRAFT = "Draft",
}

export enum DomainStatusNames {
  VERIFIED = "Verified",
  NOT_VERIFIED = "NotVerified",
}

export enum InvitationTriggerType {
  SCREENED = "Screened",
  ELIGIBLE = "Eligible",
  ENROLLED = "Enrolled",
  ASSIGNED_ARM = "AssignedArm",
}

const Arm = types.model({
  id: types.number,
  name: types.string,
  inviteParticipantIfAssigned: types.maybe(types.boolean),
});

export const Timezone = types.model("Timezone", {
  id: types.number,
  name: types.string,
});

export const Feature = types.model("Feature", {
  id: types.number,
  name: types.string,
  isEnabled: types.maybe(types.boolean),
});

export const License = types.model({
  licenceStartDate: types.string,
  licenceExpirationDate: types.string,
  periodInMonth: types.number,
  status: types.string,
  name: types.maybe(types.string),
  id: types.number,
});

export const Domain = types
  .model("Domain", {
    name: types.string,
    status: types.enumeration(Object.values(DomainStatusNames)),
  })
  .views(self => ({
    get isVerified() {
      return self.status === DomainStatusNames.VERIFIED;
    },
  }));

export const Study = types
  .model({
    id: types.number,
    arms: types.array(Arm),
    domains: types.array(Domain),
    created: types.string,
    updated: types.string,
    setupDate: types.maybe(types.string),
    publishDate: types.maybe(types.string),
    publishStatus: types.enumeration(Object.values(StatusNames)),
    fullName: types.maybe(types.string),
    shortName: types.maybe(types.string),
    licence: types.maybe(License),
    customer: types.maybe(ContactInformationModel),
    email: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
    timeZone: types.maybe(Timezone),
    isMobileAppSupported: types.boolean,
    invitationTriggerType: types.maybe(
      types.enumeration(Object.values(InvitationTriggerType)),
    ),
    features: types.array(Feature),
  })
  .views(self => ({
    get isDraft() {
      return self.publishStatus === StatusNames.DRAFT;
    },
    get isPublished() {
      return self.publishStatus === StatusNames.PUBLISHED;
    },
  }));

export const StudyItem = types.model({
  study: Study,
  sitesCount: types.number,
  armsCount: types.number,
  participantsCount: types.number,
  staffCount: types.number,
});
