/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import api from "common/services/api";

import { createCustomReadonlyDictionary } from "stores/templates/ReadonlyDictionaryStore";

import { Visit } from "./SelfScheduleStore";

const SelfScheduleVisitsDictionary = createCustomReadonlyDictionary({
  itemModel: Visit,
  fetch: async function() {
    return api.get(`/self-schedule/upcoming-visits/list`);
  },
  getValue: value => value,
  getLabel: ({ name }) => name,
});

export default SelfScheduleVisitsDictionary.create();
