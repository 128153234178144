/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldRenderProps } from "react-final-form";

import Dropdown, {
  DropdownExternalProps,
} from "common/components/Input/Dropdown";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

import { DictionaryItem } from "common/types/models";

type Props = {
  title?: string;
  required?: boolean;
  name: string;
  shifted?: boolean;
  onChangeCallback?: (newValue: DictionaryItem | DictionaryItem[]) => void;
  calculateChangeValue?: (items: DictionaryItem[]) => any;
} & DropdownExternalProps;

class DropdownFieldControl extends React.Component<
  FieldRenderProps<any> & Props
> {
  onMultiChange = (items: DictionaryItem[] = []) => {
    const {
      input: { onChange },
      onChangeCallback,
      calculateChangeValue,
    } = this.props;

    const value = calculateChangeValue
      ? calculateChangeValue(items)
      : items.map(item => item.value);
    const event: any = { target: { value } };
    onChange(event);
    onChangeCallback && onChangeCallback(items);
  };

  onChange = (item: DictionaryItem) => {
    const {
      input: { onChange },
      onChangeCallback,
    } = this.props;

    const event: any = { target: { value: item?.value } };
    onChange(event);
    onChangeCallback && onChangeCallback(item);
  };

  render() {
    const {
      action,
      actionShiftedLeft,
      title,
      required,
      input,
      meta,
      multi,
      validate,
      shifted,
      ...props
    } = this.props;
    // TODO use suitable type
    return (
      <Field
        title={title}
        actionShiftedLeft={actionShiftedLeft}
        required={required}
        shifted={shifted}
        action={action}
      >
        <Dropdown
          input={input}
          onChange={multi ? this.onMultiChange : this.onChange}
          error={meta.touched && meta.error}
          multi={multi}
          {...props}
        />
      </Field>
    );
  }
}

export default withFieldControl(DropdownFieldControl);
