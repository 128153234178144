/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
const ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_SEND_LINK: "/forgot-password/send-link",
  REGISTER: "/register",
  SELF_SCHEDULE: "/scheduling/:id/self-schedule",
  CHANGE_EMAIL: "/change-email",
  EMAIL_CHANGE_CONFIRM: "/email-change-confirm",
  EMAIL_CONFIRM: "/confirm-email",
  LOGIN_BY_ROLE: "/login-by-role",
  SUPER_ADMIN: "/super-admin",
  CUSTOMER_ADMIN: "/customer-admin",
  STUDY_ADMIN: "/study-admin",
  LOCATOR_MANAGER: "/locator-manager",
};

export default ROUTES;
