/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { Container } from "./styles";

type Props = {
  children: React.ReactNode;
};

function Row({ children }: Props) {
  return <Container>{children}</Container>;
}

export default Row;
