/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

import { DictionaryItemModel } from "./common";

export const AuthModel = types.model("AuthModel", {
  accessToken: types.maybe(types.string),
  accessTokenType: types.maybe(types.string),
  accessTokenExpiresIn: types.number,
  refreshToken: types.maybe(types.string),
  refreshTokenExpiresIn: types.number,
});

export const AuthDashboardModel = types.model("LoginOptionsModel", {
  system: types.maybe(
    types.model({
      title: types.string,
      role: types.maybe(DictionaryItemModel),
      roles: types.array(DictionaryItemModel),
    }),
  ),
  spaces: types.maybe(
    types.array(
      types.model({
        id: types.number,
        title: types.string,
        role: DictionaryItemModel,
      }),
    ),
  ),
  studies: types.maybe(
    types.array(
      types.model({
        customer: DictionaryItemModel,
        study: DictionaryItemModel,
        roles: types.array(
          types.model({
            role: DictionaryItemModel,
            site: types.maybe(DictionaryItemModel),
          }),
        ),
      }),
    ),
  ),
});
