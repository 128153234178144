/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import SvgI16Loader from "common/assets/icons/I16Loader";

import { Button, Counter, IconContainer, Icon, Text } from "./styles";

type Props = {
  onClick?: () => void;
  icon: React.ReactNode;
  isRight?: boolean;
  text?: string;
  isGreyed?: boolean;
  isRed?: boolean;
  shouldStopPropagation?: boolean;
  className?: any;
  disabled?: boolean;
  disabledColor?: string;
  counter?: number;
  minimal?: boolean;
  noWrap?: boolean;
  isLoading?: boolean;
  isMenuButton?: boolean;
  menuIcon?: React.ReactNode;
};

type IconContainerProps = {
  icon: React.ReactNode;
  counter?: number;
  minimal?: boolean;
};

const IconWithCounter = ({ minimal, icon, counter }: IconContainerProps) => {
  return (
    <IconContainer>
      <Icon minimal={minimal}>{icon}</Icon>
      {counter && counter > 0 ? <Counter>{counter}</Counter> : null}
    </IconContainer>
  );
};

function IconButton({
  onClick,
  icon,
  text,
  isRight,
  isGreyed,
  isRed,
  shouldStopPropagation,
  className,
  disabled,
  disabledColor,
  counter,
  minimal,
  noWrap,
  isLoading,
  isMenuButton,
  menuIcon,
}: Props) {
  const onClickHandle = (e: { [key: string]: any }) => {
    if (shouldStopPropagation) {
      e.stopPropagation();
    }
    onClick && onClick();
  };

  return (
    <Button
      className={className}
      onClick={onClickHandle}
      isGreyed={isGreyed}
      isRed={isRed}
      disabled={disabled}
      disabledColor={disabledColor}
      minimal={minimal}
      isLoading={isLoading}
    >
      {isLoading && !isRight && <SvgI16Loader />}
      {!isLoading && !isRight && (
        <IconWithCounter icon={icon} counter={counter} minimal={minimal} />
      )}
      {text && (
        <Text noWrap={noWrap} isRight={isRight} isMenuButton={isMenuButton}>
          {text}
        </Text>
      )}
      {isLoading && isRight && <SvgI16Loader />}
      {!isLoading && isRight && (
        <IconWithCounter icon={icon} counter={counter} minimal={minimal} />
      )}
      {isMenuButton && menuIcon && <IconWithCounter icon={menuIcon} />}
    </Button>
  );
}

export default IconButton;
