/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import ConfirmationDialogDefault from "common/components/ConfirmationDialog";
import { ButtonWrapper } from "common/components/ConfirmationDialog/style";

export const ConfirmationDialog = styled(ConfirmationDialogDefault)`
  ${ButtonWrapper} {
    width: 195px;
  }
`;
