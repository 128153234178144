/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

import { DictionaryItem } from "common/types/models";

export const SimpleModel = types.model("SimpleModel", {
  id: types.number,
  name: types.maybe(types.string),
});

export const DictionaryItemModel = types
  .model("DictionaryItemModel", {
    id: types.number,
    name: types.maybe(types.string),
  })
  .views(self => ({
    get asDictionaryItem(): DictionaryItem {
      return { label: self.name || "", value: `${self.id}` };
    },
  }));

export const Employee = types.model("Employee", {
  id: types.maybe(types.number),
  name: types.maybe(types.string),
  isInactive: types.maybe(types.boolean),
  role: types.maybe(types.string),
  email: types.maybe(types.string),
  status: types.maybe(types.string),
});
