/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { types } from "mobx-state-tree";

import { DictionaryItemModel } from "./common";

import { formatPhone } from "common/utils/phoneUtils";

export const ContactModel = types.model("ContactModel", {
  primaryContact: types.maybe(types.string),
  phoneOffice: types.maybe(types.string),
  phoneCell: types.maybe(types.string),
  email: types.maybe(types.string),
});

export const ContactInformationModel = types
  .model("ContactInformationModel", {
    id: types.number,
    name: types.maybe(types.string),
    created: types.maybe(types.string),
    state: types.maybe(DictionaryItemModel),
    city: types.maybe(types.string),
    zipCode: types.maybe(types.string),
    address1: types.maybe(types.string),
    address2: types.maybe(types.string),
    contact: types.maybe(ContactModel),
  })
  .views(self => ({
    get formValues() {
      const contact = {
        ...self.contact,
        phoneCell: self.contact?.phoneCell
          ? formatPhone(self.contact.phoneCell)
          : undefined,
        phoneOffice: self.contact?.phoneOffice
          ? formatPhone(self.contact.phoneOffice)
          : undefined,
      };

      const stateId = self.state?.id;

      return { ...self, contact, stateId };
    },
  }));
