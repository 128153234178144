/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";
import LetterAvatarDefault from "common/components/LetterAvatar";
import IconButton from "common/components/Button/IconButton/IconButton";

export const Container = styled.div`
  margin-bottom: 24px;
`;

export const IconControlsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  display: none;

  transform: translate(-50%, -50%);

  z-index: 1;
`;

export const IconControl = styled(IconButton)`
  padding: 0px 7px;
`;

export const LetterAvatar = styled(LetterAvatarDefault)`
  margin: 0 auto;
  position: absolute;
`;

export const Label = styled.div`
  width: ${({ size }) => size ?? "162px"};
  height: ${({ size }) => size ?? "162px"};

  position: relative;

  margin: 0 auto;

  border-radius: 50%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    ${IconControlsContainer} {
      display: flex;
      flex-direction: row;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      border-radius: 50%;

      background-color: ${({ isEditingAvailable }) =>
        isEditingAvailable ? "rgba(255, 255, 255, 0.7)" : "transparent"};
    }
  }

  .filepicker {
    border-radius: 50%;
    border: none;
    background-color: transparent;

    width: 100%;
    height: ${({ size }) => size ?? "162px"};
    position: relative;
  }

  .dz-message {
    display: none;
  }

  .dropzone.dz-drag-hover {
    border: solid 2px #d8ddec;
  }

  .dropzone .dz-preview .dz-details .dz-filename:hover {
    display: none;
  }

  .dropzone .dz-preview.dz-error .dz-error-mark {
    display: none;
  }

  .dropzone .dz-preview.dz-error .dz-error-message {
    display: none;
  }

  .dropzone .dz-preview .dz-image {
    display: none;
  }

  .dz-details {
    display: none;
  }
`;

export const Error = styled.p`
  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 10px;

  text-align: center;
`;
