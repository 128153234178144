/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React, { ReactNode, Component } from "react";
import Title from "./Title";
import { Container, Wrapper, Content, Loader, WrapperTable } from "./styles";

export type Props = {
  title?: string;
  children: ReactNode;
  badge?: ReactNode;
  controls?: ReactNode;
  isLoading?: boolean;
  className?: any;
  dynamicTitle?: boolean;
  editableTitle?: boolean;
  onSubmitTitle?: (text: string) => void;
  dynamicHeight?: boolean;
};

export default class Page extends Component<Props> {
  render() {
    const {
      title,
      badge,
      controls,
      children,
      isLoading,
      className,
      dynamicTitle,
      dynamicHeight,
    } = this.props;
    const isTitleShow = dynamicTitle ? !isLoading : true;
    return (
      <Wrapper dynamicHeight={dynamicHeight}>
        <WrapperTable>
          <Container className={className}>
            {title && isTitleShow && (
              <Title title={title} badge={badge} controls={controls} />
            )}

            {isLoading ? <Loader /> : <Content>{children}</Content>}
          </Container>
        </WrapperTable>
      </Wrapper>
    );
  }
}
