/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { FieldInputProps } from "react-final-form";

import SvgI16Attention from "common/assets/icons/I16Attention";
import SvgI16Dropdown, {
  SvgI16DropdownRotated,
} from "common/assets/icons/I16Dropdown";

import { MaskProperties } from "common/constants/forms";

import {
  Container,
  Wrapper,
  Input,
  Label,
  Note,
  NoteText,
  Error,
  IconButton,
  InputBorder,
  IconContainer,
} from "./styles";

export type NumericInputExternalProps = {
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  autoComplete?: string;
  mask?: MaskProperties;
  label?: string;
  note?: string;
  min?: number;
  max?: number;
  canStretchOnError?: boolean;
  maxWidth?: number;
  withoutControls?: boolean;
};

type Props = {
  error?: string;
  input: FieldInputProps<number>;
} & NumericInputExternalProps;

@observer
class NumericInput extends React.Component<Props> {
  @computed
  get parsedValue() {
    const {
      input: { value },
    } = this.props;
    return value ? (typeof value === "string" ? parseFloat(value) : value) : 0;
  }

  @computed
  get min(): number | undefined {
    const { min } = this.props;
    return min !== undefined && typeof min === "number" ? min : undefined;
  }

  @computed
  get max(): number | undefined {
    const { max } = this.props;
    return max !== undefined && typeof max === "number" ? max : undefined;
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {
      input: { onChange },
    } = this.props;

    const value = e.currentTarget.value;
    const parsedValue = isNaN(parseFloat(value))
      ? value === "-"
        ? value
        : ""
      : parseFloat(value);
    onChange(this.getValue(parsedValue));
  };

  getValue = (value: number | string) => {
    if (this.min !== undefined && value <= this.min) {
      return this.min;
    }

    if (this.max !== undefined && value >= this.max) {
      return this.max;
    }

    return value;
  };

  incValue = (delta: number) => {
    const {
      input: { onChange },
    } = this.props;

    onChange(this.getValue(this.parsedValue + delta));
  };

  onDecClick = () => {
    this.incValue(-1);
  };

  onIncClick = () => {
    this.incValue(1);
  };

  render() {
    const {
      error,
      disabled,
      placeholder,
      type,
      required,
      autoComplete,
      mask,
      input,
      label,
      note,
      canStretchOnError,
      maxWidth,
      withoutControls,
    } = this.props;
    return (
      <Container>
        <Wrapper>
          <InputBorder maxWidth={maxWidth}>
            <Input
              {...input}
              disabled={disabled}
              placeholder={placeholder}
              onChange={this.onChange}
              hasError={error}
              type={type}
              required={required}
              autoComplete={autoComplete}
              withoutControls={withoutControls}
              {...mask}
            />

            {!withoutControls && (
              <IconContainer>
                <IconButton
                  isGreyed
                  icon={<SvgI16DropdownRotated />}
                  onClick={this.onIncClick}
                  disabled={disabled || this.parsedValue === this.max}
                  minimal
                />
                <IconButton
                  isGreyed
                  icon={<SvgI16Dropdown />}
                  onClick={this.onDecClick}
                  disabled={disabled || this.parsedValue === this.min}
                  minimal
                />
              </IconContainer>
            )}
          </InputBorder>
          {label && <Label>{label}</Label>}
          {note && (
            <Note>
              <SvgI16Attention />
              <NoteText>{note}</NoteText>
            </Note>
          )}
        </Wrapper>
        {error && <Error isStatic={canStretchOnError}>{error}</Error>}
      </Container>
    );
  }
}

export default NumericInput;
