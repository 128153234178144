/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

import DefaultIconButton from "common/components/Button/IconButton";

import { COLORS } from "common/constants/layout";

const activeSlotStyle = css`
  background-color: rgba(86, 130, 246, 0.12);
  color: #5682f6;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 40px;
`;

export const Header = styled.div``;

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderBottom = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 12px;
`;

export const IconButton = styled(DefaultIconButton)``;

export const MonthTitle = styled.div`
  margin: 0 24px;

  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: bold;
  color: #37383d;
`;

export const Slot = styled.div`
  padding: 6px 14px;

  border-radius: 6px;

  font-size: 16px;
  color: #37383d;

  cursor: pointer;

  &:hover {
    ${activeSlotStyle}
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${activeSlotStyle}
    `};
`;

export const WeekDayCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-basis: 100%;
  flex-grow: 1;

  ${({ isHeader }) =>
    isHeader &&
    css`
      justify-content: center;
    `};
`;

export const WeekDayName = styled.p`
  font-size: 14px;
  text-align: center;
  color: #919bb8;
`;

export const WeekDayNumber = styled.p`
  font-size: 16px;
  text-align: center;
  color: #37383d;
`;

export const WeekTable = styled.div`
  display: flex;

  width: 100%;

  margin-top: 12px;
`;

export const WeekTableHeader = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 70px;
  border-radius: 6px;
  background-color: rgba(169, 180, 201, 0.1);
`;

export const WeekTitle = styled.div`
  display: flex;
  align-items: center;

  margin: 0 14px;

  font-size: 14px;
  font-weight: 600;
  color: #a9b4c9;
`;

export const WeekNavigation = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  & > svg > path,
  & > svg > use {
    fill: ${COLORS.TEXT_LIGHT};
  }

  &:hover {
    ${WeekTitle} {
      color: #5682f6;
    }

    & > svg > path,
    & > svg > use {
      fill: #5682f6;
    }
  }
`;
