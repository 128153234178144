/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";

import { PossibilitiesType } from "stores/ChangeEmailStore";

import { Condition, Row } from "common/components/Form";
import FormControl, {
  RadioButtonGroupFieldControl,
} from "common/components/FormControl";
import { ButtonBlue } from "common/components/Button";

import { SEND_TYPES } from "common/constants/changeEmail";

import { Form, Error, Text, ButtonSubmit } from "../styles";
import { PhoneNumber, PhoneNumberGroup, SmallText } from "./styles";

type Props = {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  error: string;
  possibilities?: PossibilitiesType;
};

@observer
class GetPinCodeForm extends React.Component<Props> {
  get phoneNumberLabel() {
    const { possibilities } = this.props;

    if (possibilities?.phones?.length === 1) {
      return `Phone number (${possibilities?.phones[0].maskedPhoneNumber})`;
    } else {
      return "Phone number";
    }
  }

  get phoneNumbers() {
    return this.props.possibilities?.phones.map(phone => ({
      label: phone.maskedPhoneNumber,
      value: phone.id,
    }));
  }

  get phonesLength() {
    return this.props.possibilities?.phones.length ?? 0;
  }

  render() {
    const { onSubmit, possibilities, error } = this.props;

    return (
      <FormControl
        onSubmit={onSubmit}
        initialValues={{ sendType: SEND_TYPES.EMAIL }}
      >
        <Form>
          {this.phonesLength ? (
            <>
              <Row>
                <Text>
                  To verify your identity, we&apos;ll send you a PIN code for
                  email update. Please select the way you would like to get the
                  confirmation code.
                </Text>
              </Row>
              <Row>
                <PhoneNumber>
                  <RadioButtonGroupFieldControl
                    name="sendType"
                    options={[
                      {
                        label: `Email (${possibilities?.email})`,
                        value: SEND_TYPES.EMAIL,
                      },
                      {
                        label: this.phoneNumberLabel,
                        value: SEND_TYPES.PHONE,
                      },
                    ]}
                    required
                  />
                  {this.phonesLength > 1 && (
                    <Condition when="sendType" is={SEND_TYPES.PHONE}>
                      <PhoneNumberGroup>
                        <SmallText>
                          There are {this.phonesLength} phone numbers related to
                          your SMART account. Please select the one to send the
                          PIN code:
                        </SmallText>
                        <RadioButtonGroupFieldControl
                          name="phoneId"
                          options={this.phoneNumbers}
                          required
                        />
                      </PhoneNumberGroup>
                    </Condition>
                  )}
                </PhoneNumber>
              </Row>
            </>
          ) : (
            <Row>
              <Text>
                To verify your identity, we’ll send you an email with PIN code
                to {possibilities?.email}.
              </Text>
            </Row>
          )}

          {error && (
            <Row>
              <Error>{error}</Error>
            </Row>
          )}
          <ButtonSubmit render={this.renderButton} />
        </Form>
      </FormControl>
    );
  }

  renderButton = ({ onClick }: { onClick: () => void }) => (
    <ButtonBlue onClick={onClick} isLoading={this.props.isLoading}>
      Get code
    </ButtonBlue>
  );
}

export default GetPinCodeForm;
