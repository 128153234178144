/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const DASHBOARD_TYPE = {
  LOGIN: "LoginDashboard",
  SUPER_ADMIN: "SuperAdminDashboard",
  CUSTOMER_ADMIN: "CustomerAdminDashboard",
  STUDY_ADMIN: "StudyAdminDashboard",
  STI_LOCATOR: "StiLocatorDashboard",
};

export const DASHBOARD_TYPES = Object.values(DASHBOARD_TYPE);
