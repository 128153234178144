/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observable } from "mobx";
import { observer } from "mobx-react";

import AuthStore from "stores/Auth";

import Background from "common/components/Layout/Background/BackgroundBlue";
import Paper from "common/components/Paper";

import LoginForm from "./LoginForm";

import ROUTES from "navigation/routes";

import { DASHBOARD_TYPE } from "constants/dashboard";

import { AuthData } from "common/types/models";

import { Container, Content, Logo, Separator } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class Login extends React.Component<Props> {
  @observable serverError: string = "";

  signIn = async (login: string, password: string) => {
    this.serverError = "";
    try {
      await AuthStore.signIn(login, password);
      return true;
    } catch (error) {
      this.serverError = error.message;
      return false;
    }
  };

  navigateToNextScreen = () => {
    switch (AuthStore.dashboard) {
      case DASHBOARD_TYPE.LOGIN:
        this.props.history.push(ROUTES.LOGIN_BY_ROLE);
        break;
      case DASHBOARD_TYPE.SUPER_ADMIN:
        this.props.history.push(ROUTES.SUPER_ADMIN);
        break;
      case DASHBOARD_TYPE.CUSTOMER_ADMIN:
        this.props.history.push(ROUTES.CUSTOMER_ADMIN);
        break;
      case DASHBOARD_TYPE.STUDY_ADMIN:
        this.props.history.push(ROUTES.STUDY_ADMIN);
        break;
      case DASHBOARD_TYPE.STI_LOCATOR:
        this.props.history.push(ROUTES.STUDY_ADMIN);
        break;
    }
  };

  onSubmit = async ({ login, password }: AuthData) => {
    if (await this.signIn(login, password)) {
      this.navigateToNextScreen();
    }
  };

  onForgotPassword = () => {
    this.props.history.push(ROUTES.FORGOT_PASSWORD_SEND_LINK);
  };

  render() {
    return (
      <Container>
        <Background />
        <Paper>
          <Content>
            <Logo />
            <Separator />
            <LoginForm
              onSubmit={this.onSubmit}
              serverError={this.serverError}
              isLoading={AuthStore.isLoading}
              onForgotPassword={this.onForgotPassword}
            />
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(Login);
