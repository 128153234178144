/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
export const ADHOC_ACTIVITY_TYPES = {
  ON_DATE: "Date",
  TRIGGER_BASED: "Trigger",
};

export const ADHOC_ACTIVITY_TYPES_TEXT = {
  ON_DATE: "On date",
  TRIGGER_BASED: "Trigger-based",
};
