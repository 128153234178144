/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { FieldRenderProps } from "react-final-form";

import { DictionaryItem } from "common/types/models";

import RadioButtonGroup from "common/components/RadioButtonGroup";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

type Props = {
  title?: string;
  required?: boolean;
  inline?: boolean;
  disabled?: boolean;
} & FieldRenderProps<any>;

class RadioButtonGroupFieldControl extends React.Component<Props> {
  onChange = (item: DictionaryItem) => {
    const event: any = {
      target: { value: item.value },
    };
    this.props.input.onChange(event);
  };

  get selected() {
    const { input } = this.props;
    return { label: input.name, value: input.value };
  }

  render() {
    const { options, title, required, inline, disabled } = this.props;

    const Control = (
      <RadioButtonGroup
        options={options}
        onChange={this.onChange}
        selected={this.selected}
        inline={inline}
        disabled={disabled}
      />
    );

    return title ? (
      <Field title={title} required={required}>
        {Control}
      </Field>
    ) : (
      Control
    );
  }
}

export default withFieldControl(RadioButtonGroupFieldControl);
