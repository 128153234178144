/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import {
  Switch as SwitchContext,
  Redirect,
  Route,
  RouteProps,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { observer } from "mobx-react";

import AuthStore from "stores/Auth";

import Loadable from "common/components/Loadable";

import Login from "common/pages/Login";
import Register from "common/pages/Register";
import LoginByRole from "common/pages/LoginByRole";

import ForgotPassword from "common/pages/Login/ForgotPassword";
import ResetPassword from "common/pages/Login/ResetPassword";
import SelfSchedule from "common/pages/SelfSchedule";
import ChangeEmail from "common/pages/ChangeEmail";
import EmailChangeConfirm from "common/pages/EmailChangeConfirm";
import EmailConfirm from "common/pages/EmailConfirm";

import { AdminLoaderLayout } from "./LazyLoad";
import GlobalNavigationContext from "./context";

import ROUTES from "./routes";

import { ROLE_TYPE, ROLE_TYPES } from "constants/roles";

import {
  TIMEOUT_AUTO_LOGOUT,
  TIMEOUT_AUTO_LOGOUT_SHOW_POPUP,
} from "constants/autoLogout";

import AutoLogout from "common/services/autoLogout";

import { getRouteByRole } from "common/utils/auth";

/* @ts-ignore */
const SuperAdmin = Loadable(() => import("superAdmin/entry"), {
  LoaderLayout: AdminLoaderLayout,
});
/* @ts-ignore */
const CustomerAdmin = Loadable(() => import("customerAdmin/entry"), {
  LoaderLayout: AdminLoaderLayout,
});
/* @ts-ignore */
const StudyAdmin = Loadable(() => import("studyAdmin/entry"), {
  LoaderLayout: AdminLoaderLayout,
});
/* @ts-ignore */
const LocatorManager = Loadable(() => import("locatorManager/entry"), {
  LoaderLayout: AdminLoaderLayout,
});

interface PrivateRouteProps extends RouteProps {
  authRoles: Array<string>;
  redirectPath: string;
}

const PrivateRoute = ({
  authRoles,
  redirectPath,
  ...rest
}: PrivateRouteProps) => {
  return authRoles.includes(AuthStore.authRole) ? (
    <Route {...rest} />
  ) : (
    <Redirect to={redirectPath} />
  );
};

@observer
class Switch extends React.Component<RouteComponentProps> {
  get navigationContext() {
    return {
      navigateToLogin: this.navigateToLogin,
      navigateToLoginByRole: this.navigateToLoginByRole,
    };
  }

  navigateToLogin = () => {
    this.props.history.push(ROUTES.LOGIN);
  };

  navigateToLoginByRole = () => {
    this.props.history.push(ROUTES.LOGIN_BY_ROLE);
  };

  render() {
    return (
      <GlobalNavigationContext.Provider value={this.navigationContext}>
        <AutoLogout
          timeoutAutoLogout={TIMEOUT_AUTO_LOGOUT}
          timeoutShowPopup={TIMEOUT_AUTO_LOGOUT_SHOW_POPUP}
        />
        <SwitchContext>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route
            exact
            path={ROUTES.FORGOT_PASSWORD_SEND_LINK}
            component={ForgotPassword}
          />
          <Route
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={ResetPassword}
          />
          <Route exact path={ROUTES.REGISTER} component={Register} />
          <Route exact path={ROUTES.SELF_SCHEDULE} component={SelfSchedule} />
          <Route exact path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route
            exact
            path={ROUTES.EMAIL_CHANGE_CONFIRM}
            component={EmailChangeConfirm}
          />
          <Route exact path={ROUTES.EMAIL_CONFIRM} component={EmailConfirm} />

          <PrivateRoute
            exact
            path={ROUTES.LOGIN_BY_ROLE}
            component={LoginByRole}
            authRoles={ROLE_TYPES}
            redirectPath={ROUTES.LOGIN}
          />
          <PrivateRoute
            path={ROUTES.SUPER_ADMIN}
            component={SuperAdmin}
            authRoles={[ROLE_TYPE.SUPER_ADMIN]}
            redirectPath={ROUTES.LOGIN_BY_ROLE}
          />
          <PrivateRoute
            path={ROUTES.CUSTOMER_ADMIN}
            component={CustomerAdmin}
            authRoles={[ROLE_TYPE.CUSTOMER_ADMIN]}
            redirectPath={ROUTES.LOGIN_BY_ROLE}
          />
          <PrivateRoute
            path={ROUTES.STUDY_ADMIN}
            component={StudyAdmin}
            authRoles={[
              ROLE_TYPE.STUDY_ADMIN,
              ROLE_TYPE.STUDY_COORDINATOR,
              ROLE_TYPE.SITE_ADMIN,
              ROLE_TYPE.SITE_STAFF,
              ROLE_TYPE.EVENT_STAFF,
              ROLE_TYPE.REPORTING,
            ]}
            redirectPath={ROUTES.LOGIN_BY_ROLE}
          />
          <PrivateRoute
            path={ROUTES.LOCATOR_MANAGER}
            component={LocatorManager}
            authRoles={[ROLE_TYPE.LOCATOR_MANAGER]}
            redirectPath={ROUTES.LOGIN_BY_ROLE}
          />

          <Redirect to={getRouteByRole(AuthStore.authRole)} />
        </SwitchContext>
      </GlobalNavigationContext.Provider>
    );
  }
}

export default withRouter(Switch);
