/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import styled, { css } from "styled-components";

import DefaultInputMask from "react-input-mask";

import styledWrapper from "common/components/StyledWrapper";

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputMask = ({ hasError, ...props }) => <DefaultInputMask {...props} />;

const StyledInputMask = styled(InputMask)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: ${({ small }) => (small ? "32px" : "48px")};

  padding: ${({ small }) => (small ? "0 8px" : "0 16px")};

  font-family: OpenSans-Regular;
  font-size: 16px;

  outline: none;

  border-radius: 7px;

  ${({ disabled }) =>
    disabled
      ? css`
          color: rgba(51, 51, 51, 0.4);
          background-color: #f0f2f7;
        `
      : css`
          color: #333333;
          background-color: transparent;
        `};

  ${({ hasError }) =>
    hasError
      ? css`
          border: solid 2px #f74a70;
        `
      : css`
          border: solid 2px #d8ddeb;
        `};

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }

  &:active,
  &:focus {
    ${({ hasError, readOnly }) =>
      !readOnly &&
      (hasError
        ? css`
            border: solid 2px #f74a70;
          `
        : css`
            border: solid 2px #5682f6;
          `)};
  }
`;

export const Input = styledWrapper(StyledInputMask)(css`
  min-width: 76px;
  width: 100%;
  height: 48px;
  position: relative;
`);

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;

  color: #f74a70;

  margin-top: ${({ small }) => (small ? "-6px" : "4px")};
  margin-bottom: ${({ small }) => (small ? "8px" : "0px")};
`;

export const Label = styled.span`
  font-size: 14px;
  color: #919bb8;
  margin-left: 8px;
`;

export const Note = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

export const NoteText = styled.span`
  font-size: 14px;
  color: #5682f6;
  margin-left: 6px;
  white-space: nowrap;
`;
