/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import { createCustomReadonlyDictionary } from "stores/templates/ReadonlyDictionaryStore";

import api from "common/services/api";

import { TimeZoneCustom } from "models/TimeZoneCustom";

const ManageTimeZonesDictionary = createCustomReadonlyDictionary({
  itemModel: TimeZoneCustom,
  fetch: async function() {
    return api.get(`/timezone/custom/list`);
  },
  getValue: ({ value }) => value,
  getLabel: ({ text }) => text,
}).views(self => ({
  getOriginalTimezoneByValue(value?: string) {
    return self.items.find(timezone => timezone.value === value);
  },
  getOriginalTimezoneByText(text?: string) {
    return self.items.find(timezone => timezone.text === text);
  },
  getOriginalTimezoneByPartOfText(partOftext?: string, timezoneIANA?: string) {
    const timezoneByPartOftext = self.items.find(timezone =>
      timezone.text.includes(partOftext),
    );

    const timezonByIANA = timezoneIANA
      ? self.items.find(timezone =>
          timezone.utc.find((timezoneIANACurrent: string) =>
            timezoneIANACurrent.includes(timezoneIANA),
          ),
        )
      : undefined;

    const timezoneByPartOfTextAndIANA = timezoneIANA
      ? self.items.find(
          timezone =>
            timezone.text.includes(partOftext) &&
            timezone.utc.find((timezoneIANACurrent: string) =>
              timezoneIANACurrent.includes(timezoneIANA),
            ),
        )
      : undefined;

    return timezoneByPartOfTextAndIANA || timezonByIANA || timezoneByPartOftext;
  },
}));

export default ManageTimeZonesDictionary.create();
