/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
const APP_ENV_FIELD = "REACT_APP_ENV";
const API_URL_FIELD = "REACT_APP_API_URL";

const ENVIRONMENTS = {
  DEVELOPMENT: "development",
  TEST: "test",
  EC2_DEVELOPMENT: "ec2-dev",
  EC2_STAGING: "ec2-staging",
  EC2_PRODUCTION: "ec2-production",
};

export const environmentName = process.env[APP_ENV_FIELD];

export const isProduction = environmentName === ENVIRONMENTS.EC2_PRODUCTION;

export const apiUrl = process.env[API_URL_FIELD];
