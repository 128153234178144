/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";
import InputMask from "react-input-mask";

import styledWrapper from "common/components/StyledWrapper";

import SvgI24ChevronLeft from "common/assets/icons/i-24-chevron-left.svg";
import SvgI24ChevronLeftBlue from "common/assets/icons/i-24-chevron-left-blue.svg";
import SvgI24ChevronLeftDarkBlue from "common/assets/icons/i-24-chevron-left-darkblue.svg";
import SvgI24ChevronRight from "common/assets/icons/i-24-chevron-right.svg";
import SvgI24ChevronRightBlue from "common/assets/icons/i-24-chevron-right-blue.svg";
import SvgI24ChevronRightDarkBlue from "common/assets/icons/i-24-chevron-right-darkblue.svg";

import Dropdown from "common/components/Input/Dropdown";
import IconButtonDefault from "common/components/Button/IconButton";

import { MEDIA_QUERIES } from "constants/layout";

export const Container = styled.div`
  min-width: 134px;
  width: 100%;

  ${props =>
    props.fullWidth ? ".react-datepicker-wrapper {width: 100%  }" : ""}

  .react-datepicker {
    padding: 24px;

    ${MEDIA_QUERIES.SMALL_DESKTOP} {
      padding: 15px;
    }

    border-radius: 4px;
    border: solid 1px #d7d9e9;
  }

  .react-datepicker__header {
    border: none;
    background-color: transparent;
  }

  .react-datepicker__navigation {
    outline: none;
    width: 12px;
    height: 24px;
    border: none;
  }

  .react-datepicker__navigation--previous {
    background: url(${SvgI24ChevronLeft}) no-repeat;
    left: auto;
    right: 60px;
    top: 30px;

    &:hover {
      background: url(${SvgI24ChevronLeftBlue}) no-repeat;
    }

    &:active {
      background: url(${SvgI24ChevronLeftDarkBlue}) no-repeat;
    }
  }

  .react-datepicker__navigation--next {
    background: url(${SvgI24ChevronRight}) no-repeat;
    right: 30px;
    top: 30px;

    &:hover {
      background: url(${SvgI24ChevronRightBlue}) no-repeat;
    }

    &:active {
      background: url(${SvgI24ChevronRightDarkBlue}) no-repeat;
    }
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-names,
  .react-datepicker__month {
    margin: 0 -6px;
  }

  .react-datepicker__current-month {
    font-family: OpenSans-SemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #37383d;
    text-align: left;
    margin-bottom: 20px;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day-name {
    width: 32px;
    height: 32px;
    font-family: OpenSans-SemiBold;
    font-size: 14px;
    font-weight: 600;
    color: #919bb8;
    text-transform: uppercase;
    margin: 4px;
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    font-family: OpenSans-SemiBold;
    font-size: 14px;
    font-weight: 600;
    color: #37383d;
    background-color: transparent;
    outline: none;
    border: solid 2px transparent;
    margin: 4px;

    &:hover {
      border-radius: 50%;
      background-color: #f2f5ff;
    }
  }

  .react-datepicker__day--today {
    background-color: #f2f5ff;
    color: #5682f6;
    border-radius: 50%;
  }

  .react-datepicker__day--selected {
    border-color: rgba(86, 130, 246, 0.3);
    border-radius: 50%;
  }

  .react-datepicker__day--disabled {
    color: #919bb8;
  }

  .react-datepicker__day--outside-month {
    font-family: OpenSans;
    font-weight: 400;
    color: #919bb8;
  }
`;

export const CustomHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 5px;
`;

export const IconButton = styled(IconButtonDefault)`
  padding: 0px 5px;
`;

export const MonthDropdown = styledWrapper(Dropdown)`
    width: 136px;
`;

export const YearDropdown = styledWrapper(Dropdown)`
    margin-left: 10px;
    width: 84px;
`;

export const InputContainer = styled.div`
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const Input = styled(InputMask)`
  max-width: ${props => (props.fullWidth ? "100%" : "144px")};
  width: 100%;
  height: ${({ small }) => (small ? "32px" : "48px")};

  cursor: pointer;

  padding: ${({ small }) => (small ? "0 8px" : "0 16px")};

  color: ${({ disabled }) => (disabled ? "rgba(51, 51, 51, 0.4)" : "#333333")};
  background-color: ${props => (props.disabled ? "#f0f2f7" : "transparent")};

  font-family: OpenSans-Regular;
  font-size: 16px;

  outline: none;

  border-radius: 7px;
  border: ${props =>
    props.hasError ? "solid 2px #f74a70;" : "solid 2px #d8ddeb"};

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }

  &:active {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }

  &:focus {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }
`;

export const InputIconContainer = styled.div`
  display: flex;

  position: absolute;
  right: 13px;
  top: 50%;

  transform: translateY(-50%);

  cursor: pointer;
`;

export const Error = styled.p`
  position: absolute;

  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 4px;
`;
