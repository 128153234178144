/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { SnapshotOrInstance } from "mobx-state-tree";
import {
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  isEqual,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";

import SelfScheduleStore, {
  Location as LocationModel,
} from "stores/selfSchedule/SelfScheduleStore";

import SvgI16ArrowBack from "common/assets/icons/I16ArrowBack";
import SvgI16ArrowForward from "common/assets/icons/I16ArrowForward";
import SvgI24ChevronLeft from "common/assets/icons/I24ChevronLeft";
import SvgI24ChevronRight from "common/assets/icons/I24ChevronRight";

import Placeholder from "common/components/Placeholder";

import {
  Container,
  Header,
  HeaderTop,
  HeaderBottom,
  IconButton,
  MonthTitle,
  Slot,
  WeekNavigation,
  WeekTableHeader,
  WeekDayCell,
  WeekDayName,
  WeekDayNumber,
  WeekTable,
  WeekTitle,
} from "./styles";

type Props = {
  date: Date;
  onDateChange: (date: Date) => void;
  location: SnapshotOrInstance<typeof LocationModel>;
  selectedSlot: any;
  onSelectSlot: (slot?: any) => void;
  timezone?: string;
};

@observer
class Calendar extends React.Component<Props> {
  @observable slots: Array<any> = [];
  @observable selectedSlot = null;

  get date() {
    const { date } = this.props;
    return date;
  }

  get nextMonth() {
    return startOfMonth(addMonths(this.date, 1));
  }

  get prevMonth() {
    return startOfMonth(subMonths(this.date, 1));
  }

  get nextWeek() {
    return addWeeks(this.date, 1);
  }

  get prevWeek() {
    return subWeeks(this.date, 1);
  }

  get monthTitle() {
    return format(this.date, "LLL yyyy");
  }

  get interval() {
    return {
      start: startOfWeek(this.date),
      end: endOfWeek(this.date),
    };
  }

  get week() {
    return eachDayOfInterval(this.interval);
  }

  get isSomeSlots() {
    return this.slots.some(({ slots }) => slots.length);
  }

  componentDidMount() {
    this.fetchSlots();
  }

  componentDidUpdate(prevProps: Props) {
    const { date, location, timezone } = this.props;

    if (
      location.id !== prevProps.location.id ||
      timezone !== prevProps.timezone ||
      !isEqual(date, prevProps.date)
    ) {
      this.fetchSlots();
    }
  }

  fetchSlots = async () => {
    const { location } = this.props;

    this.slots = await SelfScheduleStore.fetchSlots({
      locationId: location.id,
      ...this.interval,
    });
  };

  onSelectSlot = (slot?: Date) => () => {
    const { onSelectSlot } = this.props;
    onSelectSlot(slot);
  };

  isSlotSelected = (slot: any) => {
    const { selectedSlot } = this.props;
    return slot === selectedSlot;
  };

  getWeekNavigateTitle = (day: Date) => {
    const start = startOfWeek(day);
    const end = endOfWeek(day);

    const startDay = format(start, "d");
    const startMonth = format(start, "LLL");
    const endDay = format(end, "d");
    const endMonth = format(end, "LLL");

    return isSameMonth(start, end)
      ? `${startDay} – ${endDay} ${startMonth}`
      : `${startDay} ${startMonth} – ${endDay} ${endMonth}`;
  };

  onNavigate = (date: Date) => () => {
    const { onSelectSlot, onDateChange } = this.props;

    onSelectSlot();
    onDateChange(date);
  };

  render() {
    return (
      <Container>
        <Header>
          <HeaderTop>
            <IconButton
              icon={<SvgI24ChevronLeft />}
              onClick={this.onNavigate(this.prevMonth)}
              isGreyed
            />
            <MonthTitle>{this.monthTitle}</MonthTitle>
            <IconButton
              icon={<SvgI24ChevronRight />}
              onClick={this.onNavigate(this.nextMonth)}
              isGreyed
            />
          </HeaderTop>
          <HeaderBottom>
            <WeekNavigation onClick={this.onNavigate(this.prevWeek)}>
              <SvgI16ArrowBack />
              <WeekTitle>{this.getWeekNavigateTitle(this.prevWeek)}</WeekTitle>
            </WeekNavigation>

            <WeekNavigation onClick={this.onNavigate(this.nextWeek)}>
              <WeekTitle>{this.getWeekNavigateTitle(this.nextWeek)}</WeekTitle>
              <SvgI16ArrowForward />
            </WeekNavigation>
          </HeaderBottom>
        </Header>
        <WeekTableHeader>
          {this.week.map(day => (
            <WeekDayCell key={day} isHeader>
              <WeekDayName>{format(day, "LLL")}</WeekDayName>
              <WeekDayNumber>{format(day, "d")}</WeekDayNumber>
            </WeekDayCell>
          ))}
        </WeekTableHeader>
        <WeekTable>
          {this.isSomeSlots ? (
            this.slots.map(({ date, slots }) => (
              <WeekDayCell key={date}>
                {slots.map(slot => (
                  <Slot
                    key={slot}
                    onClick={this.onSelectSlot(slot)}
                    isActive={this.isSlotSelected(slot)}
                  >
                    {format(slot, "p")}
                  </Slot>
                ))}
              </WeekDayCell>
            ))
          ) : (
            <Placeholder title="No available slots for this week." />
          )}
        </WeekTable>
      </Container>
    );
  }
}

export default Calendar;
