/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const LogoContainer = styled.div`
  min-width: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  overflow: hidden;

  border-radius: 50%;

  &::after {
    position: absolute;
  }
`;

export const Logo = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  overflow: hidden;

  flex-shrink: 0;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
