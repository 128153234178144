/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const PhoneNumber = styled.div``;

export const PhoneNumberGroup = styled.div`
  margin-left: 26px;
  margin-top: 8px;
`;

export const SmallText = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`;
