/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import styledWrapper from "common/components/StyledWrapper";
import { LogoBlue } from "common/components/Logo";
import { SeparatorHorizontal } from "common/components/Separator";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  padding: 50px 60px 60px 60px;

  max-width: 392px;
`;

export const Logo = styledWrapper(LogoBlue)`
  margin-bottom: 21px;
`;

export const Separator = styledWrapper(SeparatorHorizontal)`
  margin-bottom: 21px;
`;
