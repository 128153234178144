/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";
import styledWrapper from "common/components/StyledWrapper";

import Link from "common/components/Link";
import { ButtonBlue } from "common/components/Button";

export const Button = styledWrapper(ButtonBlue)`
  margin: 10px 0 16px;
`;

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;

  height: 200px;
`;

export const ReturnButton = styledWrapper(styled(Link)`
  padding-right: 5px;
`)`
 margin-top: 35px;
 text-align: center;
`;

export const Text = styled.span`
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #919bb8;
`;
