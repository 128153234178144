/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: -1;

  background-color: #ebf0fb;
`;
