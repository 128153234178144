/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import api from "common/services/api";
import { types, flow } from "mobx-state-tree";

import createTableStore from "stores/templates/TableStore";
import { RolePayload, deleteDuplicateRoles } from "stores/RolesStore";

export type InviteCustomerUserPayload = {
  email: string;
  roles: RolePayload[];
};

export const StudyUser = types.model({
  id: types.number,
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  email: types.maybe(types.string),
  phone: types.maybe(types.string),
});

const StudyStaffStore = createTableStore({
  fetch: async function({ self, params }) {
    return await api.get(`/study/${self.studyId}/study-user/list`, params);
  },
  itemModel: StudyUser,
  searchBy: "search",
})
  .props({
    studyId: types.maybe(types.number),
    isInviteLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    init: flow(function*(studyId: number) {
      self.studyId = studyId;
      yield self.fetch();
    }),
    inviteUser: flow(function*(payload: InviteCustomerUserPayload) {
      let isSuccess = false;
      let error = "";
      self.isInviteLoading = true;

      try {
        const uniqueRoles = deleteDuplicateRoles(payload.roles);
        const payloadUniqueRoles: InviteCustomerUserPayload = {
          ...payload,
          roles: uniqueRoles,
        };
        yield api.post(`/customer-user/invite`, payloadUniqueRoles);
        isSuccess = true;
      } catch (e) {
        console.error("error", error);
        error = e.message;
      } finally {
        self.isInviteLoading = false;
      }

      return { isSuccess, error };
    }),
    deleteUser: flow(function*({ userId }: { userId: number }) {
      self.isLoading = true;
      let isSuccess = false;
      let error = "";

      try {
        yield api.delete(`/customer-user/${userId}`, {});
        isSuccess = true;
      } catch (e) {
        error = e.error;
      } finally {
        self.isLoading = false;
      }

      return { isSuccess, error };
    }),
  }));

export default StudyStaffStore.create();
