/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import AuthStore from "stores/Auth";
import SelfScheduleStore from "stores/selfSchedule/SelfScheduleStore";

import Background from "common/components/Layout/Background/BackgroundLight";
import { Header } from "common/components/Layout";

import Steps from "./Steps";

import { Paper, StepsContainer, StepsContent, StepsHeader } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class SelfSchedule extends React.Component<Props> {
  get studyId() {
    const { id } = this.props.match.params;
    return Number.parseInt(id);
  }

  @computed
  get contentTitle() {
    const { studyName } = SelfScheduleStore;

    return (
      `Self Scheduling for Participants` + (studyName ? ` of ${studyName}` : ``)
    );
  }

  componentDidMount() {
    AuthStore.signOut();
    SelfScheduleStore.reset();
    SelfScheduleStore.setStudyId(this.studyId);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      SelfScheduleStore.setStudyId(this.studyId);
      AuthStore.signOut();
    }
  }

  componentWillUnmount() {
    SelfScheduleStore.reset();
  }

  render() {
    const { isScheduleStep, isRequestIsSentStep } = SelfScheduleStore;

    return (
      <StepsContainer>
        <Background />
        <Header />
        <StepsHeader>{this.contentTitle}</StepsHeader>
        <Paper>
          <StepsContent wide={isScheduleStep || isRequestIsSentStep}>
            <Steps step={SelfScheduleStore.step} />
          </StepsContent>
        </Paper>
      </StepsContainer>
    );
  }
}

export default withRouter(SelfSchedule);
