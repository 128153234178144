/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI24ChevronLeft(props) {
  return (
    <svg width={12} height={24} {...props}>
      <path
        fill="#A9B4C9"
        fillRule="evenodd"
        d="M2.258 4.258a1.05 1.05 0 00-.088 1.386l.088.098L8.515 12l-6.257 6.258a1.05 1.05 0 001.386 1.572l.098-.088 7-7a1.05 1.05 0 000-1.484l-7-7a1.05 1.05 0 00-1.484 0z"
      />
    </svg>
  );
}

export default SvgI24ChevronLeft;
