/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  border-radius: 6px;
  background-color: #ffffff;

  box-shadow: ${({ withShadow }) =>
    withShadow ? "0 16px 24px -8px rgba(0, 26, 216, 0.06)" : "none"};

  max-height: 100%;
  height: auto;
`;
