/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React, { ReactNode } from "react";

import IconButton from "common/components/Button/IconButton";
import I16ArrowBack from "common/assets/icons/I16ArrowBack";

import {
  Container,
  TopBarContainer,
  HeaderContent,
  SideContainer,
  Content,
} from "./styles";

type Props = {
  onBackClick?: () => void;
  backButtonText?: string;
  children: ReactNode;
  header?: ReactNode;
  rightContent?: ReactNode;
};

export default class BackBar extends React.Component<Props> {
  render() {
    const {
      onBackClick,
      backButtonText,
      children,
      header,
      rightContent,
    } = this.props;

    return (
      <Container>
        <TopBarContainer>
          <SideContainer>
            {onBackClick && (
              <IconButton
                icon={<I16ArrowBack />}
                onClick={onBackClick}
                text={backButtonText || "Back"}
              />
            )}
          </SideContainer>

          <HeaderContent>{header}</HeaderContent>

          <SideContainer>{rightContent}</SideContainer>
        </TopBarContainer>

        <Content>{children}</Content>
      </Container>
    );
  }
}
