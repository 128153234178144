/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import ROUTES from "navigation/routes";

import { ROLE_TYPE, ROLE_ID } from "constants/roles";
import { DASHBOARD_TYPE } from "constants/dashboard";

export const getRouteByRole = (currentRole: string) => {
  switch (currentRole) {
    case ROLE_TYPE.SUPER_ADMIN:
      return ROUTES.SUPER_ADMIN;
    case ROLE_TYPE.CUSTOMER_ADMIN:
      return ROUTES.CUSTOMER_ADMIN;
    case ROLE_TYPE.STUDY_ADMIN:
    case ROLE_TYPE.STUDY_COORDINATOR:
    case ROLE_TYPE.SITE_ADMIN:
    case ROLE_TYPE.SITE_STAFF:
    case ROLE_TYPE.EVENT_STAFF:
    case ROLE_TYPE.REPORTING:
    case ROLE_TYPE.LOCATOR_MANAGER:
      return ROUTES.STUDY_ADMIN;
    case ROLE_TYPE.NONE:
      return ROUTES.LOGIN_BY_ROLE;
    default:
      return ROUTES.LOGIN;
  }
};

export const getDashboard = (dashboard: string) => {
  switch (dashboard) {
    case DASHBOARD_TYPE.LOGIN:
      return ROLE_TYPE.NONE;
    case DASHBOARD_TYPE.SUPER_ADMIN:
      return ROLE_TYPE.SUPER_ADMIN;
    case DASHBOARD_TYPE.CUSTOMER_ADMIN:
      return ROLE_TYPE.CUSTOMER_ADMIN;
    case DASHBOARD_TYPE.STUDY_ADMIN:
      return ROLE_TYPE.STUDY_ADMIN;
    case DASHBOARD_TYPE.STI_LOCATOR:
      return ROLE_TYPE.LOCATOR_MANAGER;
  }
};

export const getRoleNameByRoleId = (roleId: number) =>
  Object.keys(ROLE_ID).find(key => ROLE_ID[key] === roleId);

export const getRoleIdByRoleName = (roleName: string) => ROLE_ID[roleName];
