/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { COLORS } from "common/constants/layout";

import { Button, LoaderBlue, LoaderWhite } from "./styles";

type Props = {
  children: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  className?: any;
  type?: string;
};

export function ButtonBlue({
  onClick,
  children,
  disabled,
  isLoading,
  className,
  type,
}: Props) {
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={(isLoading || disabled) ?? false}
      background={disabled ? "rgba(86, 130, 246, 0.4)" : COLORS.CORNFLOWER}
      color={COLORS.BUTTON_FONT_COLOR}
      hoverColor={disabled ? "rgba(86, 130, 246, 0.4)" : COLORS.CORNFLOWER_DARK}
      activeColor={disabled ? "rgba(86, 130, 246, 0.4)" : "#3f47cb"}
      borderWidth="0px"
      type={type}
    >
      {isLoading ? <LoaderWhite size={24} /> : children}
    </Button>
  );
}

export function ButtonOutline({
  onClick,
  children,
  disabled,
  isLoading,
  className,
  type,
}: Props) {
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={(isLoading || disabled) ?? false}
      background="transparent"
      color={COLORS.CORNFLOWER}
      borderColor={COLORS.CORNFLOWER}
      hoverColor={COLORS.BLUE_MASKING}
      activeColor={COLORS.BLUE_MASKING}
      outline
      borderWidth="2px"
      type={type}
    >
      {isLoading ? <LoaderBlue size={24} /> : children}
    </Button>
  );
}
