/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Instance } from "mobx-state-tree";

import SelfScheduleStore, {
  Visit as VisitModel,
} from "stores/selfSchedule/SelfScheduleStore";
import SelfScheduleVisitsDictionary from "stores/selfSchedule/SelfScheduleVisitsDictionary";

import { ButtonBlue, ButtonOutline } from "common/components/Button";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import { Row, Field } from "common/components/Form";
import Dropdown from "common/components/Input/Dropdown";
import withGlobalPopup, {
  GlobalPopupProps,
} from "common/components/Popup/withGlobalPopup";

import Header from "../../Header";

import {
  ButtonWrapper,
  Container,
  Content,
  Controls,
  FieldValue,
} from "../styles";

type Props = {} & GlobalPopupProps;

@observer
class Visit extends React.Component<Props> {
  @observable visit: Instance<typeof VisitModel> | undefined;
  @observable error: string = "";

  async componentDidMount() {
    this.resetError();

    try {
      const { study } = await SelfScheduleVisitsDictionary.fetch();

      SelfScheduleStore.setStudyName(study.name);
    } catch (error) {
      this.error = error.message;
    }
  }

  getDurationValues = (durationMins: number) => {
    const hours = durationMins / 60;
    const hoursRounded = Math.floor(hours);
    const minutes = (hours - hoursRounded) * 60;
    const minutesRounded = Math.round(minutes);

    return `${hoursRounded} h ${minutesRounded} min`;
  };

  onVisitChange = (item: any) => {
    this.visit = item.value;
  };

  openConfirmationPopup = () => {
    const { openPopup } = this.props;

    openPopup({
      key: "CancelScheduling",
      canCloseByOuterClick: true,
      render: ({ close }) => (
        <ConfirmationDialog
          title={"Discard Scheduling"}
          text={`Are you sure you would like to discard scheduling? All information will be lost.`}
          acceptText={"Discard"}
          declineText={"Keep"}
          onDecline={close}
          onAccept={() => {
            SelfScheduleStore.softReset(1, true);

            close();
          }}
        />
      ),
    });
  };

  resetError = () => {
    this.error = "";
  };

  onSubmit = async () => {
    const { stepForward } = SelfScheduleStore;

    SelfScheduleStore.setVisit(this.visit);

    stepForward();
  };

  render() {
    const { isLoading } = SelfScheduleStore;

    return (
      <Container>
        <Content>
          <Header />
          <Row>
            <Field title="Visit" required>
              <Dropdown
                input={{ value: this.visit }}
                onChange={this.onVisitChange}
                provider={SelfScheduleVisitsDictionary}
                placeholder="Select visit"
                error={this.error}
              />
            </Field>
          </Row>
          {this.visit && (
            <>
              <Row>
                <Field title="Visit type">
                  <FieldValue>{this.visit.visitType?.name}</FieldValue>
                </Field>
              </Row>
              <Row>
                <Field title="Duration">
                  <FieldValue>
                    {this.getDurationValues(this.visit.visitType?.durationMins)}
                  </FieldValue>
                </Field>
              </Row>
            </>
          )}
        </Content>
        <Controls>
          <ButtonWrapper>
            <ButtonOutline onClick={this.openConfirmationPopup}>
              Cancel
            </ButtonOutline>
          </ButtonWrapper>

          <ButtonWrapper>
            <ButtonBlue
              onClick={this.onSubmit}
              disabled={!this.visit}
              isLoading={isLoading}
            >
              Next
            </ButtonBlue>
          </ButtonWrapper>
        </Controls>
      </Container>
    );
  }
}

export default withGlobalPopup(Visit);
