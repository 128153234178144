/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled from "styled-components";

import LoaderDefault from "common/components/Loader";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1198px;
  margin: 0 auto;

  padding: 0 23px;
  margin-top: 150px;
`;

export const Content = styled.div`
  padding: 50px 60px 60px 60px;
  max-width: 590px;
`;

export const Loader = styled(LoaderDefault)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p``;
