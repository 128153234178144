/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observable } from "mobx";
import { observer } from "mobx-react";

import ROUTES from "navigation/routes";

import Background from "common/components/Layout/Background/BackgroundBlue";
import Paper from "common/components/Paper";

import { Container, Content, Logo, Separator } from "./styles";

import { auth } from "stores/Auth";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";

type Props = {} & RouteComponentProps;

@observer
class ForgotPassword extends React.Component<Props> {
  @observable serverError: string = "";
  @observable emailIsSent: boolean = false;

  onSubmit = async (email: string) => {
    const { error } = await auth.forgotPassword(email);
    if (error) {
      this.serverError = error;
      return;
    }

    this.resetError();
    this.emailIsSent = true;
  };

  resetError = () => {
    this.serverError = "";
  };

  onReturnToLogin = () => {
    this.props.history.push(ROUTES.LOGIN);
  };

  render() {
    return (
      <Container>
        <Background />
        <Paper>
          <Content>
            <Logo />
            <Separator />
            <ForgotPasswordForm
              onSubmit={this.onSubmit}
              serverError={this.serverError}
              isLoading={auth.isLoading}
              onReturnToLogin={this.onReturnToLogin}
              emailIsSent={this.emailIsSent}
            />
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(ForgotPassword);
