/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";

import { withFormContext, FormContextType } from "../formContext";

type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

type Props = {
  render: (props: ButtonProps) => React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  canSubmit?: boolean;
};

function onDummyClick() {}

class SubmitButtonControl extends React.Component<Props & FormContextType> {
  onClick = () => {
    const {
      handleSubmit,
      onClick = onDummyClick,
      canSubmit = true,
      getState,
    } = this.props;
    if (canSubmit) {
      handleSubmit && handleSubmit();
    }
    const { hasValidationErrors } = (getState && getState()) || {};
    !hasValidationErrors && onClick();
  };

  render() {
    return this.props.render({
      onClick: this.onClick,
      disabled: this.props.disabled,
    });
  }
}

export default withFormContext(SubmitButtonControl);

export const CancelButtonControl = withFormContext(
  class CancelButtonControl extends React.Component<
    {
      onClick?: (resetForm: () => void) => void;
      render: (props: ButtonProps) => React.ReactNode;
      isFormDirty?: boolean;
    } & FormContextType
  > {
    onClick = async () => {
      const { onClick = onDummyClick, reset = onDummyClick } = this.props;
      onClick(reset);
    };

    render() {
      return this.props.render({
        onClick: this.onClick,
      });
    }
  },
);
