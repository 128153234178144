/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import * as React from "react";

function SvgI24Trash(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        fill="#A9B4C9"
        fillRule="evenodd"
        d="M20 5a1 1 0 010 2h-1v13a2 2 0 01-2 2H7a2 2 0 01-2-2V7H4a1 1 0 110-2h16zm-3 2H7v13h10V7zm-7 2a1 1 0 011 1v7a1 1 0 01-2 0v-7a1 1 0 011-1zm4 0a1 1 0 011 1v7a1 1 0 01-2 0v-7a1 1 0 011-1zm2-7a1 1 0 010 2H8a1 1 0 110-2h8z"
      />
    </svg>
  );
}

export default SvgI24Trash;
