/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import { FieldRenderProps } from "react-final-form";

import SvgI24Trash from "common/assets/icons/I24Trash";
import SvgI24Edit from "common/assets/icons/I24Edit";
import SvgI48PlaceholderImage from "common/assets/icons/I48PlaceholderStudyImage";

import DropZone, { ExternalDropZoneProps } from "common/components/DropZone";

import withFieldControl from "../FieldControl";

import { readImageContent } from "common/utils/images";

import {
  Container,
  IconControl,
  IconControlsContainer,
  PlaceholderContainer,
} from "./styles";

type Props = { invalidFileTypeMessage?: string } & FieldRenderProps<any> &
  ExternalDropZoneProps;

@observer
class UploadPreviewFieldControl extends React.Component<Props> {
  dropZoneRef = React.createRef<DropZone>();
  reader = new FileReader();

  @observable imagePreview: any = "";

  @computed get image() {
    const { input } = this.props;
    return this.imagePreview || input.value;
  }

  get dropzoneElement() {
    return this.dropZoneRef?.current;
  }

  addedfile = async (file: File) => {
    const { input } = this.props;
    const event: any = {
      target: { value: file },
    };
    input.onChange(event);

    const { result } = await readImageContent(this.reader, file);

    this.imagePreview = result;
  };

  removedFile = () => {
    const { input } = this.props;
    const event: any = {
      target: { value: undefined },
    };
    input.onChange(event);

    this.imagePreview = undefined;
  };

  openFileSelector = () => {
    if (this.dropzoneElement) {
      this.dropzoneElement.dropzone.element.click();
    }
  };

  render() {
    const { meta, ...props } = this.props;

    const error = meta.error;

    return (
      <Container image={this.image} hasError={error}>
        {!this.image && (
          <PlaceholderContainer>
            <SvgI48PlaceholderImage />
          </PlaceholderContainer>
        )}
        <DropZone
          ref={this.dropZoneRef}
          componentConfig={{ postUrl: "no-url" }}
          eventHandlers={{
            addedfile: this.addedfile,
            removedfile: this.removedFile,
          }}
          error={error}
          autoUpload={false}
          maxFiles={1}
          fitWidth
          hideProgressBar
          {...props}
        />
        <IconControlsContainer>
          {this.image && (
            <IconControl icon={<SvgI24Trash />} onClick={this.removedFile} />
          )}
          <IconControl icon={<SvgI24Edit />} onClick={this.openFileSelector} />
        </IconControlsContainer>
      </Container>
    );
  }
}

export default withFieldControl(UploadPreviewFieldControl);
