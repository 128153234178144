/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { withRouter, RouteComponentProps } from "react-router-dom";

import ChangeEmailStore from "stores/ChangeEmailStore";

import Background from "common/components/Layout/Background/BackgroundLight";
import { Header } from "common/components/Layout";
import Paper from "common/components/Paper";
import Title from "common/components/Form/Title";
import Link from "common/components/Link";

import { correctTextFromQueryParams } from "common/utils/queryParamsUtils";

import { Container, Content, Loader, Text } from "./styles";

type Props = {} & RouteComponentProps;

@observer
class EmailChangeConfirm extends React.Component<Props> {
  @observable changeIsSuccessful: boolean = false;

  get oldEmail() {
    const oldEmail = new URLSearchParams(this.props.location.search).get(
      "oldEmail",
    );

    return oldEmail ? correctTextFromQueryParams(oldEmail) : oldEmail;
  }

  get newEmail() {
    const newEmail = new URLSearchParams(this.props.location.search).get(
      "newEmail",
    );

    return newEmail ? correctTextFromQueryParams(newEmail) : newEmail;
  }

  get userId(): number | null {
    const userId = new URLSearchParams(this.props.location.search).get(
      "userId",
    );
    return userId ? Number.parseInt(userId) : null;
  }

  get code(): string | null {
    const code =
      new URLSearchParams(this.props.location.search).get("code") ?? null;

    return code ? correctTextFromQueryParams(code) : code;
  }

  @computed
  get title() {
    return this.changeIsSuccessful
      ? "Email is changed"
      : "Couldn’t change the email address";
  }

  @computed
  get text() {
    return this.changeIsSuccessful ? (
      <Text>
        Your email address was successfully changed from{" "}
        <Link href={`mailto:${this.oldEmail}`}>{this.oldEmail}</Link> to{" "}
        <Link href={`mailto:${this.newEmail}`}>{this.newEmail}</Link>. From now
        on, all the emails will be redirected to the new email and you can use
        the new credentials to schedule visits or log in to SMART mobile app if
        you have an invitation from your study.
      </Text>
    ) : (
      <Text>
        Please request changing email address again to get the new confirmation
        link.
      </Text>
    );
  }

  async componentDidMount() {
    if (!this.userId || !this.newEmail || !this.code) {
      this.changeIsSuccessful = false;
      return;
    }

    const { isSuccess } = await ChangeEmailStore.confirmNewEmail(
      this.userId,
      this.newEmail,
      this.code,
    );

    this.changeIsSuccessful = isSuccess;
  }

  render() {
    return (
      <Container>
        <Background />
        <Header />
        <Paper>
          <Content>
            {ChangeEmailStore.isLoading ? (
              <Loader />
            ) : (
              <>
                <Title title={this.title} />
                {this.text}
              </>
            )}
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(EmailChangeConfirm);
