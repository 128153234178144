/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Area = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "123px")};

  padding: 8px 16px;

  ${({ disabled }) =>
    disabled
      ? css`
          color: #999999;
          background-color: #f0f2f7;
        `
      : css`
          color: #333333;
          background-color: transparent;
        `};

  font-family: OpenSans-Regular;
  font-size: 16px;

  outline: none;

  border-radius: 7px;

  ${({ hasError }) =>
    hasError
      ? css`
          border: solid 2px #f74a70;

          &:active {
            border: solid 2px #f74a70;
          }

          &:focus {
            border: solid 2px #f74a70;
          }
        `
      : css`
          border: solid 2px #d8ddeb;

          &:active {
            border: solid 2px #5682f6;
          }

          &:focus {
            border: solid 2px #5682f6;
          }
        `};

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;

  color: #f74a70;

  margin-top: 4px;
`;
