/*
 *   Emory: SMART
 *   Copyright (C) by Emory: SMART
 *
 *   Developed by Mercury Development, LLC
 *   http://www.mercdev.com
 *
 */
import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import DefaultDatePicker from "react-datepicker";
import { FieldInputProps } from "react-final-form";
import { ValueType } from "react-select";
import { addDays, isBefore, isValid, startOfDay, subDays } from "date-fns";

import "react-datepicker/dist/react-datepicker.min.css";

import SvgI16Calendar from "common/assets/icons/I16Calendar";
import SvgI24ChevronLeft from "common/assets/icons/I24ChevronLeft";
import SvgI24ChevronRight from "common/assets/icons/I24ChevronRight";

import { DictionaryItem } from "common/types/models";

import { MASKS } from "common/constants/forms";

import {
  Container,
  CustomHeaderContainer,
  Error,
  IconButton,
  InputContainer,
  Input,
  InputIconContainer,
  MonthDropdown,
  YearDropdown,
} from "./styles";

export type DatePickerExternalProps = {
  disabled?: boolean;
  placeholder?: string;
  placement?: string;
  minDate?: Date;
  maxDate?: Date;
  fullWidth?: boolean;
  small?: boolean;
  labelMarginLeft?: number;
  showYearDropdown?: boolean;
  readOnly?: boolean;
  placementPriority?: string[];
  onCalendarClose?: () => void;
};

type Props = {
  onChange: (date: Date) => void;
  error?: string | boolean;
  input: FieldInputProps<Date>;
} & DatePickerExternalProps;

const DATE_STRING_LENGTH = 10;

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getYear = (date: Date): number => {
  return date.getFullYear();
};

const getMonth = (date: Date): number => {
  return date.getMonth();
};

const range = (start: number, end: number) => {
  return [...Array(end - start).keys()].map(value => value + start);
};

const customHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}) => {
  const years = range(1901, getYear(new Date()) + 1);

  const monthOptions = MONTHS.map((item, index) => ({
    value: index,
    label: item,
  }));

  const yearsOptions = years.map(item => ({
    value: item,
    label: item.toString(),
  }));

  const onChangeMonth = (item: ValueType<DictionaryItem>) =>
    changeMonth(item.value);

  const onYearChange = (item: ValueType<DictionaryItem>) =>
    changeYear(Number.parseInt(item.value));

  return (
    <CustomHeaderContainer>
      <IconButton
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        icon={<SvgI24ChevronLeft />}
      />
      <MonthDropdown
        input={{ value: getMonth(date) }}
        onChange={onChangeMonth}
        options={monthOptions}
        maxMenuHeight={300}
        menuPosition={"relative"}
        size={"middle"}
      />
      <IconButton
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        icon={<SvgI24ChevronRight />}
      />
      <YearDropdown
        input={{ value: getYear(date) }}
        onChange={onYearChange}
        options={yearsOptions}
        maxMenuHeight={300}
        menuPosition={"relative"}
        size={"middle"}
      />
    </CustomHeaderContainer>
  );
};

@observer
export default class DatePicker extends React.Component<Props> {
  inputRef: HTMLInputElement | null = null;

  onChange = (date: Date) => {
    this.props.onChange(date);
  };

  onChangeRaw = (event: React.FocusEvent<HTMLInputElement>) => {
    const { minDate, maxDate, input } = this.props;

    const possibleDateMin = minDate ? addDays(minDate, 1) : undefined;
    const possibleDateMax = maxDate ? subDays(maxDate, 1) : undefined;

    const possibleDate =
      input.value || possibleDateMin || possibleDateMax || new Date();
    const dateString = event.target.value;

    if (dateString.length === DATE_STRING_LENGTH) {
      try {
        const date = startOfDay(new Date(dateString));

        if (isValid(date)) {
          const minDateCheck = minDate ? isBefore(minDate, date) : true;
          const maxDateCheck = maxDate ? isBefore(date, maxDate) : true;
          const dateIsAcceptable = minDateCheck && maxDateCheck;

          if (dateIsAcceptable) {
            this.props.onChange(date);
            return;
          }
        }
      } catch (e) {}
    }

    this.props.onChange(startOfDay(possibleDate));
  };

  onCalendarClose = () => {
    const { onCalendarClose } = this.props;

    this.blurInput();
    onCalendarClose && onCalendarClose();
  };

  handleRef = (ref: any) => {
    this.inputRef = ref;
  };

  blurInput = () => {
    this.inputRef && this.inputRef.blur();
  };

  focusInput = () => {
    this.inputRef && this.inputRef.focus();
  };

  render() {
    const {
      disabled,
      placeholder,
      error,
      input,
      placement,
      placementPriority,
      minDate,
      maxDate,
      fullWidth,
      readOnly,
      small,
      showYearDropdown,
    } = this.props;

    return (
      <Container fullWidth={fullWidth}>
        <DefaultDatePicker
          {...input}
          autoComplete="off"
          selected={input?.value}
          formatWeekDay={weekDay => weekDay[0]}
          onChange={this.onChange}
          onChangeRaw={this.onChangeRaw}
          onCalendarClose={this.onCalendarClose}
          customInput={
            <CustomInput
              handleRef={this.handleRef}
              focusInput={this.focusInput}
              error={error}
              fullWidth={fullWidth}
              small={small}
              disabled={disabled}
              {...MASKS.DATE}
            />
          }
          placeholderText={placeholder}
          disabled={disabled}
          popperPlacement={placement || "bottom-start"}
          minDate={minDate}
          maxDate={maxDate}
          enableTabLoop={false}
          popperModifiers={{
            flip: {
              behavior: placementPriority || ["bottom", "top", "left", "right"],
            },
            preventOverflow: {
              enabled: false,
            },
          }}
          popperProps={{ positionFixed: true }}
          renderCustomHeader={showYearDropdown ? customHeader : undefined}
          readOnly={readOnly}
          fixedHeight
        />
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
}

type CustomInputProps = React.HTMLProps<HTMLInputElement> & {
  handleRef: (ref: any) => void;
  focusInput: () => void;
  error?: string;
  fullWidth?: boolean;
  small?: boolean;
};

class CustomInput extends React.Component<CustomInputProps> {
  onIconClick = (e: MouseEvent<HTMLInputElement>) => {
    const { focusInput, onClick } = this.props;

    focusInput();
    onClick && onClick(e);
  };

  render() {
    const {
      name,
      onChange,
      onClick,
      placeholder,
      value,
      error,
      fullWidth,
      disabled,
      small,
      readOnly,
      autoComplete,
      handleRef,
      focusInput,
      ...remainingProps
    } = this.props;

    return (
      <InputContainer onClick={onClick} disabled={disabled}>
        <Input
          inputRef={handleRef}
          name={name}
          placeholder={placeholder}
          hasError={error}
          value={value}
          onChange={onChange}
          onClick={onClick}
          readOnly={readOnly}
          fullWidth={fullWidth}
          disabled={disabled}
          small={small}
          autoComplete={autoComplete}
          {...remainingProps}
        />
        <InputIconContainer onClick={this.onIconClick}>
          <SvgI16Calendar />
        </InputIconContainer>
      </InputContainer>
    );
  }
}
